<div class="search-container">
  <div class="banner-centered">
    <h2>Search</h2>
  </div>
</div>
<div class="container">
  <div class="row my-3">
    <div class="col-sm-8">
      <div class="bg-white me-2 rounded-3 shadow" >
        <ul class="nav align-items-center" >
          <li class="nav-item">
            <div class="nav-link">Home</div>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="nav-link">Search</div>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-3">Content</div>
          </li>
        </ul>
      </div>
      <div class="row mt-3">
        <div class="mb-3 col-sm-12">
          <div class="bg-white p-4 overFlowHidden">
            <ng-container *ngIf="contentData?.length;else noData" >
            <h3 style="color: rgb(255, 208, 122)"> Search results for <strong>{{searchString}}</strong></h3>
              <ng-container *ngFor="let item of contentData">
                <div class="mt-3">
                  <h4 class="text-truncate">
                    <a  style="cursor: pointer;color: #b19999;" (click)="searchModule(item)">{{
                      item.title
                    }}</a>
                  </h4>
                  <hr />
                  <span style="font-size: 13px"><ng-container *ngIf="item.author">Author:{{item.author}} |</ng-container>
                    <ng-container *ngIf="item.source">Source:{{item.source}} <span *ngIf="item.issueDate || item.publishedBy || item.publishedDate">|</span> </ng-container>
                    <!-- <ng-container *ngIf="item.source">Source:{{item.source}} | </ng-container> -->
                    <ng-container *ngIf="item.issueDate">Issue Date:{{item.issuedDate | date:'medium'}}<span *ngIf="item.publishedBy">|</span> </ng-container>
                    <ng-container *ngIf="item.publishedBy">Published By:{{item.publishedBy}}<span *ngIf="item.publishedDate">|</span> </ng-container>
                    <ng-container *ngIf="item.publishedDate">Published Date:{{item.publishedDate | date:'medium'}}<span *ngIf="item.issueDate">|</span> </ng-container>
                  </span>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noData>
              <div class="content">
                <!-- <form class="search-form" action="/en/search/node" method="post" id="search-form" accept-charset="UTF-8" >
                  <div>
                    <div class="container-inline form-wrapper" id="edit-basic">
                      <div class="form-item form-type-textfield form-item-keys">
                        <label for="edit-keys">Enter your keywords </label>
                        <input type="text" id="edit-keys" name="keys" value="asd" size="40" maxlength="255" class="form-text" />
                      </div>
                      <input type="submit" id="edit-submit" name="op" value="Search" class="form-submit" />
                    </div>
                    <input type="hidden" name="form_build_id" value="form-j3owmBjmoTwwVwHLb3gSo5qGxZCFBSGiVEHCSWOz104" />
                    <input type="hidden" name="form_id" value="search_form" />
                  </div>
                </form> -->
                <h2 style="color: orange" class="mb-2">Your search yielded no results</h2>
                <hr>
                <ul style="list-style:none;font-weight: 100;">
                  <li>Check if your spelling is correct.</li>
                  <li>
                    Remove quotes around phrases to search for each word
                    individually. <em>bike shed</em> will often show more
                    results than <em>"bike shed"</em>.
                  </li>
                  <li>
                    Consider loosening your query with <em>OR</em>.
                    <em>bike OR shed</em> will often show more results than
                    <em>bike shed</em>.
                  </li>
                </ul>
              </div>
            </ng-template>
            
            <app-paginator [totalCount]="totalItems" [page]="page" (nextPage)="pageEvent($event)" ></app-paginator>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-4">
      <app-footer-sub-menu></app-footer-sub-menu>
    </div>
  </div>
</div>
