import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = require("sweetalert");

@Component({
  selector: 'app-core-document-add',
  templateUrl: './core-document-add.component.html',
  styleUrls: ['./core-document-add.component.scss'],
})
export class CoreDocumentAddComponent implements OnInit {
  entryForm: FormGroup;
  fileInput: any;

  @Input() rowData: any = [];
  @Input() submitButton: string = 'Submit';
  @Input() dynamicForm: any = {};
  @Input() moduleData: any = {};
  @Input() fileAcceptType:any='';
  @Input() buttonDisable:boolean=false
  displayUploadNew = true;
  @Input() isKms = false;
  updateFormData: boolean = false
  currentDate: string = ''
  constructor(private formBuilder: FormBuilder,private contentService:ContentService, private dialog: MatDialog) {
    this.entryForm = this.formBuilder.group({
      title: ['', Validators.required],
      publishedDate: ['', Validators.required],
      publishedBy: ['', Validators.required],
      author: ['', Validators.required],
      fileList: ['', Validators.required],
      source: ['', Validators.required],
      autoExpiry: ['', Validators.required],
      ranking: ['', Validators.required],
      keyword: [''],
    });
  }
  @Input() title: string = 'NRLM Document';
  @Output() previousPath: any = new EventEmitter();
  @Output() submitEntry: any = new EventEmitter();
  @Input() isViewMode: boolean = false;
  @Input() labelKeys:any = {}
  fileValidations='default';
  ngOnInit(): void {
    console.log(this.rowData);
    console.log(this.dynamicForm);

    const date = new Date();

    let day = date.getDate().toString()
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let singleDigit = ["0","1","2","3","4","5","6","7","8","9"]

    if(singleDigit.includes(day)) {
      this.currentDate = `${year}-0${month}-0${day}`
    } else {
      this.currentDate = `${year}-0${month}-${day}`
    }

    // This arrangement can be altered based on how we want the date's format to appear.

    console.log("Current Date ", this.currentDate)
    
  };

  goBack() {
    this.previousPath.emit();
    sessionStorage.removeItem("entryTitle")
    sessionStorage.removeItem("addData")
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('chanes', changes);
    this.checkValidationType(this.moduleData.moduleId, this.moduleData.subModuleId);
    if (changes.rowData && changes.rowData.currentValue) {
      this.rowData = changes.rowData.currentValue;
      this.labelKeys = changes.labelKeys.currentValue
      // for(let key in this.entryForm.value){
      //   if(!key.includes('DateExtended'))
      //   if((key.includes('Date') || key.includes('Expiry')) && this.rowData[key])
      //   this.rowData[key] =  this.rowData[key].split('T')[0]
      //   this.entryForm.patchValue({

      //     [key] : this.rowData[key]
      //   })
      // }
      this.prefilledForm();
    }
    if (changes.isView && changes.isView.hasOwnProperty('currentValue')) {
      this.isViewMode = changes.isView.currentValue;
    }
    if (changes.dynamicForm && changes.dynamicForm.currentValue) {
      this.dynamicForm = changes.dynamicForm.currentValue;
      this.entryForm = this.formBuilder.group(changes.dynamicForm.currentValue);
      if (this.rowData.hasOwnProperty('id')) {
        this.updateFormData = true
        this.prefilledForm();
      }
    }
    if(this.isKms){
      let obj:any = {title:'fileName', fileType:'knowledgeMaterialVertical',language:'language',source:'source',file_url:'file'};
      for(let key in obj){
        this.rowData = this.entryForm.value[obj[key]];
      }
      this.entryForm.patchValue({
        ...this.rowData
      })
    }
  }
checkValidationType(moduleId='',subModuleId=''){
  console.log('ran')
  if(moduleId && subModuleId && moduleId=='11' && subModuleId=='15'){
    this.fileValidations='image'
  }else{
    this.fileValidations = 'default'
  }
}
  onSubmit() {
console.log("row", this.rowData, this.dynamicForm)
    if(this.dynamicForm.hasOwnProperty('fileList') && !this.dynamicForm.fileList.length && !this.fileInput && !this.rowData?.fileList?.length && this.moduleData.moduleId !='9'){
      swal("Error", `Please Add File`, "error");
      return
    }
    this.submitEntry.emit({
      form: this.entryForm.value,
      file: this.fileInput?.files,
      contentId: this.rowData?.id,
    });
  }

  prefilledForm() {
    console.log('Entry Form ', this.entryForm)
    for (let key in this.entryForm.value) {
      if (!key.includes('DateExtended'))
        if (
          (key.includes('Date') || key.includes('Expiry')) &&
          this.rowData[key]
        )
          this.rowData[key] = this.rowData[key].split('T')[0];
      this.entryForm.patchValue({
        [key]: this.rowData[key],
      });
    }
    console.log('ddd', this.entryForm.value);

  }

  // fileName: string = '';
  // getFileNameFromURL(fileUrl: any) {
  //   console.log(fileUrl)
  //   this.fileName = fileUrl.split(environment.url)[1];
  //   console.log('fileName', this.fileName)
  // }
  imgExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/raw', 'image/image'];
  async onFileChange(event:any) {
    let validation = await this.contentService.checkValidFile(event);
    if(this.fileValidations=='image' && !this.imgExtensions.includes(validation)){
      swal("","Only image file is allowed","error");
      event.target.value = '';
      return;
    }
    if(validation.includes('Unknown')){
      swal("","Invalid file Type","error");
      event.target.value = '';
      return;
    }
    this.fileInput = (<HTMLInputElement>document.getElementById("fileInput"));
  }
  openDialog(fileList:any) {

   if(!Array.isArray(fileList)){
    let type  =  fileList.fileUrl.split('.');
    fileList['fileType'] =type[type.length-1];
     fileList = [fileList];
  //   {
  //     "fileList": [
  //         {
  //             "id": 1218,
  //             "contentId": 1172,
  //             "fileUrl": "https://stgaajeevikaimg.dhwaniris.in/service-standards-for-mksp_1653991300230.pdf",
  //             "fileSize": "28.0",
  //             "fileType": "pdf",
  //             "downloadCount": 0,
  //             "viewCount": 0,
  //             "thumbnail": "https://stgaajeevikaimg.dhwaniris.in/service-standards-for-mksp_1653991300230_thumbnail.png"
  //         }
  //     ]
  // }
   }


    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  getFileName(fileList:any){
    // console.log(fileList)
    if(fileList.length){
      let fileArray = fileList[0].fileUrl.split('https://stgaajeevikaimg.dhwaniris.in/')
      let fileName = fileArray[fileArray.length-1];
      return fileName
    }else if(!Array.isArray(fileList)){
      console.log(fileList)
        let fileName =  fileList.fileUrl.split('https://stgaajeevikaimg.dhwaniris.in/');
      return fileName[fileName.length-1]
    }
    else{
      return 'No file provided'
    }

  }
}

