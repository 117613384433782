<div class="bg-white rounded-3 shadow p-4 my-3">
    <ng-container *ngIf="!isKms">
        <div class="row align-items-center">
            <div class="col-auto">
                <a class="btn btn-link" (click)="goBack()">
                    <img src="assets/icons/arrow_back_black_24dp.svg" alt="back icon">
                </a>
            </div>
            <div class="col">
                <h3 class="text-black m-0">{{title}}</h3>
            </div>
        </div>
        <hr>
    </ng-container>
    <form [formGroup]="entryForm" (ngSubmit)="onSubmit()" class="form-group" autocomplete="off">
        <div class="row">

            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.title">
                <label for="">Title <span class="text-danger">*</span></label>
                <input appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" onkeyup="this.value = this.value.replace(/[\[\]]/g, '')"
                    class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" [placeholder]="title == 'Photos Upload' ? 'SRLM Name, Actitvity Description' : 'Enter Title'"
                    name="title" formControlName="title" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.publishedDate">
                <label for="">Published Date <span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Date"
                    name="publishedDate" formControlName="publishedDate" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.publishedBy">
                <label for="">Published By <span class="text-danger">*</span></label>
                <input appAlphabetOnly class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Published By" name="publishedBy" formControlName="publishedBy" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.author">
                <label for="">Author <span class="text-danger">*</span></label>
                <input appAlphabetOnly class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Author" name="author" formControlName="author" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.source">
                <label for="">Source <span class="text-danger">*</span></label>
                <input appAlphabetOnly class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Source" name="source" formControlName="source" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.link">
                <label for="">Link <span class="text-danger"
                        *ngIf="moduleData.moduleId=='28'?false:true">*</span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Link"
                    name="link" formControlName="link" [required]="moduleData.moduleId=='28'?false:true"
                    pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.corrigendum">
                <label for="">Corrigendum <span class="text-danger">*</span></label>
                <!-- <input class="form-check" type="checkbox" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Corrigendum" name="corrigendum" formControlName="corrigendum"> -->
                <div class="">
                    <input type="radio" name="corrigendum" [ngClass]="{'disabled': isViewMode}"
                        formControlName="corrigendum" [value]="1" required> Yes
                    <input type="radio" name="corrigendum" [ngClass]="{'disabled': isViewMode}"
                        formControlName="corrigendum" [value]="2" required> No
                </div>
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.fileNumber">
                <label for="">File Number <span class="text-danger">*</span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter File Number" name="fileNumber" formControlName="fileNumber" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.issuedNo">
                <label for="">Issued Number <span class="text-danger">*</span></label>
                <input class="form-control" type="number" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Issued Number" name="issuedNo" formControlName="issuedNo" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.issuedNumber">
                <label for="">Issued No. <span class="text-danger">*</span></label>
                <input class="form-control" type="number" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Issued No." name="issuedNumber" formControlName="issuedNumber" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.location">
                <label for="">Location <span class="text-danger">*</span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Location" name="location" formControlName="location" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.rtiQuery">
                <label for="">RTI Query <span class="text-danger">*</span></label>
                <input appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" class="form-control" type="text"
                    [ngClass]="{'disabled': isViewMode}" placeholder="Enter RTI Query" name="rtiQuery"
                    formControlName="rtiQuery" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.issuedBy">
                <label for="">Issued By <span class="text-danger"></span></label>
                <input appAlphabetOnly class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Issued By" name="issuedBy" formControlName="issuedBy" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.referenceNo">
                <label for="">Reference Number<span class="text-danger"></span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" placeholder="Referenece Number" name="referenceNo"
                    formControlName="referenceNo" autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.autoExpiry">
                <label for="">Expiry Date <span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Expiry Date" name="autoExpiry" formControlName="autoExpiry" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.dueDateExtended">
                <label for="">Due Date Extended <span class="text-danger">*</span></label>
                <!-- <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Due Date Extended" name="dueDateExtended" formControlName="dueDateExtended"> -->
                <div class="">
                    <input type="radio" name="dueDateExtended" [ngClass]="{'disabled': isViewMode}"
                        formControlName="dueDateExtended" [value]="1" required> Yes
                    <input type="radio" name="dueDateExtended" [ngClass]="{'disabled': isViewMode}"
                        formControlName="dueDateExtended" [value]="2" required> No
                </div>
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.lastDateSubmission">
                <label for="">Last Date Of Submission <span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Last Date Of Submission" name="lastDateSubmission"
                    formControlName="lastDateSubmission" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.vacancyUploadedDate">
                <label for="">Vacancy Uploaded Date<span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Vacancy Uploaded Date" name="vacancyUploadedDate"
                    formControlName="vacancyUploadedDate" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.issuedDate">
                <label for="">Issued Date<span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}" [max]="currentDate"
                    placeholder="Enter Issued Date" name="issuedDate" formControlName="issuedDate" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.startDate">
                <label for="">Start Date<span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Start Date" name="startDate" formControlName="startDate" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.endDate">
                <label for="">End Date<span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter End Date" name="endDate" formControlName="endDate" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.ranking">
                <label for="">Ranking <span class="text-danger">*</span></label>
                <input appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" onkeyup="this.value = this.value.replace(/[\[\]]/g, '')"
                    class="form-control" type="number" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Ranking"
                    name="ranking" formControlName="ranking" required autocomplete="off" min="0">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.description">
                <label for=""> {{labelKeys.description?labelKeys.description : 'Description' }}</label>
                <textarea style="resize: none;" class="form-control" cols="20" rows="2"
                    [ngClass]="{'disabled': isViewMode}"
                    placeholder="{{labelKeys.description?labelKeys.description : 'Description'}}" name="description"
                    formControlName="description"></textarea>
            </div>
            <div class="col-sm-4 " *ngIf="dynamicForm?.fileList">
                <label for="fileInput">File <span class="text-danger" *ngIf="moduleData.moduleId !='9'">*</span>
                </label>
                <input formControlName="fileList" *ngIf="submitButton=='Submit' && !isKms" class="form-control" type="file"
                    [ngClass]="{'disabled': isViewMode}" id="fileInput" #fileInput (change)="onFileChange($event)"
                    placeholder="Enter File" [accept]="fileAcceptType" multiple required>
                <input formControlName="fileList"  *ngIf="!displayUploadNew " class="form-control" type="file" [ngClass]="{'disabled': isViewMode}"
                    id="fileInput" #fileInput (change)="onFileChange($event)" placeholder="Enter File"
                    [accept]="fileAcceptType" multiple required>
                <ng-container *ngIf="this.entryForm.get('fileList')?.value && updateFormData">
                    <span class="m-1"><button type="button" class="btn btn-sm"
                            [ngClass]="{'btn-success m-2':displayUploadNew,'btn-danger mt-2':!displayUploadNew,'disabled d-none': isViewMode}"
                            (click)="displayUploadNew=!displayUploadNew">{{displayUploadNew?'Upload
                            New':'Cancel'}}</button></span>
                    <div *ngIf="displayUploadNew" class="m-1 text-truncate"> 
                        <div style="cursor:pointer" (click)="openDialog(this.entryForm.get('fileList')?.value)">
                            <i class="fa fas fa-paperclip orange p-2"></i>
                            {{getFileName(this.entryForm.get('fileList')?.value)}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.keyword">
                <label for="">Keyword <span class="text-danger">*</span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Keyword" name="keyword" formControlName="keyword" required autocomplete="off">
            </div>
        </div>

        <div class="row mt-2">
            <div class="col">
                <button class="btn btn-bg" type="submit" *ngIf="!isViewMode"
                    [disabled]="!entryForm.valid || buttonDisable">{{submitButton}}</button>
            </div>
        </div>
    </form>
</div>