<div class="container">
    <div class="row py-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/about" class="nav-link ">Themes</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Sustainable Agriculture</div>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Organic Farming</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p><span style="font-weight: 900;">Organic Farming</span>
                        Mahila Kisans who have adopted agro-ecological practices and improved animal rearing, organic farming is the next logical progression to get their produces certified to get access to better market and price. Various states had identified 1646 villages, potential for promotion of organic farming in phased manner. A detailed guidance note was circulated to the SRLMs in January 2018. Subsequently a list of six Technical Support Agencies (TSAs) are empanelled to support the SRLMs, a draft ToR for engagement of TSA has been circulated to the states. SRLMs engaged TSAs and Regional Councils for effective implementation of Organic Farming. NMMU also hired TSA at National Level. 
                        Under organic farming, 9,856 Local Groups (LG) have been registered on Participatory Guarantee System of India PGS-India portal with about 2 Lakh SHG members. Seven Technical Bulletins were issued to create awareness on Organic farming. Two expert talks were organised to strengthen the perspectives on organic farming for SRLM staff. State level trainings were organised for four SRLMs by National Mission Management Unit (NMMU). Signed MoU with National Centre of Organic and Natural Farming (NCONF), Ghaziabad, Nodal agency for implementation of PGS. All the registers required for implementation of PGS were placed with Local Groups. All the LGs completed peer appraisals. Four webinar series were organised on various technologies of organic farming. 66, 569 SHG members received PGS green certificates and 1659 SHG members received PGS Organic certificates in the year 2020-21.</p>                 
                </div>
            </div>
        </div>
    </div>
    <app-common-document-upload [moduleId]="31" [subModuleId]="34"></app-common-document-upload>
</div>