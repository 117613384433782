<div class="header-image">
    <img src="/assets/images/ajjevika-banner-introduction.jpg" alt="Introduction" title="Introduction" class="img-fluid" />
</div>
<div class="container">
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    Establishment of convergence compatible line Department/Ministries and partnerships with private players is key to lever more support to achieve the objective set under NRLM for income enhancement. Since FY 2020-21 the efforts have been intensified to establish a strong convergence to sustain the activities with different line Department/Ministries. Followings are the details on the Convergence and Partnership:                    
                    <br><br>
                    <span style="font-weight: 800;">Department of Animal Husbandry and Dairying:</span> MoU has been signed between NRLM and DAHD to dovetail various program of Ministries. Various field level activities such as vaccination, health camps etc. were expedited. One signification joint effort was taken on training & certification of the Pashu Sakhi promoted under DAY-NRLM by accredited them as A-HELP (Accredited Agent for Health and Extension of Livestock Production). The initiative of A-HELP have already been started in States like Jharkhand, Jammu & Kashmir and Madhya Pradesh
                    <br><br>
                    <span style="font-weight: 800;">Ministry of Tribal Affairs:</span> An MoU has been signed with TRIFED for promoting NTFP based interventions
                    <br><br>
                    <span style="font-weight: 800;">Ministry of Agriculture and Farmer's Welfare (MoA&FW):</span> An MoU has been signed with MoA&FW for providing technical support in implementation of Integrated farming Cluster promoted under DAY-NRLM.
                    <br><br>
                    <span style="font-weight: 800;">MoU with NHAI (National Highway Authority of India):</span> DAY - NRLM signed MoU with NHAI on 30th March 2022 for promotion of plantations along the National Highways through SHGs and their federations. So far Six states viz AP, Assam, Bihar, MP, Rajasthan and UP signed MoU with respective Regional offices of NHAI. (Seven MoUs)
                    <br><br>
                    <span style="font-weight: 800;">MoU with NAFED (National Agricultural Cooperative Marketing Federation of India Ltd.):</span> MoU has been signed with NAFED to support the Producers Enterprises in marketing of their products. To expedite the joint initiative further, the Ministry of Rural Development has also recognised NAFED as National Resource Organisation
                    <br><br>
                    <span style="font-weight: 800;">MoU with NCONF (National Centre for Organic and Natural Farming):</span> MoU signed with NCONF to provide technical support for promotion of Organic and Natural Farming. NCONF is actively involved in providing trainings to SRLMs and technical support in implementation of PGS. One National Level training is organised by NCONF for all the NRETP states and Punjab at NCONF, Gahziabad.
                    <br><br>
                    <span style="font-weight: 800;">MoU with IIMR (Indian Institute for Millet Research):</span> An MoU signed with IIMR, Hyderabad for providing technical support for promotion of Millets. Five exposure visits were organised for SRLM staff and PE leaders. Two webinars were organised for SRLM staff on emerging technologies in Millet Value chain.
                    <br /><br />
                    <span style="font-weight: 800;">DAY-NRLM Support Organizations</span> DAY-NRLM is building partnerships with various stakeholders for improving effectiveness of the implementation of the livelihood interventions. The partners are emerging as sector support organizations in diversified livelihood subsectors. These organizations are in a position to support the SRLMs in project formulation, providing implementation support and in training and capacity building of SRLM staff, CRP and community.
                    <br /><br />
                    <table style="width: 100%; padding-left: 2px;">
                        <thead>
                            <tr>
                                <th style="font-weight: 700;">Sector</th>
                                <th style="font-weight: 700;">Organization</th>
                                <th style="font-weight: 700;">Key Support Areas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Livelihoods</td>
                                <td>TASAR Value Chain Development</td>
                                <td>Dairy Value Chain Development</td>
                            </tr>
                            <tr>
                                <td>PRADAN</td>
                                <td>Central Silk Board</td>
                                <td>NDDB Dairy Services</td>
                            </tr>
                            <tr>
                                <td>Knowledge management, Identifying successful replicable models, capacity building</td>
                                <td>Developing pro poor Tasar value chain up to pre cocoon stage</td>
                                <td>Developing Dairy value chain through promotion of Producers' Company</td>
                            </tr>
                            <tr>
                                <td>Tasar Value Chain development</td>
                                <td>Tasar Development Foundation</td>
                                <td>Developing pro poor Tasar value chain up to pre-cocoon stage</td>
                            </tr>
                            <tr>
                                <td>Value Chain Development</td>
                                <td>Foundation for development of value chain ( FDRVC)</td>
                                <td>Value chain initiative throuu</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>