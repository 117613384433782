<div class="container-fluid careerImage"> 
  <div class="centered">Careers</div>
</div>  
<div class="container">
  <!-- hided as per requirement -->
  <!-- <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Careers</div>
                  </li>
              </ul>
          </div>
      </div>
  </div> -->
    <div class="card border-0 rounded-2 shadow mb-3 mt-5">
      <div class="row">
        <div class="col-4">
            <h4 class="ms-3 mt-3 textColor">Careers</h4>
        </div>
        <div class="col-8">
          <form class="date-block row" [formGroup]="filterForm">
            
              <div class="col-3">
                <div>
                  <span class="filter">Filter Uploaded Date</span>
               </div>
              </div>
              <div class="col-3">
                <div>
                  <mat-form-field>
                    <input matInput 
                      [matDatepicker]="fromDate" 
                      placeholder="From Date" 
                      formControlName="fromDate">
                    <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-3">
                <div>
                  <mat-form-field>
                    <input matInput 
                      [matDatepicker]="toDate" 
                      placeholder="To Date" 
                      formControlName="toDate">
                    <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                    <mat-datepicker #toDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-3">
             <button mat-button color="primary" class="primary" (click)="applyFilter()">Search</button>
                
              </div>
            
            

            
              
          </form>
        </div>
      </div>
      <!-- <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator> -->
      <ng-container *ngIf="careers?.length;else noData">
      <ul class="list-group list-group-flush">
        <ng-container *ngFor="let item of resultProductData">
          <div class="dropdown">
            <li style="cursor: pointer" class="list-group-item dropbtn m-4" (click)="navigateTo(item)">{{item.textDesc}}<br>
              
              <span class="upload">Uploaded Date:</span>{{item.vacancyUploadedDate | date : 'mediumDate'}}&nbsp;&nbsp;&nbsp;&nbsp;
              <span class="lastDate">Last Submission Date: </span>{{item.lastDateSubmission | date : 'mediumDate'}}
                <!-- {{item?.lastDateSubmission | date : 'mediumDate'}} -->
            </li>
            <div class="dropdown-content d-button" *ngIf="item?.fileList && item.fileList.length">
              <a class="downloadButton" [href]="item?.fileList[0].fileUrl" download="file" target="_blank">
                Download File
            </a>
            </div>
          </div>
          
         
        </ng-container>
      </ul>
      </ng-container>
      <ng-template #noData>
        <div class="row mb-4 mt-4">
          <div style="text-align: center">
            <h1>No Data Found</h1>
          </div>
        </div>
      </ng-template>
    </div>

</div>
