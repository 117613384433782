<div class="header-image">
    <img src="/assets/images/ajjevika-banner-introduction.jpg" alt="Introduction" title="Introduction" class="img-fluid" />
</div>
<div class="container">
    <div class="row py-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/about" class="nav-link ">F L Components</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">NRETP</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                   <span style="font-weight: 900;">National Rural Economic Transformation Project (NRETP):</span> 
                    NRETP was declared effective on 24th April 2019 and is scheduled to close on 30th June 2023. The total outlay of the project is USD 500 million out of which USD 250 million is a loan assistance from the World Bank and the remaining USD 250 million is the counterpart financing provided by Government of India.  
                    The farm livelihoods component of it has the focus on promotion of producers’ collectives, viz. producers’ groups and producers’ enterprises to enable the small and marginal women farmers to access markets through aggregation and value addition. The project is going to be implemented in 13 earlier National Rural Livelihoods Project (NRLP) States.              
                </div>
            </div>
        </div>
    </div>
</div>