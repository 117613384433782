import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentService } from 'src/app/shared/services/content.service';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit {

  constructor(private contentService: ContentService, private dialog: MatDialog) { }

  ngOnInit(): void {
    // this.getSubModuleData(this.subModuleId)
    this.getPhotoGallery()
  }

  photoGallery: any = [];
  totalItem = 0;
  page = 1;
  params:any = "";
  filterConfig = {
    globalSearch:true,
    search:true,
    author:false,
    source:false,
    from:true,
    to:true,
    fileType:false
  }


  moduleId:Number=28;
  subModuleId:Number = 29;

  getPhotoGallery(){
    this.contentService.getContent("list", `moduleId=28&subModuleId=29&page=${this.page}&${this.params}`).subscribe(
      (data: any) => {
        this.photoGallery = data.wrapperList;
        this.totalItem = data.totalcount;
        console.log(this.photoGallery);

      }
    );
  }

  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
        type:'image'
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  pageEvent(event: any) {
    this.page = event;
    this.getPhotoGallery();
  }
  searchParams(event: any){
    let searchParams  = new URLSearchParams(event).toString();
    this.params = searchParams;
    this.page = 1;
    this.getPhotoGallery()
  }

  getImageThumbnail(item:any){
    let imageUrl = item.fileList && item.fileList.length && item.fileList[0].fileUrl ? item.fileList[0]?.fileUrl : '/assets/images/women.png'; 
    return imageUrl;
  }

  getTotalDownload(fileList:any){
    let total = 0;
    for (var i in fileList) {
      total += fileList[i].downloadCount;
    }
    return total

  }

}
