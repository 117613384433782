<div class="header-image">
    <img src="/assets/images/ajjevika-banner-introduction.jpg" alt="Introduction" title="Introduction" class="img-fluid" />
</div>
<div class="container">
    <div class="row py-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/about" class="nav-link ">F L Components</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">MKSP</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    Mahila Kisan Sashaktikaran Pariyojana (MKSP) is a sub-component of DAY-NRLM  and being implemented since 2011. The primary objective of the MKSP is to empower women by making systematic investments to enhance their participation and productivity, as also create and sustainable livelihoods of rural women. The program is implemented by  State Rural Livelihoods Missions. Followings are the components of the program<br /><br />
                    <span style="font-weight: 900;"> 1. MKSP for Sustainable Agriculture </span> aims at empowering women in agriculture by making systematic investments to enhance their participation and productivity, as also create and sustain agriculture-based livelihoods of rural women. By establishing efficient local resource-based agriculture, wherein women in agriculture gain more control over the production resources and manage the support systems, the project seeks to enable them to gain better access to the inputs and services provided by the government and other agencies. The key objectives of the component are<br />
                        i. To enhance the productive participation of women in agriculture;<br />
                        ii. To create sustainable agricultural livelihood opportunities for women in agriculture;<br />
                        iii. To improve the skills and capabilities of women in agriculture to support farm and non-farm-based activities;<br />
                        iv. To ensure food and nutrition security at the household and the community level;<br />
                        v. To enable women to have better access to inputs and services of the government and other agencies;<br />
                        vi. To enhance the managerial capacities of women in agriculture for better management of biodiversity;<br />
                        vii. To improve the capacities of women in agriculture to access the resources of other institutions and schemes within a convergence framework.<br /><br />
                        <span style="font-weight: 900;">2. MKSP for NTFP</span> aims at empowering the tribal women NTFP collectors through an integrated approach of institution building around livelihoods, capacity building and livelihood interventions programmed together. The key objectives of the component are<br />
                        i. To develop a sustainable model for livelihood promotion of NTFP collectors<br />
                        ii. To ensure a better control of the institutions of the NTFP collectors over the NTFP value chain<br />
                        iii. To promote regeneration of NTFP species to improve the bio diversity and enhanced productivity<br />
                        iv. To build the capacity of the community in sustainable harvesting and post harvesting techniques to increase their income<br />
                        v. To promote local value addition of NTFP to ensure higher returns<br />
                        vi. To develop market linkages for NTFP<br />
                        vii. To develop a pool of community resource persons to enable the community institutions to manage their activities<br/>
                        viii. To enable women to have better access to their entitlements and services of the government and other agencies.<br /><br />
                        <span style="font-weight: 900;">3. MKSP - Value Chain</span> aims at empowering women farmers and collectors through an integrated approach of developing Producers' Enterprises, building market linkage and capacity building programmed together. The key objectives of the component are<br />
                        i. To ensure higher price realization for the agriculture, dairying and NTFP produce for the small and marginal women farmers<br />
                        ii. Increasing influence of women farmers on the commodity value chain<br />
                        iii. To ensure increased bargaining power of small and marginal producers by achieving economy of scale and therefore negotiate better prices.<br />
                        iv. To develop robust business models at scale for the commodities produced by small and marginal women farmers<br />
                        v. To develop scalable and sustainable Producers' Enterprises to implement the value chain development interventions<br />
                        vi. To create opportunities for value addition of commodities<br />
                        vii. To take up Capacity building of the community for better post-harvest handling, knowledge of quality parameters<br />
                        viii. To provide the platform for producers to promote their interests and influence policies in the local and national environment that affect their business and livelihood.<br />
                        ix. To promote the use of ICT for accounting, inventory management, payment to producers and market information dissemination.<br />
                </div>
            </div>
        </div>
    </div>
</div>