import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-videos-upload',
  templateUrl: './videos-upload.component.html',
  styleUrls: ['./videos-upload.component.scss']
})
export class VideosUploadComponent implements OnInit {

  constructor(private contentService: ContentService, public dialog: MatDialog) { }

  videoGallery:any
  totalItem = 0;
  page = 1;
  params:any = "";
  filterConfig = {
    globalSearch:true,
    search:true,
    author:false,
    source:false,
    from:true,
    to:true,
    fileType:false
  }

  moduleId:Number=28;
  subModuleId:Number = 30
  ;

  ngOnInit(): void {
    this.getVideoGallery()
  }

  getVideoGallery(){
    this.contentService.getContent("list", `moduleId=28&subModuleId=30&page=${this.page}&${this.params}`).subscribe(
      (data: any) => {
        this.videoGallery = data.wrapperList;
        console.log("Video Gallery ", this.videoGallery)
        this.getUpdatedVideoGalllery(this.videoGallery)
        this.totalItem = data.totalcount;
      }
    );
  }

  getUpdatedVideoGalllery(videoGallery:any) {
    videoGallery.map((item:any) => {
      item.fileList[0].fileType = `video/${item.fileList[0].fileType}`
    })

    this.videoGallery = videoGallery
  }

  openDialog(link:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        link: link,
        type:'video'
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  pageEvent(event: any) {
    this.page = event;
    this.getVideoGallery();
  }

  searchParams(event: any){
    let searchParams  = new URLSearchParams(event).toString();
    this.params = searchParams;
    this.page = 1;
    this.getVideoGallery()
  }

  getYouTubeFrame(link: any =''){
    let frameImg = '/assets/images/videDefault.png';
    if(link){
    let videoId = link.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/) ? link.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop() : '';
    if(videoId)
    frameImg = '//img.youtube.com/vi/'+videoId+'/0.jpg';
    }
    // console.log("frameImg", frameImg)
    return frameImg;
  }

}
