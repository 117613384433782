<div class="container">
    <div class="row py-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/about" class="nav-link ">Themes</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Value Chain Development</div>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">FPO Promotion</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p style="font-weight: 900;">TO BE ADDED</p>                 
                </div>
            </div>
        </div>
    </div>
    <app-common-document-upload [moduleId]="31" [subModuleId]="39"></app-common-document-upload>

</div>