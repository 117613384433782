import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgroEcologicalProjectsComponent } from './agro-ecological-projects/agro-ecological-projects.component';
import { AgroforestryComponent } from './agroforestry/agroforestry.component';
import { FishRearingComponent } from './fish-rearing/fish-rearing.component';
import { FpoPromotionComponent } from './fpo-promotion/fpo-promotion.component';
import { GoatRearingComponent } from './goat-rearing/goat-rearing.component';
import { HorticultureComponent } from './horticulture/horticulture.component';
import { IfcComponent } from './ifc/ifc.component';
import { LakhpatiInitiativesComponent } from './lakhpati-initiatives/lakhpati-initiatives.component';
import { MilletPromotionComponent } from './millet-promotion/millet-promotion.component';
import { NaturalFarmingComponent } from './natural-farming/natural-farming.component';
import { NtfpComponent } from './ntfp/ntfp.component';
import { OrganicFarmingComponent } from './organic-farming/organic-farming.component';
import { PePromotionComponent } from './pe-promotion/pe-promotion.component';
import { PgPromotionComponent } from './pg-promotion/pg-promotion.component';
import { PigRearingComponent } from './pig-rearing/pig-rearing.component';
import { PoultryComponent } from './poultry/poultry.component';
import { SlaccComponent } from './slacc/slacc.component';
import { SubSectorPromotionComponent } from './sub-sector-promotion/sub-sector-promotion.component';
import { ThemeRoutingModule } from './theme-routing.module';
import { DairyPromotionComponent } from './dairy-promotion/dairy-promotion.component';

@NgModule({
    declarations: [
        AgroEcologicalProjectsComponent,
        AgroforestryComponent,
        FishRearingComponent,
        FpoPromotionComponent,
        GoatRearingComponent,
        HorticultureComponent,
        IfcComponent,
        LakhpatiInitiativesComponent,
        MilletPromotionComponent,
        NaturalFarmingComponent,
        NtfpComponent,
        OrganicFarmingComponent,
        PePromotionComponent,
        PgPromotionComponent,
        PigRearingComponent,
        PoultryComponent,
        SlaccComponent,
        SubSectorPromotionComponent,
        DairyPromotionComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ThemeRoutingModule
    ],
})
export class ThemeModule { }