<div class="header-image">
    <img src="/assets/images/approach.png" alt="Introduction" title="Introduction" class="img-fluid" />
    <div class="page-title">Events</div>
</div>
<div class="container">
    <div class="row my-3">
        <div class="col">
            <div class="bg-white rounded-3 shadow">
                <ul class="nav align-items-center">
                    <li class="nav-item">
                        <a href="/" class="nav-link ">Home</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <a href="/about" class="nav-link ">Whats New?</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <div class="px-3">Events</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <ng-template #calendar>
        <div class="row p-2">
            <app-calendar [appointments]="appointments" (requestNewAppointment)="onRequestNewAppointment($event)"
                (requestUpdateAppointment)="onRequestUpdateAppointment($event)"
                (appointmentUpdated)="onAppointmentUpdated($event)">
            </app-calendar>
            <app-appointment-detail [ngClass]="{'d-none': !appointmentDetail}" [isNew]="isNew"
                [appointment]="appointmentDetail" (add)="onAdd($event)" (update)="onUpdate($event)"
                (close)="onCloseAppointmentDetail()" id="appointment-detail">
            </app-appointment-detail>
        </div>
    </ng-template>
    <div class="row">
        <div class="col-sm-8 mb-3">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="col-sm-12 padding-calendar">
                    <label class="switch">
                        <input type="checkbox" [checked]="isEvents" (click)="toggleEvents($event)"
                            [(ngModel)]="isEvents" [ngModelOptions]="{standalone: true}" class="float-right m-2" />
                        <span class="slider round"></span>
                    </label><span class="float-right">
                        <ng-container>Toggle View</ng-container>
                    </span>
                </div>
                
                <ng-container *ngIf="isEvents; else calendar">
                    <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)">
                    </app-paginator>
                    <ul class="list-group list-group-flush">
                        <ng-container *ngFor="let item of events">
                            <li style="cursor: pointer" class="list-group-item" (click)="navigateTo(item)">
                                {{item?.title}}, Published Date: {{item?.publishedDate | date : 'mediumDate'}}</li>
                        </ng-container>
                    </ul>
                </ng-container>
            </div>
        </div>
        <div class="col-sm-4 mb-3 position-relative">
            <app-whats-new-menu></app-whats-new-menu>
        </div>
    </div>

</div>