<div class="container">
    <div class="row py-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/about" class="nav-link ">Themes</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Livestock</div>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Goat Rearing</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p>Goats are known as 'poor man's cow. Goats contribute to the livelihoods of millions of rural poor. Small and marginal farmers and landless farmers, particularly women, increasingly rely on goat keeping for their socio-economic upliftment. Goat is well integrated into the farming systems of small and marginal farmers due to low capital requirement, secure handling and ability to adapt to versatile environmental conditions. The goat is ideal for farming for small and marginal farmers, including landless agricultural labourers, particularly in rainfed regions where crop production is uncertain. Rearing large ruminants is difficult because of acute feed and fodder scarcity. Goat rearing has distinct economic and managerial advantages over other livestock species because of its less initial investment requirement, low input requirement, higher prolificacy, early sexual maturity and marketing comfort.</p>                 
                </div>
            </div>
        </div>
    </div>
    <app-common-document-upload [moduleId]="31" [subModuleId]="6"></app-common-document-upload>
</div>