import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentService } from 'src/app/shared/services/content.service';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';


@Component({
  selector: 'app-lakhpati-initiatives',
  templateUrl: './lakhpati-initiatives.component.html',
  styleUrls: ['./lakhpati-initiatives.component.scss']
})
export class LakhpatiInitiativesComponent implements OnInit {
  showExtraContent: boolean = false
  selectedTab: any = 'actionTakenPoints'

  constructor(private contentService: ContentService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getSubModuleData(this.subModuleId)
  }

  filterConfig = {
    globalSearch:true,
    search:true,
    author:false,
    source:false,
    from:true,
    to:true,
    fileType:false
  }

  moduleId:Number=31;
  subModuleId:Number = 18;

  params:any;
  page = 1;
  currentSubModuleId: any;
  totalCount: any;
  dataList: any;

  searchParams(event: any){
    let searchParams  = new URLSearchParams(event).toString();
    this.params = searchParams;
    this.page = 1;
    this.getSubModuleData(this.subModuleId)
  }

  getSubModuleData(id:any){
    // if(this.currentSubModuleId!=id){
    //   this.page=1;
    // }
    this.currentSubModuleId = id;
    this.contentService.getContent("list", `moduleId=${this.moduleId}&subModuleId=${id}&page=${this.page}&${this.params}`,).subscribe((res:any)=>{
      if(res){
        this.totalCount = res.totalcount;
        this.dataList = res && res['wrapperList']
      }
    },error=>{
      console.log(error)
    })
  }

  getTotalDownload(fileList:any){
    let total = 0;
    for (var i in fileList) {
      total += fileList[i].downloadCount;
    }
    return total

  }

  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  toggleReadMore() {
    let element = document.getElementById('readMoreContent')
    let readMoreButton = document.getElementById('readMoreBtn')
    let readLessButton = document.getElementById('readLessBtn')
    if(element?.style.display == 'none' && readMoreButton?.style.display == 'block' && readLessButton?.style.display == 'none') {
       element.style.display = 'block'
       readMoreButton.style.display = 'none'
       readLessButton.style.display = 'block'
    }
  }

  toggleReadLess() {
    let element = document.getElementById('readMoreContent')
    let readMoreButton = document.getElementById('readMoreBtn')
    let readLessButton = document.getElementById('readLessBtn')
    if(element?.style.display == 'block' && readMoreButton?.style.display == 'none' && readLessButton?.style.display == 'block') {
       element.style.display = 'none'
       readMoreButton.style.display = 'block'
       readLessButton.style.display = 'none'
    }
  }

  toggleReadMoreAction() {
    let element = document.getElementById('readMoreActionContent')
    let readMoreButton = document.getElementById('readMoreActionBtn')
    let readLessButton = document.getElementById('readLessActionBtn')
    if(element?.style.display == 'none' && readMoreButton?.style.display == 'block' && readLessButton?.style.display == 'none') {
      element.style.display = 'block'
      readMoreButton.style.display = 'none'
      readLessButton.style.display = 'block'
    }
  }

  toggleReadLessActionButton() {
    let element = document.getElementById('readMoreActionContent')
    let readMoreButton = document.getElementById('readMoreActionBtn')
    let readLessButton = document.getElementById('readLessActionBtn')
    if(element?.style.display == 'block' && readMoreButton?.style.display == 'none' && readLessButton?.style.display == 'block') {
       element.style.display = 'none'
       readMoreButton.style.display = 'block'
       readLessButton.style.display = 'none'
    }
  }

  readMoreContent() {
    this.showExtraContent = true
  }

  readLessContent() {
    this.showExtraContent = false
  }

  changeTab(tabName:any) {
    this.selectedTab = tabName
  }

}
