import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InnovativeComponent } from './innovative/innovative.component';
import { MkspComponent } from './mksp/mksp.component';
import { NrlmComponent } from './nrlm/nrlm.component';
import { NretpComponent } from './nretp/nretp.component';

const routes: Routes = [
    {
		path: "",
        redirectTo: 'introduction', 
		pathMatch: 'full',
	},
    {
        path: "innovative",
        component: InnovativeComponent
    },
    {
        path: "mksp",
        component: MkspComponent
    },
    {
        path: "nrlm",
        component: NrlmComponent
    },
    {
        path: 'nretp',
        component: NretpComponent
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes),],
    exports: [RouterModule]
  })

  export class FlComponentRoutingModule {}