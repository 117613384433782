import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  textSize = ['sm','rg','lg'];
  currentTextSize: any;
  aboutUsDropdown: boolean = false;
  whatDropdown: boolean = false;
  workStructureDropdown: boolean = false;
  impactDropdown: boolean = false;
  resourceDropdown: boolean = false;
  whatSubDropdown: boolean = false
  workStructureSubDropdown: boolean = false
  aboutUsSubDropdown: boolean = false
  ourImpactSubDropdown:boolean = false
  resourceSubDropdown:boolean = false
  activeButton:any;
  flComponentsOpen: boolean = false
  openDigitalLibraryOption: boolean = false
  fakePdfUrl: string = 'https://aajeevikaimg.dhwaniris.in/Fake_website_1676033493375.pdf';

   // This is new Headers list for mksp
   newHeadersList: any = [
    {menuName: "F L Components", mainUrl: false, subMenuList: [{subMenuName: 'NRLM-AAP', mainSubUrl: false}, {subMenuName: 'NRETP', mainSubUrl: false}, {subMenuName: 'MKSP', mainSubUrl: false},{subMenuName: 'INNOVATIVE/SPECIAL PROJECTS', mainSubUrl: false}]},
    {menuName: "Themes", mainUrl: false, subMenuList: [{subMenuName: 'Sustainable Agriculture', mainSubUrl: false, subMenuListAgain: [{subMenuNameAgain: "Agro-ecological Practices"},{subMenuNameAgain: 'Horticulture'},{subMenuNameAgain: 'Natural Farming'},{subMenuNameAgain: 'Organic Farming'},{subMenuNameAgain: 'Agroforestry'}]},{subMenuName: 'Livestock', mainSubUrl: false, subMenuListAgain:[{subMenuNameAgain: 'Poultry'},{subMenuNameAgain: 'Goat rearing'},{subMenuNameAgain: 'Fish rearing'},{subMenuNameAgain:'Pig rearing'},{subMenuNameAgain: 'Dairy Promotion'}]}, {subMenuName: 'NTFP/Non-Wood Forest Products', mainSubUrl: false},{subMenuName: 'Value Chain Development', mainSubUrl: false, subMenuListAgain: [{subMenuNameAgain: 'PG Promotion'},{subMenuNameAgain: 'PE Promotion'},{subMenuNameAgain: 'FPO Promotion'}]},{subMenuName: 'Special Interventions', mainSubUrl: false, subMenuListAgain: [{subMenuNameAgain: 'Millet Promotion'},{subMenuNameAgain: 'Integrated Farming Cluster (IFC)'},{subMenuNameAgain: 'Sub-Sector Promotion'},{subMenuNameAgain: 'SLACC'},{subMenuNameAgain: 'Lakhpati Initiatives'}]}]},
    {menuName: 'Convergence/Partners', mainUrl: true, url: "/convergence/partners"},
    {menuName: 'Digital Library', mainUrl: false, subMenuList: [{subMenuName: 'Orders, Advisory & Guidelines', mainSubUrl: true, subUrl: '/resource/order'},{subMenuName: 'Evaluation & Study Reports', mainSubUrl: true, subUrl: '/resource/study/reports'},{subMenuName: 'Best Practices & Case Study', mainSubUrl: true, subUrl: '/resource/case/study'},{subMenuName: 'Knowledge Documents', mainSubUrl: false, subMenuListAgain: [{subMenuNameAgain: "Human Resource"},{subMenuNameAgain:"Sustainable Agriculture"},{subMenuNameAgain: "Training Capacity building"}]}]},
    {menuName: 'Reports', mainUrl: false, subMenuList: [{subMenuName: 'Farm Livelihood Progress', mainSubUrl: false, subMenuListAgain: [{subMenuNameAgain: "MKSP"},{subMenuNameAgain: "AAP"}]},{subMenuName: 'Farm Value Chain', mainSubUrl: false, subMenuListAgain: [{subMenuNameAgain: "Producer Enterprise"},{subMenuNameAgain: "Producer Group"}]},{subMenuName: 'Millet Progress', mainSubUrl: false},{subMenuName: 'Sub Sector Progress', mainSubUrl: false},{subMenuName: 'IFC Cluster', mainSubUrl:false}]},
    {menuName: 'Media Gallery',mainUrl: true, url: "/mediaGallery"},
    {menuName: 'Archive',mainUrl: false,},

  ]

  flComponentsStatus: boolean = false

  digitalLibraryStatus: boolean = false


  resourceSubModules = [
    {
      subModuleName:'Case-studies',
      _id:31
    },
    {
      subModuleName:'Manuals',
      _id:32
    },
    {
      subModuleName:'Guidelines',
      _id:36
    },
    // {
    //   subModuleName:'Press Releases',
    //   _id:33
    // },
    // {
    //   subModuleName:'Publications',
    //   _id:34
    // },
    {
      subModuleName:'Annual Reports',
      _id:35
    },
    {
      subModuleName:'Evaluation and Study Reports',
      _id:37
    }
  ];
  searchForm = new FormGroup({
    searchValue: new FormControl(''),
  });
  isLoginPage:boolean=false;
  isLoggedIn:boolean= false;
  knowledgeDocumentsStatus: boolean = false
  mediaGalleryStatus: boolean = false
  themeStatus: boolean = false
  sustainableAgricultureStatus: boolean = false
  liveStockStatus: boolean = false
  valueChainDevelopmentStatus: boolean = false
  specialInterventionStatus: boolean = false
  openReportSectionStatus: boolean = false
  mahilaKisanStatus: boolean = false;
  producerGroups: boolean = false;
  others: boolean = false
  constructor(
    public router: Router,
    private location: Location,
    private authService:AuthGuardService,
    private scroll: ViewportScroller,
  ) {
    router.events.subscribe(val => {
      if (this.location.path()== "/login") {
        this.isLoginPage = true;
      } else {
        this.isLoginPage = false;
      }
    });
    this.authService.isLoggedIn();
    this.authService.isLogIn.subscribe((res:any)=>{
      this.isLoggedIn = res
    })
    // this.isLoggedIn =this.authService.isLoggedIn()

  }

  openProducerGroups() {
    this.producerGroups = !this.producerGroups
    this.mahilaKisanStatus = false
    this.others = false
  }

  openOthers() {
    this.others = !this.others
    this.mahilaKisanStatus = false
    this.producerGroups = false
  }

  closeOrders() {
    this.openDigitalLibraryOption = false
  }

  closeEvaluation() {
    this.openDigitalLibraryOption = false
  }

  closeBestPractises() {
    this.openDigitalLibraryOption = false
  }

  openFLComponents() {
    this.flComponentsStatus = !this.flComponentsStatus
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false

  }

  openFLNRLM() {
    this.flComponentsStatus = false
  }

  openFLNRETP() {
    this.flComponentsStatus = false
  }

  openFLMKSP() {
    this.flComponentsStatus = false
  }

  openInnovativeProjects() {
    this.flComponentsStatus = false
  }

  openDigitalLibrary() {
    this.digitalLibraryStatus = !this.digitalLibraryStatus
    this.flComponentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }

  openOrders() {
    this.digitalLibraryStatus = false
  }

  openEvaluation() {
    this.digitalLibraryStatus = false
  }

  openBestPractices() {
    this.digitalLibraryStatus = false
  }

  openKnowledgeDocuments() {
    this.knowledgeDocumentsStatus = !this.knowledgeDocumentsStatus
  }

  openHumanResourceDocuments() {
    this.knowledgeDocumentsStatus = false
    this.digitalLibraryStatus = false
  }

  openSustainableAgricultureDocuments() {
    this.knowledgeDocumentsStatus = false
    this.digitalLibraryStatus = false

  }

  openTrainingCapacityDocuments() {
    this.knowledgeDocumentsStatus = false
    this.digitalLibraryStatus = false
  }

  openMediaGallery() {
    this.mediaGalleryStatus = !this.mediaGalleryStatus
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }

  openPhotoGallery() {
    this.mediaGalleryStatus = false
  }

  openVideoGallery() {
    this.mediaGalleryStatus = false
  }

  openThemes() {
    this.themeStatus = !this.themeStatus
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
  }

  openSustainableAgriculture() {
    this.sustainableAgricultureStatus = !this.sustainableAgricultureStatus
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }

  openHorticulture() {
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
  }

  openNaturalFarming() {
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
  } 

  openOrganicFarming() {
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
  }

  openAgroforestry() {
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
  }

  openLiveStock() {
    this.liveStockStatus = !this.liveStockStatus
    this.sustainableAgricultureStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }

  openPoultry() {
    this.themeStatus = false
    this.liveStockStatus = false
  }

  openGoatRearing() {
    this.themeStatus = false
    this.liveStockStatus = false
  }

  openFishRearing() {
    this.themeStatus = false
    this.liveStockStatus = false
  }

  openPigRearing() {
    this.themeStatus = false
    this.liveStockStatus = false
  }

  openDairyPromotion() {
    this.themeStatus = false
    this.liveStockStatus = false
  }

  openNTFP() {
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.liveStockStatus = false
  }

  openValueChainDevelopment() {
    this.valueChainDevelopmentStatus = !this.valueChainDevelopmentStatus
    this.sustainableAgricultureStatus = false
    this.specialInterventionStatus = false
    this.liveStockStatus = false
  }

  openPGPromotion() {
    this.themeStatus = false
    this.valueChainDevelopmentStatus = false
  }

  openPEPromotion() {
    this.themeStatus = false
    this.valueChainDevelopmentStatus = false
  }

  openFPOPromotion() {
    this.themeStatus = false
    this.valueChainDevelopmentStatus = false
  }

  openSpeicalInterventions() {
    this.specialInterventionStatus = !this.specialInterventionStatus
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
  }

  openMilletPromotion() {
    this.themeStatus = false
    this.specialInterventionStatus = false
  }

  openIFCPromotion() {
    this.themeStatus = false
    this.specialInterventionStatus = false
  }

  openSubSectorPromotion() {
    this.themeStatus = false
    this.specialInterventionStatus = false
  }

  openSLACC() {
    this.themeStatus = false
    this.specialInterventionStatus = false
  }

  openLakPatiInitiatives() {
    this.themeStatus = false
    this.specialInterventionStatus = false
  }

  openReportSection() {
    this.openReportSectionStatus = !this.openReportSectionStatus
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }

  openMahilaKisan() {
    this.mahilaKisanStatus = !this.mahilaKisanStatus
    this.producerGroups = false
    this.others = false
  }

  enterSubmit(event:Event,value:any){
    console.log(this.searchForm.value)
    if(value.searchValue.trim()==''){
      this.router.navigate(['home'])
      return
    }
    this.searchForm.reset();
    this.router.navigate(['search',value.searchValue])
  }
  ngOnInit(): void {


    const textSizeJson = sessionStorage.getItem('nrlmTextSize');
    const getTextSize = textSizeJson !== null ? JSON.parse(textSizeJson) : "";
    if(getTextSize){
      this.setFontSize(getTextSize.currentTextSize)
    }

    sessionStorage.getItem("whatSubDropdown") === 'true' ? this.whatSubDropdown = true : this.whatSubDropdown = false 
    sessionStorage.getItem("workStructureSubDropdown") === 'true' ? this.workStructureSubDropdown = true : this.workStructureSubDropdown = false
    sessionStorage.getItem("aboutUsSubDropdown") === 'true' ? this.aboutUsSubDropdown = true : this.aboutUsSubDropdown = false 
    sessionStorage.getItem("ourImpactSubDropdown") === 'true' ? this.ourImpactSubDropdown = true : this.ourImpactSubDropdown = false 
    sessionStorage.getItem("resourceSubDropdown") === 'true' ? this.resourceSubDropdown = true : this.resourceSubDropdown = false 
  }

  setFontSize(size: string){
    // console.log(size)
    // this.size= size;
    let elem = document.body;

    this.textSize.forEach(item => elem.classList.remove(item));
    elem.classList.add(size);
    this.currentTextSize = size;
    sessionStorage.setItem('nrlmTextSize', JSON.stringify({
      'currentTextSize': size,
    }));
  }

  hasRoute(route: string) {
    return this.router.url.includes(route);
  }

  getToResourceSubModule(data:any){
    this.router.navigate(
      ['/resource-center'],
      { queryParams: { 'subModule': data.subModuleName, '_id': data._id } }
    );
    console.log('submodules data----->', data);
    this.activeButton = data?.subModuleName;
  }
  focusSearch(){
   let input = document.getElementById("searchFormInput");
   if(input){
     input?.focus()
   }
  }

  openAboutDropdown() {
    this.aboutUsDropdown = !this.aboutUsDropdown
    this.whatDropdown = false
    this.workStructureDropdown = false
    this.impactDropdown = false
    this.resourceDropdown = false
    console.log('About Us Dropdown ', this.aboutUsDropdown)
  }

  openWhatDropdown() {
    this.whatDropdown = !this.whatDropdown
    this.aboutUsDropdown = false
    this.workStructureDropdown = false
    this.impactDropdown = false
    this.resourceDropdown = false
  }

  openWorkStructureDropdown() {
    this.workStructureDropdown = !this.workStructureDropdown
    this.aboutUsDropdown = false
    this.whatDropdown = false
    this.impactDropdown = false
    this.resourceDropdown = false
  }

  openImpactDropdown() {
    this.impactDropdown = !this.impactDropdown
    this.resourceDropdown = false
    this.workStructureDropdown = false
    this.whatDropdown = false
    this.aboutUsDropdown = false
    this.whatSubDropdown = false
  }

  openResourceDropdown() {
    this.resourceDropdown = !this.resourceDropdown
    this.aboutUsDropdown = false
    this.whatDropdown = false
    this.workStructureDropdown = false
    this.impactDropdown = false
    this.whatSubDropdown = false
  }

  openWhatSubDropdown() {
    this.whatSubDropdown = true
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "true")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")

  }

  openWorkstructureSubDropdown() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = true
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "true")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  openAboutusSubDropdown() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = true
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "true")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  openOurImpactSubDropdown() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = true
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "true")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  openResourceSubDropdown() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = true
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "true")
  }

  openHome() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }

  openConvergencePartners() {
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }
  openDashboard() {
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }

  openUploadModule() {
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }

  hideOtherModule() {
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.openReportSectionStatus = false
    this.mahilaKisanStatus = false
    this.producerGroups = false
    this.others = false
    this.mediaGalleryStatus = false
    this.flComponentsStatus = false
    this.digitalLibraryStatus = false
    this.knowledgeDocumentsStatus = false
    this.mediaGalleryStatus = false
    this.themeStatus = false
    this.sustainableAgricultureStatus = false
    this.liveStockStatus = false
    this.valueChainDevelopmentStatus = false
    this.specialInterventionStatus = false
  }

  openCareers() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  showFakePdf(){
    let link = document.createElement('a');
    if (link.download !== undefined) {
      link.setAttribute('href', this.fakePdfUrl);
      link.setAttribute('target', "_blank");
      link.setAttribute('download', '');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
}

scrollToBottom() {
  this.scroll.scrollToPosition([0, 5000]);
}

navigateOutside(url:string=''){
  window.open(url, "_blank");
}
}
