import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgroEcologicalProjectsComponent } from './agro-ecological-projects/agro-ecological-projects.component';
import { HorticultureComponent } from './horticulture/horticulture.component';
import { NaturalFarmingComponent } from './natural-farming/natural-farming.component';
import { OrganicFarmingComponent } from './organic-farming/organic-farming.component';
import { AgroforestryComponent } from './agroforestry/agroforestry.component';
import { PoultryComponent } from './poultry/poultry.component';
import { GoatRearingComponent } from './goat-rearing/goat-rearing.component';
import { FishRearingComponent } from './fish-rearing/fish-rearing.component';
import { PigRearingComponent } from './pig-rearing/pig-rearing.component';
import { DairyPromotionComponent } from './dairy-promotion/dairy-promotion.component';
import { NtfpComponent } from './ntfp/ntfp.component';
import { PgPromotionComponent } from './pg-promotion/pg-promotion.component';
import { PePromotionComponent } from './pe-promotion/pe-promotion.component';
import { FpoPromotionComponent } from './fpo-promotion/fpo-promotion.component';
import { MilletPromotionComponent } from './millet-promotion/millet-promotion.component';
import { IfcComponent } from './ifc/ifc.component';
import { SubSectorPromotionComponent } from './sub-sector-promotion/sub-sector-promotion.component';
import { SlaccComponent } from './slacc/slacc.component';
import { LakhpatiInitiativesComponent } from './lakhpati-initiatives/lakhpati-initiatives.component';


const routes: Routes = [
    {
		path: "",
        redirectTo: 'introduction', 
		pathMatch: 'full',
	},
    {
        path: "agroEcological",
        component: AgroEcologicalProjectsComponent
    },
    {
        path: "horticulture",
        component: HorticultureComponent
    },
    {
        path: 'naturalFarming',
        component: NaturalFarmingComponent
    },
    {
        path: "organicFarming",
        component: OrganicFarmingComponent
    },
    {
        path: "agroForestry",
        component: AgroforestryComponent
    },
    {
        path: "poultry",
        component: PoultryComponent
    },
    {
        path: "goatRearing",
        component: GoatRearingComponent
    },
    {
        path: "fishRearing",
        component: FishRearingComponent
    },
    {
        path: "pigRearing",
        component: PigRearingComponent
    },
    {
        path: "dairyPromotion",
        component: DairyPromotionComponent
    },
    {
        path: 'ntfp',
        component: NtfpComponent
    },
    {
        path: 'pgPromotion',
        component: PgPromotionComponent
    },
    {
        path: 'pePromotion',
        component: PePromotionComponent
    },
    {
        path: 'fpoPromotion',
        component: FpoPromotionComponent
    },
    {
        path: 'milletPromotion',
        component: MilletPromotionComponent
    },
    {
        path: 'ifc',
        component: IfcComponent
    },
    {
        path: 'subSectorPromotion',
        component: SubSectorPromotionComponent
    },
    {
        path: 'slacc',
        component: SlaccComponent
    },
    {
        path: 'lakhpatiInitiatives',
        component: LakhpatiInitiativesComponent
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes),],
    exports: [RouterModule]
  })
  export class ThemeRoutingModule { }