<section class="py-1 small d-none d-lg-block sectionClass" style="background-color: #02943b;">
    <div class="container" >
        <div class="row">
            <div class="col-md-5 col-lg-5 col-sm-5">
                <ul class="nav">
                    
                    <!-- <li class="nav-item">
                        <a class="nav-link py-0 border-end navHover" [routerLink]="['/multimedia/feedback']"
                            routerLinkActive="active">Feedback</a>
                    </li> -->
                    <!-- <li class="nav-item">
                        <a class="nav-link py-0 border-end navHover" routerLinkActive="active" routerLink="/sitemap">Sitemap</a>
                    </li> -->
                </ul>
            </div>
            <div class="col-md-7 col-lg-7 col-sm-7">
                <ul class="nav  justify-content-end">
                    <li class="nav-item" (click)="hideOtherModule()">
                        <a class="nav-link py-0 border-end navHover" [routerLink]="['/screen-reader-access']">Screen Reader
                            Access</a>
                    </li>
                    <li class="nav-item" (click)="hideOtherModule()">
                        <ng-container *ngFor="let data of textSize">
                            <span (click)="setFontSize(data)" class="text-{{data}} textIcon circleHover" style="cursor: pointer;border: none;"
                                [ngClass]="currentTextSize == data ? 'active' : '' ">A</span>
                        </ng-container>
                    </li>
                    <li  class="nav-item" (click)="hideOtherModule()">
                        <a href="https://www.facebook.com/aajeevika" target="_blank" class="nav-link py-0 circleHover">
                        <i class="fa fa-facebook" aria-hidden="true" style="margin-left: -5px;"></i>
                      </a>
                      </li>
                  
                      <li  class="nav-item" (click)="hideOtherModule()">
                        <a href="https://twitter.com/DAY_NRLM" target="_blank" class="nav-link py-0 circleHover">
                          <i class="fa fa-twitter" aria-hidden="true" style="margin-left: -5px;"></i>
                        </a>
                      </li>
                      <!-- <li class="instagram">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                        <div class="slider">
                          <p>instagram</p>
                        </div>
                      </li> -->
                  
                      <li  class="nav-item" (click)="hideOtherModule()">
                        <a href="http://www.youtube.com/user/aajeevikaNRLM" target="_blank" class="nav-link py-0 circleHover">
                          <i class="fa fa-youtube" aria-hidden="true" style="margin-left: -5px;"></i>
                        </a>
                  
                  
                      </li>
                </ul>
            </div>
            
        </div>
    </div>
</section>
<section class="py-2 bg-white shadow">
    <div class="container" style="height: 85px;">
        <div class="row justify-content-between align-items-center">
            <div class="col-lg-3 col-6 text-right">
                <img src="/assets/images/NewMord.png" alt="Deendayal Antyodaya Yojana"
                    title="Deendayal Antyodaya Yojana" class="img-fluid" />
            </div>
            <div class="col-lg-6 col-6 d-flex" style="display: flex;justify-content: right; align-items: center;">
                <div class="login-button-css">
                    <a href="https://mksp.gov.in/portal/login.nic" target="_blank">Go to MIS</a>
                </div>
                <div>
                    <img src="/assets/images/AAJEEVIKA LOGO png.png" style="height: 60px;width: 85px;"
                    alt="Ministry of Rural Development (MoRD), Government of India"
                    title="Ministry of Rural Development (MoRD), Government of India" class="img-fluid" />
                </div>
                <div style="margin-left: 10px; margin-top: 15px; ">
                    <p style="margin: 0; font-size: 20px;">DAY-NRLM</p>
                    <p style="font-size: 20px;">Farm Livelihood</p>
                </div>
                    <!-- <p class="ms-3 mt-1"><b style="font-size: 15px;
                        font-weight: 600;">Deendayal Antyodaya Yojana <br> National Rural Livelihoods Mission</b></p> -->
            </div>
        </div>
    </div>
</section>
<nav class="navbar navbar-expand-lg navbar-dark bg-primary nav-primary" *ngIf="!hasRoute('admin')" style="display: none;">
    <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                <li class="nav-item" (click)="openHome()">
                    <a class="nav-link" aria-current="page" routerLinkActive="active" routerLink="/home">Home</a>
                </li>

                <li class="nav-item" (click)="openDashboard()">
                    <a class="nav-link" href="https://lookerstudio.google.com/u/0/reporting/c6e6022d-9eb7-4aeb-83c3-ed08947f6c82/page/p_n91sk52duc"
                        target="_blank">Dashboard</a>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" (click)="openAboutDropdown()">
                    <a class="nav-link dropdown-toggle dropdown dropdown-6" id="aboutUs" role="button"
                        aria-expanded="false" [style.color]="aboutUsSubDropdown === true ? '#fff' : '#cba6a6'">
                        About Us
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6"
                        [style.display]="aboutUsDropdown === true ? 'block' : 'none'" aria-labelledby="aboutUs">
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/introduction'" routerLink="/about/introduction"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active" aria-controls="navbarText"
                                aria-expanded="false" (click)="openAboutusSubDropdown()">Introduction</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/founding-philosophy'" routerLink="/about/founding-philosophy"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" (click)="openAboutusSubDropdown()">Founding
                                Philosophy</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/genesis'" routerLink="/about/genesis"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Genesis</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/goal'" routerLink="/about/goal"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Goal</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/guiding-principles'" routerLink="/about/guiding-principles"><a class="dropdown-item"  data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Guiding Principles </a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/values'" routerLink="/about/values"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Values</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/team'" routerLink="/about/team"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Team</a></li>
                    </ul>
                </li>

                <li class="nav-item dropdown" (click)="openWhatDropdown()">
                    <a class="nav-link dropdown-toggle  dropdown dropdown-6" routerLinkActive="active" id="whatWeDo"
                        role="button" aria-expanded="false"
                        [style.color]="whatSubDropdown === true ? '#fff' : '#cba6a6'">
                        What We Do
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6" aria-labelledby="whatWeDo"
                        [style.display]="whatDropdown === true ? 'block' : 'none'">
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/institutional-capacity-building'" routerLink="/what-we-do/institutional-capacity-building"><a class="dropdown-item" 
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Institutional Building and Capacity Building</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/social-inclusion-and-social-development'" routerLink="/what-we-do/social-inclusion-and-social-development"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Social Inclusion and Social Development</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/universal-financial-inclusion'" routerLink="/what-we-do/universal-financial-inclusion"><a class="dropdown-item" 
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Universal Financial Inclusion</a></li>
                        <li class="dropdown-ele" class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/livelihoods'" routerLink="/what-we-do/livelihoods"><a class="dropdown-item"  data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Livelihoods</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/monitoring-system-and-mis'" routerLink="/what-we-do/monitoring-system-and-mis"><a class="dropdown-item" 
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Programme Management</a></li>
                        <li routerLink="/what-we-do/national-rural-economic-transformation-project" class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/national-rural-economic-transformation-project'"><a class="dropdown-item"
                               
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">National Rural Economic Transformation Project</a></li>
                    </ul>
                </li>
                <!--  -->
                <li class="nav-item dropdown" (click)="openWorkStructureDropdown()">
                    <a class="nav-link dropdown-toggle  dropdown dropdown-6" id="workStructure" role="button"
                        routerLinkActive="active" aria-expanded="false"
                        [style.color]="workStructureSubDropdown === true ? '#fff' : '#cba6a6'">
                        Work Structure
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6" aria-labelledby="workStructure"
                        [style.display]="workStructureDropdown === true ? 'block' : 'none'">
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/approach'" routerLink="/work-structure/approach"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Approach</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/support-units'" routerLink="/work-structure/support-units"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Support Units</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/resource-blocks'" routerLink="/work-structure/resource-blocks"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Resource Blocks</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/intensive-blocks'" routerLink="/work-structure/intensive-blocks"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Intensive Blocks</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/partnership-blocks'" routerLink="/work-structure/partnership-blocks"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Partnership Blocks</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/non-intensive-blocks'" routerLink="/work-structure/non-intensive-blocks"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Non-Intensive Blocks</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/exit-strategy'" routerLink="/work-structure/exit-strategy"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Exit Strategy</a></li>
                    </ul>
                </li>
                <!--  -->
                <li class="nav-item dropdown" (click)="openImpactDropdown()">
                    <a class="nav-link dropdown-toggle  dropdown dropdown-6" id="ourImpact" role="button"
                        aria-expanded="false" [style.color]="ourImpactSubDropdown === true ? '#fff' : '#cba6a6'">
                        Our Impact
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6" aria-labelledby="ourImpact"
                        [style.display]="impactDropdown === true ? 'block' : 'none'">
                        <li routerLink="/our-impact/overview" routerLinkActive="active" [class.selectedList]="router.url == '/our-impact/overview'" class="dropdown-ele"><a class="dropdown-item"  data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" 
                                (click)="openOurImpactSubDropdown()">Overview</a></li>
                        <li routerLinkActive="active" routerLink="/our-impact/success-stories" [class.selectedList]="router.url == '/our-impact/success-stories'" class="dropdown-ele"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" 
                                (click)="openOurImpactSubDropdown()">Success Stories</a></li>
                        <!-- <li><a class="dropdown-item" routerLink="/our-impact/fact-sheet">Fact Sheets</a></li>
                        <li><a class="dropdown-item" routerLink="/our-impact/milestones">Milestones</a></li> -->
                    </ul>
                </li>

                <li class="nav-item dropdown" (click)="openResourceDropdown()">
                    <a class="nav-link dropdown-toggle  dropdown dropdown-6" id="resourceCentre" role="button"
                        aria-expanded="false" [style.color]="resourceSubDropdown === true ? '#fff' : '#cba6a6'">
                        Resource Centre
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6" aria-labelledby="resourceCentre"
                        [style.display]="resourceDropdown === true ? 'block' : 'none'">
                        <li routerLinkActive="active" class="dropdown-ele" [class.selectedList]="activeButton === item?.subModuleName" (click)="getToResourceSubModule(item);openResourceSubDropdown()" style="cursor: pointer;" *ngFor="let item of resourceSubModules"><a class="dropdown-item"
                                 data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show"
                                >{{item.subModuleName}}</a></li>

                    </ul>
                </li>

                <li class="nav-item" routerLink="/join-us" routerLinkActive="active">
                    <a class="nav-link"  data-bs-toggle="collapse"
                        data-bs-target="#navbarText.show" (click)="openCareers()">Careers</a>
                </li>
            </ul>
            <ul class="nav">
                <li class="nav-item d-flex" *ngIf="!isLoginPage">
                    <form [formGroup]="searchForm" class="d-flex bg-white rounded searchForm"
                        (ngSubmit)="enterSubmit($event, searchForm.value)">
                        <input type="text" name="searchForm" id="searchFormInput" class="form-control border-0"
                            formControlName="searchValue" placeholder="search...">
                        <button class="btn btn-link"><i class="fa fa-search icon"></i></button>
                    </form>


                </li>
                <!-- <li class="nav-item">
                    <a   class="btn btn-login">Login</a>
                </li> -->
            </ul>
        </div>
    </div>
</nav>

<nav class="nav-ajvk bg-primary nav-primary">
    <div class="container">

        <div class="row collapse navbar-collapse" id="navbarContent" style="background-color: #02943b">
            <div class="col-md-9 col-sm-9" style="background-color: #02943b; width: 100%;">
                <ul class="cust-navbar">
                    <li routerLinkActive="active" class="nav-ele" (click)="openHome()">
                        <a routerLink="/home">Home</a>
                    </li>
                    <li class="nav-ele" (click)="openDashboard()">
                        <a href="https://lookerstudio.google.com/u/0/reporting/c6e6022d-9eb7-4aeb-83c3-ed08947f6c82/page/p_n91sk52duc" target="_blank">Dashboard</a>
                    </li>
                    <!-- About Us -->
                    <!-- <li class="nav-ele" routerLinkActive="active">
                        <a href="#">About Us
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/introduction'" routerLinkActive="active" routerLink="/about/introduction">
                                <a>Introduction</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/founding-philosophy'" routerLinkActive="active" routerLink="/about/founding-philosophy">
                                <a>Founding Philosophy</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/genesis'" routerLinkActive="active" routerLink="/about/genesis">
                                <a>Genesis</a></li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/goal'" routerLinkActive="active" routerLink="/about/goal">
                                <a>Goal</a></li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/guiding-principles'" routerLinkActive="active" routerLink="/about/guiding-principles">
                                <a>Guiding Principles</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/values'" routerLinkActive="active" routerLink="/about/values">
                                <a>Values</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/team'" routerLinkActive="active" routerLink="/about/team">
                                <a>Team</a>
                            </li>
                        </ul>
                    </li> -->
                    <!-- New Header list for the mksp -->
                    <li class="nav-ele" routerLinkActive="active" [class.finalSelectedList]="router.url == '/fl/nrlm' || router.url == '/fl/nretp' || router.url == '/fl/mksp' || router.url == '/fl/innovative'">
                        <div (click)="openFLComponents()">
                            <a>Components
                                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </a>
                        </div>
                        <ul class="dropdown" *ngIf="flComponentsStatus">
                            <li  (click)="openFLNRLM()" class="dropdown-ele" [class.selectedList]="router.url == '/fl/nrlm'" routerLinkActive="active" routerLink="/fl/nrlm">
                                <a>NRLM-AAP</a>
                            </li>
                            <li (click)="openFLNRETP()" class="dropdown-ele" [class.selectedList]="router.url == '/fl/nretp'" routerLinkActive="active" routerLink="/fl/nretp">
                                <a>NRETP</a>
                            </li>
                                <li (click)="openFLMKSP()" class="dropdown-ele" [class.selectedList]="router.url == '/fl/mksp'" routerLinkActive="active" routerLink="/fl/mksp">
                                    <a>MKSP</a></li>
                                <li (click)="openInnovativeProjects()" class="dropdown-ele" [class.selectedList]="router.url == '/fl/innovative'" routerLinkActive="active" routerLink="/fl/innovative">
                                    <a>INNOVATIVE/SPECIAL PROJECTS</a></li>
                        </ul>
                    </li>
                    <!-- Themes -->
                    <li class="nav-ele" routerLinkActive="active" [class.finalSelectedList]="router.url == '/theme/horticulture' || router.url == '/theme/naturalFarming' || router.url == '/theme/organicFarming' || router.url == '/theme/agroForestry' || router.url == '/theme/poultry' || router.url == '/theme/goatRearing' || router.url == '/theme/fishRearing' || router.url == '/theme/pigRearing' || router.url == '/theme/dairyPromotion' || router.url == '/theme/ntfp' || router.url == '/theme/pgPromotion' || router.url == '/theme/pePromotion' || router.url == '/theme/fpoPromotion' || router.url == '/theme/milletPromotion' || router.url == '/theme/ifc' || router.url == '/theme/slacc'">
                        <div (click)="openThemes()">
                            <a>Themes
                                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </a>
                        </div>
                        <ul class="dropdown" *ngIf="themeStatus">
                            <li class="dropdown-ele" routerLinkActive="active" (click)="openSustainableAgriculture()">
                                <div style="display: flex; align-items: center; justify-content: space-between;">
                                    <a>Sustainable Agriculture</a>
                                    <i class="fa fa-chevron-right" style="color: black" aria-hidden="true"></i>
                                </div>
                                <ul class="dropdown" style="position: absolute; left: 100%; top: 0%;" *ngIf="sustainableAgricultureStatus">
                                    <li (click)="openHorticulture()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/horticulture'" routerLinkActive="active" routerLink="/theme/horticulture">
                                        <a>Horticulture</a>
                                    </li>
                                    <li (click)="openNaturalFarming()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/naturalFarming'" routerLinkActive="active" routerLink="/theme/naturalFarming">
                                        <a>Natural Farming</a>
                                    </li>
                                    <li (click)="openOrganicFarming()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/organicFarming'" routerLinkActive="active" routerLink="/theme/organicFarming">
                                        <a>Organic Farming</a>
                                    </li>
                                    <li (click)="openAgroforestry()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/agroForestry'" routerLinkActive="active" routerLink="/theme/agroForestry">
                                        <a>Agroforestry</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-ele" routerLinkActive="active" (click)="openLiveStock()">
                                <div style="display: flex; align-items: center; justify-content: space-between;">
                                    <a>Livestock</a>
                                    <i class="fa fa-chevron-right" style="color: black" aria-hidden="true"></i>
                                </div>
                                <ul class="dropdown" style="position: absolute; left: 100%; top: 0%;" *ngIf="liveStockStatus">
                                    <li (click)="openPoultry()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/poultry'" routerLinkActive="active" routerLink="/theme/poultry">
                                        <a>Poultry</a>
                                    </li>
                                    <li (click)="openGoatRearing()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/goatRearing'" routerLinkActive="active" routerLink="/theme/goatRearing">
                                        <a>Goat Rearing</a>
                                    </li>
                                    <li (click)="openFishRearing()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/fishRearing'" routerLinkActive="active" routerLink="/theme/fishRearing">
                                        <a>Fish Rearing</a>
                                    </li>
                                    <li (click)="openPigRearing()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/pigRearing'" routerLinkActive="active" routerLink="/theme/pigRearing">
                                        <a>Pig Rearing</a>
                                    </li>
                                    <li (click)="openDairyPromotion()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/dairyPromotion'" routerLinkActive="active" routerLink="/theme/dairyPromotion">
                                        <a>Dairy Promotion</a>
                                    </li>
                                </ul>
                            </li>
                            <li (click)="openNTFP()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/ntfp'" routerLinkActive="active" routerLink="/theme/ntfp">
                                <a>NTFP/Non-Wood Forest Products</a></li>
                            <li class="dropdown-ele" routerLinkActive="active">
                                <div style="display: flex; align-items: center; justify-content: space-between;" (click)="openValueChainDevelopment()">
                                    <a>Value Chain Development</a>
                                    <i class="fa fa-chevron-right" style="color: black" aria-hidden="true"></i>
                                </div>
                                <ul class="dropdown" style="position: absolute; left: 100%; top: 0%;" *ngIf="valueChainDevelopmentStatus">
                                    <li (click)="openPGPromotion()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/pgPromotion'" routerLinkActive="active" routerLink="/theme/pgPromotion">
                                        <a>PG Promotion</a>
                                    </li>
                                    <li (click)="openPEPromotion()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/pePromotion'" routerLinkActive="active" routerLink="/theme/pePromotion">
                                        <a>PE Promotion</a>
                                    </li>
                                    <li (click)="openFPOPromotion()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/fpoPromotion'" routerLinkActive="active" routerLink="/theme/fpoPromotion">
                                        <a>FPO Promotion</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-ele" routerLinkActive="active">
                                <div style="display: flex; align-items: center; justify-content: space-between;" (click)="openSpeicalInterventions()">
                                    <a>Special Interventions</a>
                                    <i class="fa fa-chevron-right" style="color: black" aria-hidden="true"></i>
                                </div>
                                <ul class="dropdown" style="position: absolute; left: 100%; top: 0%; height: 100px;" *ngIf="specialInterventionStatus">
                                    <li (click)="openMilletPromotion()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/milletPromotion'" routerLinkActive="active" routerLink="/theme/milletPromotion">
                                        <a>Millet Promotion</a>
                                    </li>
                                    <li (click)="openIFCPromotion()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/ifc'" routerLinkActive="active" routerLink="/theme/ifc">
                                        <a>Integrated Farming Cluster (IFC)</a>
                                    </li>
                                    <li (click)="openSubSectorPromotion()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/subSectorPromotion'" routerLinkActive="active" routerLink="/theme/subSectorPromotion">
                                        <a>Sub-Sector Promotion</a>
                                    </li>
                                    <li (click)="openSLACC()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/slacc'" routerLinkActive="active" routerLink="/theme/slacc">
                                        <a>SLACC</a>
                                    </li>
                                    <!-- <li (click)="openLakPatiInitiatives()" class="dropdown-ele" [class.selectedList]="router.url == '/theme/lakhpatiInitiatives'" routerLinkActive="active" routerLink="/theme/lakhpatiInitiatives">
                                        <a>Lakhpati Initiatives</a>
                                    </li> -->
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <!-- Convergence/Partners -->
                    <li routerLinkActive="active" class="nav-ele" (click)="openConvergencePartners()">
                        <a routerLink="/convergence/partners">Convergence/Partners</a>
                    </li>
                    <!-- Digital Library -->
                    <li class="nav-ele" routerLinkActive="active" [class.finalSelectedList]="router.url == '/resource/order' || router.url == '/resource/study/reports' || router.url == '/resource/case/study' || router.url == '/resource/knowledgeDocuments/humanResource' || router.url == '/resource/knowledgeDocuments/sustainableAgriculture' || router.url == '/resource/knowledgeDocuments/trainingCapacityBuilding'">
                        <div (click)="openDigitalLibrary()">
                            <a>Digital Library
                                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </a>
                        </div>
                        <ul class="dropdown" *ngIf="digitalLibraryStatus">
                            <li (click)="openOrders()" class="dropdown-ele" [class.selectedList]="router.url == '/resource/order'" routerLinkActive="active" routerLink="/resource/order">
                                <a>Orders, Advisory & Guidelines</a>
                            </li>
                            <li (click)="openEvaluation()" class="dropdown-ele" [class.selectedList]="router.url == '/resource/study/reports'" routerLinkActive="active" routerLink="/resource/study/reports">
                                <a>Evaluation & Study Reports</a>
                            </li>
                            <li (click)="openBestPractices()" class="dropdown-ele" [class.selectedList]="router.url == '/resource/case/study'" routerLinkActive="active" routerLink="/resource/case/study">
                                <a>Best Practices & Case Study</a></li>
                            <li class="dropdown-ele" routerLinkActive="active">
                                <div style="display: flex; align-items: center; justify-content: space-between;" (click)="openKnowledgeDocuments()">
                                    <a>Knowledge Documents</a>
                                    <i class="fa fa-chevron-right" style="color: black" aria-hidden="true"></i>
                                </div>
                                <ul class="dropdown" style="position: absolute; left: 100%; top: 0%;" *ngIf="knowledgeDocumentsStatus">
                                    <li (click)="openHumanResourceDocuments()" class="dropdown-ele" [class.selectedList]="router.url == '/resource/knowledgeDocuments/humanResource'" routerLinkActive="active" routerLink="/resource/knowledgeDocuments/humanResource">
                                        <a>Human Resource</a>
                                    </li>
                                    <li (click)="openSustainableAgricultureDocuments()" class="dropdown-ele" [class.selectedList]="router.url == '/resource/knowledgeDocuments/sustainableAgriculture'" routerLinkActive="active" routerLink="/resource/knowledgeDocuments/sustainableAgriculture">
                                        <a>Sustainable Agriculture</a>
                                    </li>
                                    <li (click)="openTrainingCapacityDocuments()" class="dropdown-ele" [class.selectedList]="router.url == '/resource/knowledgeDocuments/trainingCapacityBuilding'" routerLinkActive="active" routerLink="/resource/knowledgeDocuments/trainingCapacityBuilding">
                                        <a>Training Capacity Building</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <!-- Reports -->
                    <li routerLinkActive="active" class="nav-ele">
                        <div (click)="openReportSection()">
                            <a>Reports <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
                        </div>
                        <ul class="dropdown" *ngIf="openReportSectionStatus">
                            <li class="dropdown-ele" routerLinkActive="active">
                                <div style="display: flex; align-items: center; justify-content: space-between;" (click)="openMahilaKisan()">
                                    <a>Mahil Kisan</a>
                                    <i class="fa fa-chevron-right" style="color: black" aria-hidden="true"></i>
                                </div>
                                <ul class="dropdown" style="position: absolute; left: 100%; top: 0%;" *ngIf="mahilaKisanStatus">
                                    <li  class="dropdown-ele" (click)="navigateOutside('https://mksp.gov.in/portal/ViewProjectsStatus.nic?value=1')">
                                        <a alt="Mahila Kisan Project wise Report">Mahila Kisan (AEP)-Project wise</a>
                                    </li>
                                    <li class="dropdown-ele" (click)="navigateOutside('https://mksp.gov.in/portal/ViewProjectsStatus.nic?value=2')">
                                        <a alt="Mahila Kisan Geography wise Report">Mahila Kisan (AEP)-Geography wise</a>
                                    </li>
                                    <li class="dropdown-ele" (click)="navigateOutside('https://mksp.gov.in/portal/viewProjectSHGCount.nic')">
                                        <a  alt="SHG wise Mahila Kisan Report">Mahila Kisan (AEP)-SHG wise</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-ele" routerLinkActive="active">
                                <div style="display: flex; align-items: center; justify-content: space-between;" (click)="openProducerGroups()">
                                    <a>Producers</a>
                                    <i class="fa fa-chevron-right" style="color: black" aria-hidden="true"></i>
                                </div>
                                <ul class="dropdown" style="position: absolute; left: 100%; top: 0%;" *ngIf="producerGroups">
                                    <li  class="dropdown-ele">
                                        <a>Producer Groups</a>
                                    </li>
                                    <li class="dropdown-ele">
                                        <a>Producer Enterprises</a>
                                    </li>
                                    <li class="dropdown-ele">
                                        <a>Farmer Producer Organization</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-ele" routerLinkActive="active">
                                <div style="display: flex; align-items: center; justify-content: space-between;" (click)="openOthers()">
                                    <a>Others</a>
                                    <i class="fa fa-chevron-right" style="color: black" aria-hidden="true"></i>
                                </div>
                                <ul class="dropdown" style="position: absolute; left: 100%; top: 0%;" *ngIf="others">
                                    <li  class="dropdown-ele">
                                        <a>Community Cadre</a>
                                    </li>
                                    <li class="dropdown-ele">
                                        <a>IFC (Integrated Farming Cluster)</a>
                                    </li>
                                    <li class="dropdown-ele">
                                        <a>Convergence</a>
                                    </li>
                                    <li class="dropdown-ele">
                                        <a>Sub-Sector Promotion</a>
                                    </li><li class="dropdown-ele">
                                        <a>House Hold Lakhpati Survey</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <!-- Media Gallery -->
                    <li routerLinkActive="active" class="nav-ele" [class.finalSelectedList]="router.url == '/mediaGallery' || router.url == '/videoUpload'">
                        <div (click)="openMediaGallery()">
                            <a>Media Gallery <i class="fa fa-chevron-down" aria-hidden="true"></i> </a>
                        </div>
                        <ul class="dropdown" *ngIf="mediaGalleryStatus">
                            <li (click)="openPhotoGallery()" class="dropdown-ele" [class.selectedList]="router.url == '/mediaGallery'" routerLinkActive="active" routerLink="/mediaGallery">
                                <a>Photos Gallery</a>
                            </li>
                            <li (click)="openVideoGallery()" class="dropdown-ele" [class.selectedList]="router.url == '/videoUpload'" routerLinkActive="active" routerLink="/videoUpload">
                                <a>Videos Gallery</a>
                            </li>
                        </ul>
                    </li>
                    <!-- Lakhpati Initiatives -->
                    <!-- Convergence/Partners -->
                    <li routerLinkActive="active" class="nav-ele" (click)="openLakPatiInitiatives()">
                        <a routerLink="/theme/lakhpatiInitiatives">Lakhpati Initiatives</a>
                    </li>
                    <!-- New Header list for the mksp -->
                    <!-- <li [routerLink]="headers.mainUrl === true ? [headers.url] : null" class="nav-ele" *ngFor="let headers of newHeadersList; let i = index">
                        <a>{{headers.menuName}}
                            <i class="fa fa-chevron-down" *ngIf="headers?.subMenuList && headers.subMenuList.length > 0" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li [routerLink]="subMenuHeaders.mainSubUrl === true ? [subMenuHeaders.subUrl] : null" class="dropdown-ele" *ngFor="let subMenuHeaders of headers.subMenuList">
                                <div style="display: flex; align-items: center; justify-content: space-between;">
                                    <a>{{subMenuHeaders.subMenuName}}</a>
                                    <i class="fa fa-chevron-right" style="color: black" aria-hidden="true" *ngIf="subMenuHeaders?.subMenuListAgain && subMenuHeaders.subMenuListAgain.length > 0"></i>
                                </div>
                                <ul class="dropdown" style="position: absolute; left: 100%; top: 0%;"> 
                                    <li class="dropdown-ele" [class.selectedList]="router.url == subMenuHeadersAgain.subUrl" routerLinkActive="active" [routerLink]="[subMenuHeadersAgain.subUrl]" *ngFor="let subMenuHeadersAgain of subMenuHeaders.subMenuListAgain">
                                        <a>{{subMenuHeadersAgain.subMenuNameAgain}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li> -->
                    <!-- Archive -->
                    <li routerLinkActive="active" class="nav-ele">
                        <a>Archive</a>
                    </li>
                    <!-- Contact Us -->
                    <li routerLinkActive="active" class="nav-ele" (click)="scrollToBottom()">
                        <a>Contact Us</a>
                    </li>
                    <!-- Login -->
                    <!-- <li *ngIf="!isLoggedIn">
                        <a href="https://mksp.gov.in/login.nic">Login</a>
                    </li> -->
                    <!-- Upload Module -->
                    <li *ngIf="isLoggedIn" (click)="hideOtherModule()">
                        <a routerLinkActive="active" routerLink="/admin/content">Admin Content</a>
                    </li>
                    <li *ngIf="!isLoggedIn" (click)="openUploadModule()">
                        <a routerLinkActive="active" routerLink="/login">Upload Module</a>
                    </li>
                    <!-- <li class="nav-ele" routerLinkActive="active">
                        <a href="#">What We Do
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/institutional-capacity-building'" routerLinkActive="active" routerLink="/what-we-do/institutional-capacity-building">
                                <a>Institutional Building and Capacity Building</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/social-inclusion-and-social-development'" routerLinkActive="active" routerLink="/what-we-do/social-inclusion-and-social-development">
                                <a>Social Inclusion and Social Development</a></li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/universal-financial-inclusion'" routerLinkActive="active" routerLink="/what-we-do/universal-financial-inclusion">
                                <a>Universal Financial Inclusion</a></li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/livelihoods'" routerLinkActive="active" routerLink="/what-we-do/livelihoods">
                                <a>Livelihoods</a>
                            </li>
                            <li class="dropdown-ele">
                                <a routerLinkActive="active" routerLink="/what-we-do/monitoring-system-and-mis">
                                    Monitoring System and MIS
                                </a>
                            </li>
                            <li class="dropdown-ele">
                                <a routerLinkActive="active" routerLink="/what-we-do/knowledge-management">
                                    Knowledge Management and Communication
                                </a>
                            </li>
                            <li class="dropdown-ele">
                                <a routerLinkActive="active" routerLink="/what-we-do/national-rural-economic-transformation-project">National Rural Economic Transformation Project</a>
                            </li>
                        </ul>
                    </li> -->
                    <!-- <li class="nav-ele" routerLinkActive="active">
                        <a >Implementation Structure
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/approach'"  routerLinkActive="active" routerLink="/work-structure/approach">
                                <a>Approach</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/support-units'" routerLinkActive="active" routerLink="/work-structure/support-units">
                                <a>Support Units</a></li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/community-institutional'" routerLinkActive="active" routerLink="/work-structure/community-institutional">
                                <a>Community Institutional Architecture</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/exit-strategy'" routerLinkActive="active" routerLink="/work-structure/exit-strategy">
                                <a>Exit Strategy</a>
                            </li>
                        </ul>
                    </li> -->
                    <!-- <li class="nav-ele" routerLinkActive="active">
                        <a href="#">Our Impact
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/our-impact/overview'" routerLinkActive="active" routerLink="/our-impact/overview">
                                <a>Overview</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/our-impact/success-stories'" routerLinkActive="active" routerLink="/our-impact/success-stories">
                                <a>Success Stories</a>
                            </li>
                        </ul>
                    </li> -->
                    <!-- <li class="nav-ele" routerLinkActive="active">
                        <a href="#">Resource Centre
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" *ngFor="let item of resourceSubModules" (click)="getToResourceSubModule(item);openResourceSubDropdown()"
                            [class.selectedList]="activeButton === item?.subModuleName">
                                <a routerLinkActive="active">
                                    {{item.subModuleName}}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li routerLinkActive="active" class="nav-ele" routerLink="/join-us">
                        <a>Careers</a>
                    </li> -->
                  
                </ul>
               
            </div>
            <div class="col-md-3 col-sm-3">
                <!-- <ul>
                  <li class="nav-ele hideBorder" *ngIf="!isLoginPage">
                    <form [formGroup]="searchForm" class="d-flex searchForm"
                    (ngSubmit)="enterSubmit($event, searchForm.value)">
                        <input class="input-transparent borderRadius" type="text" placeholder="Search" name="searchForm" formControlName="searchValue">             
                    </form>
                  </li>
                </ul> -->
            </div>        
        </div>
    </div>
</nav>