<div class="container rounded bg-white mt-2 mb-4 p-3" style="box-shadow: 0px 4px 8px #00000029;">
  <h3 class="title text-black mt-2 px-3 pt-3">
    <a class="btn btn-link" (click)="goBack()">
      <img src="../../../../assets/icons/arrow_back_black_24dp.svg" alt="back icon">
    </a>
    {{title}}
    <span>
      <hr style="border:1px solid #04040433;">
    </span>
  </h3>
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="form-group" autocomplete="off">
    <div class="row row-cols-3">

      <div class=" px-3 mt-2" *ngIf="dynamicForm.name">
        <div class="col-sm-12">
          <div class="d-flex flex-column">
            <label for="name">Name<span class="text-danger">*</span></label>
            <input class=" form-control rounded" style="border: 1px solid #0000001A;" type="text" id="name"
              [ngClass]="{'disabled': isViewMode}" placeholder="Enter Name" name="name" required formControlName="name">
          </div>
        </div>
      </div>
      <div class=" px-3 mt-2" *ngIf="dynamicForm.mobile">
        <div class="col-sm-12 ">
          <div class="d-flex flex-column">
            <label for="mobile">Mobile<span class="text-danger">*</span></label>
            <input id="mobile" class=" mobile form-control rounded" style="border: 1px solid #0000001A;"
              (keypress)="keyPress($event)" type="text" [ngClass]="{'disabled': isViewMode}" maxlength="10"
              placeholder="Enter Mobile Number" name="mobile" formControlName="mobile" required autocomplete="off">
            <!-- <div *ngIf="f.mobile.touched && f.mobile.invalid" class="alert alert-danger">
              <div *ngIf="f.mobile.errors?.required">Please enter mobile number</div>
              <div *ngIf="f.mobile.errors?.pattern">Mobile number is not 10 digit</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class=" px-3 mt-2" *ngIf="dynamicForm.username">
        <div class="col-sm-12">
          <div class="d-flex flex-column">
            <label for="username">Username<span class="text-danger">*</span></label>
            <input class="form-control rounded" style="border: 1px solid #0000001A;" type="text" id="username"
              [ngClass]="{'disabled': isViewMode}" placeholder="Enter username" name="username"
              formControlName="username" autocomplete="username" required>
          </div>
        </div>
      </div>
      <div class=" px-3 mt-2" *ngIf="dynamicForm.email">
        <div class="col-sm-12">
          <div class="d-flex flex-column">
            <label for="email">Email<span class="text-danger">*</span></label>
            <input class="form-control rounded" style="border: 1px solid #0000001A;" type="email" id="email"
              [ngClass]="{'disabled': isViewMode}" placeholder="Enter Email" name="email" formControlName="email"
              required>
          </div>
        </div>
      </div>
      <div class=" px-3 mt-2" *ngIf="dynamicForm.password">
        <div class="col-sm-12">
          <div class="d-flex flex-column">
            <label for="password">Password<span class="text-danger">*</span></label>
            <input class="form-control rounded" style="border: 1px solid #0000001A;" type="password" id="password"
              [ngClass]="{'disabled': isViewMode}" placeholder="Enter Password" name="password"
              formControlName="password" required autocomplete="new-password">
          </div>
        </div>
      </div>
      <div class=" px-3 mt-2" *ngIf="dynamicForm.levelId">
        <div class="col-sm-12 ">
          <div class="d-flex flex-column">
            <label for="userlevel">User Level<span class="text-danger">*</span></label>
            <select required formControlName="levelId" name="userLevelName" id="userlevel"
              (change)="onSelectionChange($event)" class="form-select" [ngClass]="{'disabled': isViewMode}">
              <option selected disabled>Select User Level</option>
              <option *ngFor="let item of userLevel" [value]="item?.id">{{item?.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class=" mt-3 mx-1">
      <div class="col-sm-12">
        <button class="btn btn-bg" type="submit" *ngIf="!isViewMode"
          [disabled]="!userForm.valid || buttonDisable">{{submitButton}}</button>
      </div>
    </div>
  </form>
</div>