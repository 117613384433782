<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="./assets/images/lakhpati_initiative_2.png" style="height: 450px;width: 100%;"  class="img-fluid" />
    </div>
  </div>
</div>
<!-- Welcome section -->
<!-- Welcome Section... -->
<div class="welcomeBg shadow">
  <div class="container gradientPadding">
    <div class=" bg-pattern">
      <div class="wel-cont white-card rounded-3 p-3" id="main-content">
        <div class="row">
          <div class="col-md-9 col-lg-9 col-sm-9 mb-3 mb-lg-0">
            <div class="info-over">
              <div class="image">
              <p class="wel-para1 ms-2">
                The Deendayal Antyodaya Yojana- National Rural Livelihood Mission (DAY-NRLM) has emerged as the largest global programme focused on livelihood augmentation of poor women, and has demonstrated robust processes, programmes leading to socio economic empowerment of women in Rural India. A great deal of headway has been made in financial literacy, access to bank accounts, credit, insurance etc. For livelihood augmentation, multitude of interventions across farm and non-farm sectors through Mahila Kisan Sashaktikaran Pariyojana (MKSP), National Rural Economic Transformation Project (NRETP), Value Chain Development Projects, Livestock Cluster Promotion, Non Timber Forest Produce (NTFP) Interventions, Integrated Farming Cluster (IFC), Organic/ Natural Farming, Start-up Village Entrepreneurship Programme (SVEP), Aajeevika Grameen Express Yojana (AGEY), Micro Enterprise Development, One Stop Facility (OSF) among others have been taken up. Besides, Convergence with schemes of the Ministry of Agriculture and farmers Welfare (MoA&FW), Ministry of Food Processing and Industries (MoFPI), Department of Animal Husbandry (DAHD), Department of Fisheries and other Ministries/ departments have been established.
              </p>
              <div *ngIf="showExtraContent">
                <p class="wel-para2 ms-2">
                  The combined efforts of the Central and State Governments in implementation of livelihood enhancement interventions has resulted in better economic outcomes at household level. The SHGs and their federations have proven their mettle in bringing socio economic improvement in lives of Rural Women. The Government of India is Committed to focus on Economic Empowerment of Women by Making Two Crore SHG Didis as Lakhpati Didis.
                </p>
                <p style="margin-left: 10px;"><span style="font-weight: 900;">Goal:-</span>Enabling Women SHG members for sustainable income of at least Rupees One Lakh per annum per Household.</p>
                <p style="margin-left: 10px;"><span style="font-weight: 900; ">Approach:-</span>The Ministry of Rural Development has adopted “Whole of Government Approach” to facilitate livelihoods expansion in the rural areas of the country</p>
                <p style="margin-left: 10px;"><span style="font-weight: 900; ">Strategy:-</span>To accelerate in the endeavour of Lakhpati Didi, following broad strategy is adopted by the Ministry:</p>
                <ol>
                  <li><span style="font-weight: 900;">Deepening, Strengthening and Expanding Livelihood interventions :- </span>Comprehensive Livelihood Planning of each SHG household using “Village Prosperity and Resilience Planning” with identification of regional specific livelihood and employment opportunities. Each Household is encouraged to take up 2-3 interventions of farm, non-farm livelihood coupled with Value Chain activities.</li>
                  <li><span style="font-weight: 900;">Adequate and timely support :- </span>in the form of- Assets, Skills, Finance and Market linkages through producer collectives.</li>
                  <li><span style="font-weight: 900;">Whole of Government Approach :- </span>to strengthen convergence within Government schemes and partnership with private sector, academia and civil society.</li>
                  <li><span style="font-weight: 900;">Training and Capacity Building :- </span>Structured capacity building and exposure of Mission Staff, line department officials, Community Institutions, Community Cadre.</li>
                </ol>
                <p style="margin-left: 10px;">A <span style="font-weight: 900; font-style: italic;">detailed Strategy</span> has been developed highlighting the key focus areas for planning, implementation and monitoring of the initiative. The initiative will empower women both in socio economic parameters and elevate their livelihoods, paving the way for a more prosperous rural India. <a href="javascript:void(0);" id="readLessBtn" style="display: none;" (click)="toggleReadLess()">Read Less</a></p>
              </div>  
              <!-- <p class="wel-para2 ms-2 mt-4">
                  Aided in part through investment support by the World Bank, the Mission aims at creating
                  efficient and effective institutional platforms of the rural poor, enabling them to
                  increase household income through sustainable livelihoods enhancements and improved
                  access to financial services.
                </p> -->
              </div>
              <!-- <img src="http://lorempixel.com/300/200" alt="Avatar" class="image">  -->
              <div class="infoText">
               <span class="vl-custom"></span>

                <h2 class="ms-2 titleHead">
                  
                  &nbsp;Welcome to
                  <br>
                  &nbsp;Lakhpati Initiatives
                </h2>
              </div>
            </div>
            
            <!-- <h4 class="wel-title">
              <span class="vl"></span>
              Welcome to Deendayal Antyodaya Yojana - National Rural Livelihoods Mission
            </h4> -->
            
            <div class="btn btn-readmoreWelcome" (click)="readMoreContent()" *ngIf="!showExtraContent">
              Read More<span class="ms-2">&#62;</span>
            </div>
            <div class="btn btn-readmoreWelcome" (click)="readLessContent()" *ngIf="showExtraContent">
              Read Less<span class="ms-2">&#62;</span>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-lg-3 my-2" style="margin-top: 90px;">
            <img src="./assets/images/lakhpati_initiative.png" class="img-fluid" style="height: 300px !important; margin-top: 78px !important;" alt="womens">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Old Content -->
<!-- <div class="container">
  <div class="row">
      <div class="col-sm-9">
          <div>
              <div>
                <div class="card border-0 rounded-2 shadow mb-3">
                  <div class="card-body">
                    <span style="font-weight: 900; font-size: 25px;">Action Taken : </span>
                    <br />
                    <p>A <span style="font-weight: 900;">National Conclave</span> was organized under the chairmanship of Hon’ble Minister, Rural Development and Panchayati Raj. Hon’ble Minister of State for Rural Development, SHG Didis, Community Resource Persons, State Governments, Central Ministries, Civil Society Organizations, Multilateral Agencies and Technical Support Agencies participated. The objective was for deepening, strengthening and expanding livelihood interventions for “Lakhpati Didis”. <a href="javascript:void(0);" id="readMoreActionBtn" style="display: block;" (click)="toggleReadMoreAction()">Read More</a> </p>
                    <div id="readMoreActionContent" style="display: none;">
                      <ul>
                        <li>Strategy on Lakhpati Didis detailing livelihood augmentation processes, focused interventions etc. released. </li>
                        <li>The planning process has been improved through the use of the District Livelihood Potential Mapping (DLPM) and the Village Prosperity and Resilience Plan (VPRP) for structured Livelihoods planning.</li>
                        <li><span style="font-weight: 900;">Steering Committee </span>under the chairmanship of Secretary Rural development at the National Level constituted for strengthening convergence and monitoring.</li>
                        <li>Target for 75 Lakhs HHs to become Lakhpati in the FY 2023-24 shared. For subsequent financial years, it will be firmed up in consultation with the States/ UTs.</li>
                        <li><span style="font-weight: 900;">Convergence and Partnerships</span> with Ministries/ organization/ CSOs established for livelihood enhancement and promotion. </li>
                        <li>Dashboard for monitoring being developed.</li>
                        <li>Lakhpati Didi Mobile app developed for self reporting of income by SHg women.</li>
                        <li>Training and capacity building on Livelihood Planning initiated. </li>
                        <li>Memoranda of Understanding (MoUs) were signed with the Ministry of Agriculture & Farmers’ Welfare (MoA&FW), Department of Animal Husbandry and Dairying (DAHD), the Ministry of Food Processing Industries (MoFPI), the Department of Fisheries, the Ministry of Small & Medium Enterprises (MSME), NABARD, NAFED, ICAR, and various Civil Society Organizations (CSOs)</li>
                        <li><span style="font-weight: 900;"> Materials, Advisories and Technical Documents prepared:</span>
                           <ol>
                              <li>Handbook on Universalization of Livelihoods among SHG Women Households;</li>
                              <li>Manual for Livelihood Augmentation of SHG Households, a Step-by-Step guide</li>
                              <li>Strategy for 2 Crore Lakhpati</li>
                              <li>Advisory on Lakhpati Initiative, Resource District Strategy issued.</li>
                              <li>Training Modules for District Livelihood Potential Mapping</li>
                              <li>Training modules for CLF, VO and CRPs on CLF anchored Livelihood processes and VPRP planning.</li>
                              <li>Guidance note and Technical bulletin for promotion of Sub-Sector intervention. </li>
                              <li>Livelihood Prototypes developed</li>
                           </ol>
                        </li>
                      </ul>
                      <span style="font-weight: 900; margin-left: 18px;">Convergence and Partnerships initiated:-</span>
                      <table style="width: 96%; padding-left: 2px; margin-left: 20px;">
                        <thead>
                            <tr>
                                <th style="font-weight: 700;">Ministry</th>
                                <th style="font-weight: 700;">Schemes for Convergence</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Ministry of Rural Development, MoRD</td>
                                <td>
                                  <ol>
                                    <li>Mahatma Gandhi National Rural Employment Guarantee Scheme  (MGNREGS)</li>
                                    <li>Deendayal Upadhyay Grameen Kaushal Yojana (DDUGKY)</li>
                                    <li>Rural Self Employment Training Institutes (RSETIs)</li>
                                    <li>Pradhan Mantri Awas Yojana -Grameen (PMAY-G)</li>
                                  </ol>
                                </td>
                            </tr>
                            <tr>
                                <td>Ministry of Agriculture & Farmers’ Welfare (MoA&FW)</td>
                                <td>Mission for Integrated Development of Horticulture (MIDH), Sub Mission on Agriculture Mechanization (SMAM), National Bamboo Mission, National Mission on Honey and Bee Keeping, Promotion of Millets, Pradhan Mantri Krishi Sinchayi Yojana (PMKSY), 10K FPO, Agri Infra Fund (AIF), Natural Farming and Engagement of Community Resource Persons (CRPs) as extension agents for service delivery etc.</td>
                            </tr>
                            <tr>
                                <td>Ministry of Food Processing Industries (MoFPI)</td>
                                <td>Pradhan Mantri Formalization of Micro Food Processing Enterprises Scheme (PMFME)</td>
                            </tr>
                            <tr>
                                <td>Dept. of Fisheries</td>
                                <td>PM Matsya Sampada Yojana</td>
                            </tr>
                            <tr>
                                <td>Ministry of Small & Medium Enterprises (MSME)</td>
                                <td>Scheme of Fund for regeneration of Traditional Industries (SFURTI)</td>
                            </tr>
                            <tr>
                              <td>Expert Institutions</td>
                              <td>National Bank for Agriculture and Rural Development (NABARD), National Agricultural Cooperative Marketing Federation of India Ltd (NAFED), Indian Council for Agriculture Research (ICAR), Commodity Boards, Multi-Lateral Institutions.</td>
                            </tr>
                            <tr>
                              <td>Pvt Sector Partnership</td>
                              <td>Civil Society Organizations (CSOs), Industry, Foundation for Development of Rural Value Chains (FDRVC)</td>
                            </tr>
                        </tbody>
                      </table>
                      <a href="javascript:void(0);" id="readLessActionBtn" style="display: none; margin-left: 18px; margin-top: 10px;" (click)="toggleReadLessActionButton()">Read Less</a>
                    </div>
                  </div>
                </div>
                  <br />
                <div class="card border-0 rounded-2 shadow mb-3">
                  <div class="card-body">
                    <p style="font-weight: 900; font-size: 25px; margin: 0;">Progress : </p>
                    <div class="progress-button-css">
                      <a href="https://lookerstudio.google.com/u/0/reporting/c6e6022d-9eb7-4aeb-83c3-ed08947f6c82/page/p_4sc9vpd29c" target="_blank">Go to Progress Report</a>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
  <app-common-document-upload [moduleId]="31" [subModuleId]="44"></app-common-document-upload>

</div> -->

<!-- New Content -->
<!-- Highlights and What's New?? Section... -->
<div class="whatsnewBg">
  <div class="container">
   <div class="">
     <div class="row">
       <div class="col-lg-8 mb-3">
         <div class="row">
           <div class="col-md-12 col-sm-12 col-lg-12">
             <h3 class="p-3 m-0">
               Action's Taken
             </h3>
             <div class="px-3 mb-3">
              <button class="btn me-3 m-2" [ngClass]="(selectedTab == 'actionTakenPoints') ? 'btn-active' : 'btn-pending'"
                 (click)="changeTab('actionTakenPoints')">Action Taken's</button>
              <button class="btn me-3 m-2" [ngClass]="(selectedTab == 'materials') ? 'btn-active' : 'btn-pending'"
                 (click)="changeTab('materials')">Resources Materials, Advisories and Technical Documents prepared</button>
              <button class="btn me-3 m-2" [ngClass]="(selectedTab == 'convergence') ? 'btn-active' : 'btn-pending'"
                 (click)="changeTab('convergence')">Convergence and Partnership initiated:</button>
             </div>
           </div>
         </div>
         <div class="bg-white shadow rounded-3 gradientBottom">
           
           <div class="hl-contentsContainer px-3">
             <!-- About Ac -->
            <ng-container *ngIf="selectedTab == 'actionTakenPoints'">
              <ul>
                <li>Strategy on Lakhpati Didis detailing livelihood augmentation processes, focused interventions etc. released. </li>
                <li>The planning process has been improved through the use of the District Livelihood Potential Mapping (DLPM) and the Village Prosperity and Resilience Plan (VPRP) for structured Livelihoods planning.</li>
                <li><span style="font-weight: 900;">Steering Committee </span>under the chairmanship of Secretary Rural development at the National Level constituted for strengthening convergence and monitoring.</li>
                <li>Target for 75 Lakhs HHs to become Lakhpati in the FY 2023-24 shared. For subsequent financial years, it will be firmed up in consultation with the States/ UTs.</li>
                <li><span style="font-weight: 900;">Convergence and Partnerships</span> with Ministries/ organization/ CSOs established for livelihood enhancement and promotion. </li>
                <li>Dashboard for monitoring being developed.</li>
                <li>Lakhpati Didi Mobile app developed for self reporting of income by SHg women.</li>
                <li>Training and capacity building on Livelihood Planning initiated. </li>
                <li>Memoranda of Understanding (MoUs) were signed with the Ministry of Agriculture & Farmers’ Welfare (MoA&FW), Department of Animal Husbandry and Dairying (DAHD), the Ministry of Food Processing Industries (MoFPI), the Department of Fisheries, the Ministry of Small & Medium Enterprises (MSME), NABARD, NAFED, ICAR, and various Civil Society Organizations (CSOs)</li>
              </ul>             
            </ng-container>
            <!-- Materials -->
            <ng-container *ngIf="selectedTab == 'materials'">
              <ol>
                <li>Handbook on Universalization of Livelihoods among SHG Women Households;</li>
                <li>Manual for Livelihood Augmentation of SHG Households, a Step-by-Step guide</li>
                <li>Strategy for 2 Crore Lakhpati</li>
                <li>Advisory on Lakhpati Initiative, Resource District Strategy issued.</li>
                <li>Training Modules for District Livelihood Potential Mapping</li>
                <li>Training modules for CLF, VO and CRPs on CLF anchored Livelihood processes and VPRP planning.</li>
                <li>Guidance note and Technical bulletin for promotion of Sub-Sector intervention. </li>
                <li>Livelihood Prototypes developed</li>
             </ol>
            </ng-container>
            <!-- Convergence and partnership initiated -->
            <ng-container *ngIf="selectedTab == 'convergence'">
              <table style="width: 96%; padding-left: 2px; margin-left: 20px;">
                <thead>
                    <tr>
                        <th style="font-weight: 700;">Ministry</th>
                        <th style="font-weight: 700;">Schemes for Convergence</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Ministry of Rural Development, MoRD</td>
                        <td>
                          <ol>
                            <li>Mahatma Gandhi National Rural Employment Guarantee Scheme  (MGNREGS)</li>
                            <li>Deendayal Upadhyay Grameen Kaushal Yojana (DDUGKY)</li>
                            <li>Rural Self Employment Training Institutes (RSETIs)</li>
                            <li>Pradhan Mantri Awas Yojana -Grameen (PMAY-G)</li>
                          </ol>
                        </td>
                    </tr>
                    <tr>
                        <td>Ministry of Agriculture & Farmers’ Welfare (MoA&FW)</td>
                        <td>Mission for Integrated Development of Horticulture (MIDH), Sub Mission on Agriculture Mechanization (SMAM), National Bamboo Mission, National Mission on Honey and Bee Keeping, Promotion of Millets, Pradhan Mantri Krishi Sinchayi Yojana (PMKSY), 10K FPO, Agri Infra Fund (AIF), Natural Farming and Engagement of Community Resource Persons (CRPs) as extension agents for service delivery etc.</td>
                    </tr>
                    <tr>
                        <td>Ministry of Food Processing Industries (MoFPI)</td>
                        <td>Pradhan Mantri Formalization of Micro Food Processing Enterprises Scheme (PMFME)</td>
                    </tr>
                    <tr>
                        <td>Dept. of Fisheries</td>
                        <td>PM Matsya Sampada Yojana</td>
                    </tr>
                    <tr>
                        <td>Ministry of Small & Medium Enterprises (MSME)</td>
                        <td>Scheme of Fund for regeneration of Traditional Industries (SFURTI)</td>
                    </tr>
                    <tr>
                      <td>Expert Institutions</td>
                      <td>National Bank for Agriculture and Rural Development (NABARD), National Agricultural Cooperative Marketing Federation of India Ltd (NAFED), Indian Council for Agriculture Research (ICAR), Commodity Boards, Multi-Lateral Institutions.</td>
                    </tr>
                    <tr>
                      <td>Pvt Sector Partnership</td>
                      <td>Civil Society Organizations (CSOs), Industry, Foundation for Development of Rural Value Chains (FDRVC)</td>
                    </tr>
                </tbody>
              </table>
            </ng-container>
             <!-- Letters and Circulars Tab Content -->
             <!-- Tenders Tab Content -->
             <!-- Events Tab Content -->
             <ng-template #noContent>Coming soon</ng-template>
           </div>
           <!-- <div class="border-top-0 text-center">
             <button class="btn btn-viewmore" (click)="navigatiToPage(selectedTab)" [disabled]="!selectedTabData">View
               All<span class="ms-2">&#62;</span></button>
           </div> -->
         </div>
       </div>
       <div class="col-lg-4 mb-3">
         <div class="row">
           <div class="col-md-12 col-lg-12 col-sm-12 mb-3">
             <div class="bg-white shadow rounded-3 marginNew">
               <div class="card-title m-0 highlights">
                 <h3 class="p-3 m-0">
                   Highlights
                 </h3>
               </div>
               
             </div>
           </div>
         </div>
         
         <div class="row">
           <div class="col-md-12 col-lg-12 col-sm-12 mb-3">
             <div class="bg-white shadow rounded-3 mt-4 highlightBorder">
               <div class="card-body highlights-card-height py-2" style="overflow-y: auto;max-height: 40px; margin-top: 70px;">
                 <ng-template #noHighlights>
                   <div class="row mb-4 mt-4">
                     <div style="text-align: center;margin-top: 50px;">
                       <h6>
                         No Highlights Available
                       </h6>
                     </div>
     
                   </div>
                 </ng-template>
               </div>
               <!-- <div class="card-footer border-top-0 bg-white customHl" [routerLink]="['/highlights']">
                 <button class="btn btn-readmoreHighlights">Read More<span class="ms-2">&#62;</span></button>
               </div> -->
             </div>
           </div>
         </div>
         
       </div>
     </div>
   </div>
  </div>
</div>

<!-- Document Uploaded -->
<div class="whatsnewBg">
  <div class="container">
   <div class="">
    <div class="row">
    <div class="col-lg-8 mb-3">
      <app-common-document-upload [moduleId]="31" [subModuleId]="44"></app-common-document-upload>
    </div>
    <div class="col-lg-4 mb-3">
      <div class="col-md-12 col-lg-12 col-sm-12 mb-3">
        <div class="bg-white shadow rounded-3 mt-1">
          <div class="card-title m-0 highlights">
            <h3 class="p-3 m-0">
              Success Stories
            </h3>
          </div>
        </div>
      </div>
      <div class="shadow" style="height: 175px;
      background-color: white;
      border-radius: 6px;">

      </div>
    </div>
    </div>
   </div>
  </div>
</div>