import { newArray } from '@angular/compiler/src/util';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit {

  filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });
  careers:any;
  page=1;
  totalCount =0;
  lastDate:any=[];
  lDate:any=[];
  splitedArray:any=[];
  obj:any={}
  modifiedArray:any=[]
  resultProductData:any=[]
  constructor(private contentService:ContentService,private dialog:MatDialog,private router:Router) {
    
    
   }

  ngOnInit(): void {
    this.getCareers()
  }
  getCareers(){
    this.contentService.getContent("list", `moduleId=12&page=${this.page}`).subscribe((res:any)=>{
      if(res){
        console.log('career response-->', res);
        this.careers = res && res['wrapperList'].length && res['wrapperList'];      
        this.totalCount = res.totalcount;
        // this.careers[2].title = 'Job advertisements for 22 posts of Tripura Rural Livelihood Mission (TRLM). Uploaded on: 13 April,2022'
       
        this.careers.forEach((ele:any)=>{
          console.log(ele);
          
        this.lDate = ele.title.split('.')
        this.splitedArray.push(this.lDate[0])
        this.lastDate.push(this.lDate[1]);
        for(let i=0;i<this.splitedArray.length;i++){
          ele.textDesc = this.splitedArray[i]
        }
        })
        this.modifiedArray = this.careers
         for(let i =0; i< this.lastDate.length;i++){
            if(this.lastDate[i]){
            let str = this.lastDate[i].split(':')
            this.lastDate[i] = str[1]
            }
          }
          console.log(this.modifiedArray);
          this.resultProductData = this.modifiedArray
          console.log('this.resultProductData', this.resultProductData);
          
      }
    },error=>{
      console.log(error)
    })
  }
  

  applyFilter() {
    this.resultProductData = this.modifiedArray.filter(
      (ele:any)=>
      {
          return new Date(ele.vacancyUploadedDate) > this.filterForm.get('fromDate')?.value && new Date(ele.vacancyUploadedDate) < this.filterForm.get('toDate')?.value
      });
  }
  
  getLink(fileList:any){
    let link = fileList[0]? fileList[0].fileUrl : ''
    return link;
  }
  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
  pageEvent(event:any){
    this.page = event;
    this.getCareers();
  }

}
