import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { InnovativeComponent } from './innovative/innovative.component';
import { MkspComponent } from './mksp/mksp.component';
import { NretpComponent } from './nretp/nretp.component';
import { NrlmComponent } from './nrlm/nrlm.component';
import { FlComponentRoutingModule } from './fl-components.routing.module';


@NgModule({
    declarations: [
        InnovativeComponent,
        MkspComponent,
        NretpComponent,
        NrlmComponent
    ],
    imports: [
        FlComponentRoutingModule,
        CommonModule,
        SharedModule
    ],
})

export class FlComponentsModule { }