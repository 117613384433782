import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentService } from 'src/app/shared/services/content.service';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';

@Component({
  selector: 'app-organic-farming',
  templateUrl: './organic-farming.component.html',
  styleUrls: ['./organic-farming.component.scss']
})
export class OrganicFarmingComponent implements OnInit {

  constructor(private contentService: ContentService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getSubModuleData(this.subModuleId)
  }

  filterConfig = {
    globalSearch:true,
    search:true,
    author:false,
    source:false,
    from:true,
    to:true,
    fileType:false
  }

  moduleId:Number=31;
  subModuleId:Number = 3;

  params:any;
  page = 1;
  currentSubModuleId: any;
  totalCount: any;
  dataList: any;

  searchParams(event: any){
    let searchParams  = new URLSearchParams(event).toString();
    this.params = searchParams;
    this.page = 1;
    this.getSubModuleData(this.subModuleId)
  }

  getSubModuleData(id:any){
    // if(this.currentSubModuleId!=id){
    //   this.page=1;
    // }
    this.currentSubModuleId = id;
    this.contentService.getContent("list", `moduleId=${this.moduleId}&subModuleId=${id}&page=${this.page}&${this.params}`,).subscribe((res:any)=>{
      if(res){
        this.totalCount = res.totalcount;
        this.dataList = res && res['wrapperList']
      }
    },error=>{
      console.log(error)
    })
  }

  getTotalDownload(fileList:any){
    let total = 0;
    for (var i in fileList) {
      total += fileList[i].downloadCount;
    }
    return total

  }

  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
