<div class="container">
  <div class="row my-3">
      <div class="col px-0">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <a href="/multimedia" class="nav-link ">Multimedia</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Video Gallery</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-sm-8 mb-3 bg-white border-0 rounded-2 shadow mb-3 p-3">
         <div class="row">
           <h5>Video Gallery</h5>
         </div>
         <div class="row">
           <div class="col">
            <!-- <div class="row mx-0">
              <app-common-filters
                [filterConfig]="filterConfig"
                [moduleId]="moduleId"
                (searchParams)="searchParams($event)"
              ></app-common-filters>
            </div> -->
           </div>
         </div>
         <div class="d-flex justify-content-between align-items-center">
          <p class="m-2" style="padding: 10px;">Showing {{totalItem}} Results</p>
          <app-paginator [totalCount]="totalItem" [page]="page" (nextPage)="pageEvent($event)" ></app-paginator>
         </div>

        <div class="cardStyle mb-4 mt-4 row">
            <div class="col-sm-4 mb-3 position-relative shadow" style="width: 45%; margin: 15px; border: 1px solid gray;" *ngFor="let item of videoGallery">
                <video width="100%" height="240" controls style="padding: 2px;">
                    <source [src]="item.fileList[0].fileUrl" [type]="item.fileList[0].fileType">
                    Your browser does not support the video tag.
                </video> 
              <div class="card-body">
                <p class="text-center">{{item.title}}</p>
                <!-- <a style="cursor: pointer;" [href]="item.link" target="_blank"  >{{item.title}}</a> -->
              </div>
            </div>
          <!-- </div> -->
        </div>
      </div>
  </div>
</div>