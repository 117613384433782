<div class="container">
    <div class="row py-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/about" class="nav-link ">Themes</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Livestock</div>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Pig Rearing</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p>Backyard piggery has been an age-old practice among the communities in north-eastern India and socio-economically weaker sections of the country. It has great potential to contribute to a faster economic return to the rearers due to its high fecundity, better-feed conversion efficiency, early maturity and short generation interval. Moreover, a pig can convert so-called wastes into high-quality edible protein in its body. Backyard pig rearing with a small herd size contributes in many ways to improving the livelihood of the poor. Pork and other pig products provide high-value animal protein. The meat is easy to dress and has superior curing and storage qualities. The low costs and small investments are recovered relatively quickly as slaughter can take place at about six to eight months from farrowing (birth), depending on breed and feed availability. Pig husbandry can be easily integrated with other farming activities (agriculture, fishery) within the agricultural and aquaculture sectors </p>                 
                </div>
            </div>
        </div>
    </div>
    <app-common-document-upload [moduleId]="31" [subModuleId]="8"></app-common-document-upload>
</div>