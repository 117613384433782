import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = require("sweetalert");


@Component({
  selector: 'app-kms-core-add',
  templateUrl: './kms-core-add.component.html',
  styleUrls: ['./kms-core-add.component.scss']
})
export class KmsCoreAddComponent implements OnInit {
  @Output() previousPath: any = new EventEmitter();
  excludeArray = ['id', 'keyword', 'createdAt', 'updatedAt', 'deletedAt', 'createdBy', 'updatedBy', 'deletedBy', 'isDeleted','fileType','category']
//   masterData:any=[ {

//     "id": 1,

//     "fileName": "testing",

//     "fileType": "ppt",

//     "file": "samplepptx_(1)_1654418755040.pptx",

//     "crossStatePublication": "prashant",

//     "language": "eng",

//     "category": 232323,

//     "source": "uuuu",

//     "approverName": "prashant",

//     "approverDesignation": "testing",

//     "keyword": "usjs",

//     "createdAt": "2022-06-05T08:32:41.958+00:00",

//     "updatedAt": "2022-06-05T08:45:55.042+00:00",

//     "deletedAt": null,

//     "createdBy": 5,

//     "updatedBy": 5,

//     "deletedBy": null,

//     "isDeleted": 1,

//     "knowledgeMaterialVertical": "uuu"

// }];
masterData:any=[];

@Input() kmsId=''
content:any=[];
currentModuleId=''
currentSubModuleId='';
item:any= {};
filteredHeader:any;
  constructor(private router:Router,
    private contentService: ContentService,
    private dialog:MatDialog) {

     }
  ngOnInit(): void {
    this.getKmsContentByid();
    this.getContent();
    console.log(this.kmsId)
  }
  getKmsContentByid(){
    this.contentService.getKmsContent('list',`kmsId=${this.kmsId}`).subscribe((res:any)=>{
      this.masterData = res.wrapperList;
      console.log('this.masterData', this.masterData);
      
      let obj:any ={}
      
      let receivedObj = this.masterData.filter((ele:any)=>{
         return ele.id == this.kmsId
      })
      for(let key in receivedObj[0]){
        if(!this.excludeArray.includes(key) && receivedObj[0][key]){
         let upperCasedKey = this.checkDataTypes(key);
         if(key == 'file'){
          obj[upperCasedKey] = [{
            "id": (Math.random()*10).toFixed(),
            "contentId": (Math.random()*10).toFixed(),
            "fileUrl": receivedObj[0][key],
            "fileSize": (Math.random()*10).toFixed(),
            "fileType": receivedObj[0][key].split('.').pop(),
            "downloadCount": 0,
            "viewCount": 0,
        }]
         }else{

          obj[upperCasedKey] = receivedObj[0][key];
         }
        }
      }
      console.log('receivedObj', receivedObj);
      this.item = obj;
      console.log('this.item', this.item);
      delete obj.KmSubTypeId
      delete obj.ModuleId
      delete obj.SearchTag
      delete obj.IsSynced
      this.filteredHeader = obj;
      console.log(this.filteredHeader);
      
      // this.item.hasOwnProperty('State') == true ? this.item['State'] = this.item.State : this.item['State'] = 'N/A'
    })
  }
  getContent(){
    this.contentService.getAdminContent('list',`kmsId=${this.kmsId}`).subscribe((res:any)=>{
      this.rowData  = res.wrapperList.length? res.wrapperList[0]: '';
      // this.onSelectionChange({target:{value:this.rowData.moduleId}})
        if(!this.rowData.subModuleId){
          let moduleId = this.rowData['moduleId'];
          this.rowData['moduleId'] = 'misc';
          this.rowData['subModuleId'] = moduleId;
        }
      this.subModules = this.catageoryOption.find((item:any)=>item.id==this.rowData['moduleId']).options;
      this.rowData &&  this.rowData.kmsId ? this.setDynamicForm(true):this.setDynamicForm(false)
    })
  }
  setDynamicForm(isKms=false){
    let obj:any = {title:'fileName', fileType:'knowledgeMaterialVertical',language:'language',source:'source',file_url:'file'};
    //   for(let key in obj){
    //     this.rowData = this.rowData.value[obj[key]];
    //   // }
    // }
    console.log("rowDyanamicForm", this.dynamicForm, obj, this.rowData)
  if(!isKms){
    this.dynamicForm = this.catageoryOption[0].options[0].dynamicForm;
    return
  }
    this.isEntry=true;
    this.isViewMode = true;
    let customModuleId = this.rowData['moduleId'] == 'misc' ? this.rowData.subModuleId : this.rowData.moduleId;
    // this.onSubModuleChange({target:{value:this.rowData.subModuleId}})
    this.selectedModuleOptions['subModuleId'] = this.rowData.subModuleId

    let option:any = this.rowData.moduleId && this.rowData.subModuleId ? this.catageoryOption.find((item:any)=>item.id==this.rowData.moduleId).options:''
    console.log("custom",customModuleId, this.catageoryOption,this.rowData,option)
    this.dynamicForm = this.rowData['moduleId'] == 'misc' ? option.find((item:any)=>item.moduleId == this.rowData.subModuleId).dynamicForm : option.find((item:any)=>item.subModuleId == this.rowData.subModuleId).dynamicForm;
  }
  isKms=true;
  goBack() {
    this.previousPath.emit();
  }
  tableHeader: any = [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'publishedDate',
    label: 'Published Date'
  },
  {
    key: 'publishedBy',
    label: 'Published By'
  },
  {
    key: 'author',
    label: 'Author'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'source',
    label: 'Document Source'
  }
];
docExtensions = [
  'doc',
  'docm',
  'docx',
  'pdf',
  'txt',
  'csv',
  'xls',
  'xlsb',
  'xlsx',
  'pptm',
  'pptx',
  'application/pdf',
  'application/vnd.ms-excel'
];
imgExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/raw', 'image/image'];
videoExtensions = [
  'mp4',
  'mov',
  'wmv',
  'avi',
  'avchd',
  'flv',
  'f4v',
  'swf',
  'mkv',
  'webm',
  // 'html5',
  'mpeg-2',
];
subModules=[{
  value: 'core-document',
  label: 'NRLM Core Document',
  moduleId: '1',
  subModuleId: '1',
  dynamicForm: {
    title: [''],
    publishedDate: [''],
    publishedBy: [''],
    author: [''],
    fileList: [''],
    source: [''],
    keyword:['']
  },
  tableHeader: [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'publishedDate',
    label: 'Published Date'
  },
  {
    key: 'publishedBy',
    label: 'Published By'
  },
  {
    key: 'author',
    label: 'Author'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'source',
    label: 'Source'
  }
]
},{
  value: 'national-report-plans',
  label: 'National Reports & Plans',
  moduleId: '1',
  subModuleId: '2',
  dynamicForm: {
    title: [''],
    fileList: [''],
    keyword: ['']
  },
  tableHeader: [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  }
]
},
{
  value: 'guidelines',
  label: 'Guidelines',
  moduleId: '1',
  subModuleId: '3',
  dynamicForm: {
    title: [''],
    fileList: [''],
    keyword: ['']
  },
  tableHeader: [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  }
]
},
{
  value: 'intensive-coverage',
  label: 'Intensive Coverage',
  moduleId: '1',
  subModuleId: '4',
  dynamicForm: {
    title: [''],
    fileList: [''],
    keyword: ['']
  },
  tableHeader: [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  }
]
},
{
  value: 'monthly-status-resblock',
  label: 'Monthly Status of Resource Blocks',
  moduleId: '1',
  subModuleId: '5',
  dynamicForm: {
    title: [''],
    fileList: [''],
    keyword: ['']
  },
  tableHeader: [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  }
]
},
{
  value: 'crp-calender',
  label: 'CRP Calendar',
  moduleId: '1',
  subModuleId: '6',
  dynamicForm: {
    title: [''],
    publishedDate: [''],
    fileList: [''],
    keyword: ['']
  },
  tableHeader: [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'publishedDate',
    label: 'Uploaded On'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  }
]
},
{
  value: 'fund-release',
  label: 'Fund Release',
  moduleId: '1',
  subModuleId: '7',
  dynamicForm: {
    title: [''],
    fileList: [''],
    keyword: ['']
  },
  tableHeader: [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  }
]
},
{
  value: 'ec-minutes',
  label: 'Approved Ec Minutes',
  moduleId: '1',
  subModuleId: '8',
  dynamicForm: {
    title: [''],
    fileList: [''],
    keyword: ['']
  },
  tableHeader: [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  }
]
}]
  page=1;
  entryTitle: string = 'NRLM Document';
  selectedModule:any = [{
    value: 'core-document',
    label: 'NRLM Core Document',
    moduleId: '1',
    subModuleId: '1',
    dynamicForm: {
      title: [''],
      publishedDate: [''],
      publishedBy: [''],
      author: [''],
      fileList: [''],
      source: [''],
      keyword:['']
    },
    tableHeader: [{
      key: 'title',
      label: 'Title'
    },
    {
      key: 'publishedDate',
      label: 'Published Date'
    },
    {
      key: 'publishedBy',
      label: 'Published By'
    },
    {
      key: 'author',
      label: 'Author'
    },
    {
      key: 'keyword',
      label: 'Keyword'
    },
    {
      key: 'fileList',
      label: 'File'
    },
    {
      key: 'source',
      label: 'Source'
    }
  ]
},{
    value: 'national-report-plans',
    label: 'National Reports & Plans',
    moduleId: '1',
    subModuleId: '2',
    dynamicForm: {
      title: [''],
      fileList: [''],
      keyword: ['']
    },
    tableHeader: [{
      key: 'title',
      label: 'Title'
    },
    {
      key: 'fileList',
      label: 'File'
    },
    {
      key: 'keyword',
      label: 'Keyword'
    }
  ]
},
{
    value: 'guidelines',
    label: 'Guidelines',
    moduleId: '1',
    subModuleId: '3',
    dynamicForm: {
      title: [''],
      fileList: [''],
      keyword: ['']
    },
    tableHeader: [{
      key: 'title',
      label: 'Title'
    },
    {
      key: 'fileList',
      label: 'File'
    },
    {
      key: 'keyword',
      label: 'Keyword'
    }
  ]
},
{
    value: 'intensive-coverage',
    label: 'Intensive Coverage',
    moduleId: '1',
    subModuleId: '4',
    dynamicForm: {
      title: [''],
      fileList: [''],
      keyword: ['']
    },
    tableHeader: [{
      key: 'title',
      label: 'Title'
    },
    {
      key: 'fileList',
      label: 'File'
    },
    {
      key: 'keyword',
      label: 'Keyword'
    }
  ]
},
{
    value: 'monthly-status-resblock',
    label: 'Monthly Status of Resource Blocks',
    moduleId: '1',
    subModuleId: '5',
    dynamicForm: {
      title: [''],
      fileList: [''],
      keyword: ['']
    },
    tableHeader: [{
      key: 'title',
      label: 'Title'
    },
    {
      key: 'fileList',
      label: 'File'
    },
    {
      key: 'keyword',
      label: 'Keyword'
    }
  ]
},
{
    value: 'crp-calender',
    label: 'CRP Calendar',
    moduleId: '1',
    subModuleId: '6',
    dynamicForm: {
      title: [''],
      publishedDate: [''],
      fileList: [''],
      keyword: ['']
    },
    tableHeader: [{
      key: 'title',
      label: 'Title'
    },
    {
      key: 'publishedDate',
      label: 'Uploaded On'
    },
    {
      key: 'fileList',
      label: 'File'
    },
    {
      key: 'keyword',
      label: 'Keyword'
    }
  ]
},
{
    value: 'fund-release',
    label: 'Fund Release',
    moduleId: '1',
    subModuleId: '7',
    dynamicForm: {
      title: [''],
      fileList: [''],
      keyword: ['']
    },
    tableHeader: [{
      key: 'title',
      label: 'Title'
    },
    {
      key: 'fileList',
      label: 'File'
    },
    {
      key: 'keyword',
      label: 'Keyword'
    }
  ]
},
{
    value: 'ec-minutes',
    label: 'Approved Ec Minutes',
    moduleId: '1',
    subModuleId: '8',
    dynamicForm: {
      title: [''],
      fileList: [''],
      keyword: ['']
    },
    tableHeader: [{
      key: 'title',
      label: 'Title'
    },
    {
      key: 'fileList',
      label: 'File'
    },,
    {
      key: 'keyword',
      label: 'Keyword'
    }
  ]
}]
  selectedModuleOptions: any = {moduleId: '1', subModuleId:'1'};
  rowData: any;
  isChecked: boolean= false;
  isViewMode: boolean = false;
  submitButton: string = 'Submit';
  dynamicForm: any = {
    title: [''],
    publishedDate: [''],
    publishedBy: [''],
    author: [''],
    fileList: [''],
    source: [''],
    keyword:['']
  };
  catageoryOption:any = [
{
    value: 'NRLM Document',
    label: 'NRLM Document',
    id:1,
    fileTypeAccept:this.docExtensions,
    options: [{
        value: 'core-document',
        label: 'NRLM Core Document',
        moduleId: '1',
        subModuleId: '1',
        dynamicForm: {
          title: [''],
          publishedDate: [''],
          publishedBy: [''],
          author: [''],
          fileList: [''],
          source: [''],
          keyword:['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'publishedDate',
          label: 'Published Date'
        },
        {
          key: 'publishedBy',
          label: 'Published By'
        },
        {
          key: 'author',
          label: 'Author'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'source',
          label: 'Source'
        }
      ]
    },{
        value: 'national-report-plans',
        label: 'National Reports & Plans',
        moduleId: '1',
        subModuleId: '2',
        dynamicForm: {
          title: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }

      ]
    },
    {
        value: 'guidelines',
        label: 'Guidelines',
        moduleId: '1',
        subModuleId: '3',
        dynamicForm: {
          title: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },
    {
        value: 'intensive-coverage',
        label: 'Intensive Coverage',
        moduleId: '1',
        subModuleId: '4',
        dynamicForm: {
          title: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },
    {
        value: 'monthly-status-resblock',
        label: 'Monthly Status of Resource Blocks',
        moduleId: '1',
        subModuleId: '5',
        dynamicForm: {
          title: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },
    {
        value: 'crp-calender',
        label: 'CRP Calendar',
        moduleId: '1',
        subModuleId: '6',
        dynamicForm: {
          title: [''],
          publishedDate: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'publishedDate',
          label: 'Uploaded On'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },
    {
        value: 'fund-release',
        label: 'Fund Release',
        moduleId: '1',
        subModuleId: '7',
        dynamicForm: {
          title: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },
    {
        value: 'ec-minutes',
        label: 'Approved Ec Minutes',
        moduleId: '1',
        subModuleId: '8',
        dynamicForm: {
          title: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    }],
},
{
    value: 'National Repository',
    label: 'National Repository',
    id:8,
    options: [{
        value: 'nrlmnationalrepo-document',
        label: 'Document',
        moduleId: '8',
        subModuleId: '10',
        dynamicForm: {
          title: [''],
          author: [''],
          fileList: [''],
          source: [''],
          publishedDate:[''],
          keyword:['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'author',
          label: 'Author'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'publishedDate',
          label: 'Published Date'
        },
      ]
    },{
        value: 'nrlmnationalrepo-videos',
        label: 'Videos',
        moduleId: '8',
        subModuleId: '11',
        dynamicForm: {
          title: [''],
          source: [''],
          link: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'link',
          label: 'Link'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    }]
},
{
  value: 'Resources',
  label: 'Resources',
  id:27,
  options: [
    {
      value: 'Case Study',
      label: 'Case Study',
      moduleId: '27',
      subModuleId:'31',
      dynamicForm: {
        title: [''],
        author: [''],
        fileList: [''],
        source: [''],
        publishedDate:[''],
        keyword:['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'soure',
        label: 'Author'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'source',
        label: 'Source'
      },
      {
        key: 'publishedDate',
        label: 'Published Date'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
    ]
    },
    {
      value: 'Manuals',
      label: 'Manuals',
      moduleId: '27',
      subModuleId:'32',
      dynamicForm: {
        title: [''],
        author: [''],
        fileList: [''],
        source: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'author',
        label: 'Author'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'source',
        label: 'Source'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
    ]
    },
    {
      value: 'Guidelines',
      label: 'Guidelines',
      moduleId: '27',
      subModuleId:'36',
      dynamicForm: {
        title: [''],
        fileList: [''],
        source: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'source',
        label: 'Source'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
    ]
    },
    {
      value: 'Press Release',
      label: 'Press Release',
      moduleId: '27',
      subModuleId:'33',
      dynamicForm: {
        title: [''],
        link: [''],
        source: [''],
        publishedDate:[''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'link',
        label: 'Link'
      },
      {
        key: 'source',
        label: 'Source'
      },
      {
        key: 'publishedDate',
        label: 'Date'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
    ]
    },
    {
      value: 'Publications',
      label: 'Publications',
      moduleId: '27',
      subModuleId:'34',
      dynamicForm: {
        title: [''],
        fileList: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }

    ]
    },
    {
      value: 'Annual Reports',
      label: 'Annual Reports',
      moduleId: '27',
      subModuleId:'35',
      dynamicForm: {
        title: [''],
        fileList: [''],
        publishedDate:[''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'publishedDate',
        label: 'Date'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
    ]
    },

  ]
},

{
    value: 'RTI ACT',
    label: 'RTI ACT',
    id:9,
    options: [
    {
        value: 'rti-received',
        label: 'RTI received and responses given',
        moduleId: '9',
        subModuleId: '12',
        dynamicForm: {
          title: [''],
          publishedDate: [''],
          fileList: [''],
          fileNumber: [''],
          rtiQuery: [''],
          description:[''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'publishedDate',
          label: 'Date'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'fileNumber',
          label: 'File Number'
        },{
          key: 'rtiQuery',
          label: 'RTI Query'
        },
        // {
        //   key: 'link',
        //   label: 'Link'
        // },
      {
        key: 'description',
        label: 'Rti Response'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    },
    {
        value: 'rti-compliance',
        label: 'Compliance under Section 4, RTI Act',
        moduleId: '9',
        subModuleId: '13',
        dynamicForm: {
          title: [''],
          fileList: [''],
          description:[''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    }
    ]
},
{
    value: 'Multimedia',
    label: 'Multimedia',
    id:11,
    options: [
    {
        value: 'multimedia-videos',
        label: 'Videos',
        moduleId: '11',
        subModuleId: '14',
        dynamicForm: {
          title: [''],
          source: [''],
          link:[''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'link',
          label: 'Link'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },
    {
        value: 'multimedia-photo',
        label: 'Photo',
        moduleId: '11',
        subModuleId: '15',
        dynamicForm: {
          title: [''],
          fileList: [''],
          source: [''],
          keyword:['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    }
    ]
},
{
    value: 'misc',
    label: 'Other Modules',
    id:'misc',
    options: [
    {
        value: 'parliament-ques',
        label: 'Parliament Q & A',
        moduleId: '10',
        // subModule
        dynamicForm: {
          title: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },{
        value: 'News Letters',
        label: 'News Letters',
        moduleId: '2',
        dynamicForm: {
          title: [''],
          issuedNo: [''],
          issuedDate: [''],
          publishedBy: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'issuedNo',
          label: 'Issued No'
        },
        {
          key: 'issuedDate',
          label: 'Issued Date'
        },
        {
          key: 'publishedBy',
          label: 'Published By'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },{
        value: 'letters',
        label: 'Letters/Circular',
        moduleId: '4',
        dynamicForm: {
          title: [''],
          issuedDate: [''],
          fileList: [''],
          issuedBy: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        {
          key: 'issuedBy',
          label: 'Issued By'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },{
        value: 'Tenders',
        label: 'Procurement',
        moduleId: '5',
        dynamicForm: {
          title: [''],
          corrigendum: [''],
          dueDateExtended: [''],
          autoExpiry: [''],
          lastDateSubmission: [''],
          issuedDate: [''],
          fileList: [''],
          referenceNo: [''],
          description : [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'corrigendum',
          label: 'Corrigendum'
        },
        {
          key: 'dueDateExtended',
          label: 'Due Date Extended'
        },
        {
          key: 'autoExpiry',
          label: 'Auto Expiry'
        },
        {
          key: 'lastDateSubmission',
          label: 'Last Date of Submission'
        },
        {
          key: 'issuedDate',
          label: 'Date Of Issue'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key:'referenceNo',
          label: 'Reference No'

        },
        {
          key:'description',
          label: 'Number of Days Extended'

        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },{
        value: 'Events',
        label: 'Events',
        moduleId: '6',
        dynamicForm: {
          title: [''],
          // publishedDate: [''],
          location: [''],
          fileList: [''],
          startDate:[''],
          endDate:[''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        // {
        //   key: 'publishedDate',
        //   label: 'Date'
        // },
        {
          key: 'location',
          label: 'Location'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'startDate',
          label: 'Start Date',
        },
        {
          key: 'endDate',
          label: 'End Date',
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },{
        value: 'Media/News',
        label: 'Media/News',
        moduleId: '7',
        dynamicForm: {
          title: [''],
          publishedDate: [''],
          link: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'publishedDate',
          label: 'Date'
        },
        {
          key: 'link',
          label: 'Link'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },{
        value: 'Careers',
        label: 'Careers',
        moduleId: '12',
        dynamicForm: {
          title: [''],
          vacancyUploadedDate: [''],
          lastDateSubmission: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'vacancyUploadedDate',
          label: 'Vacancy Uploaded Date'
        },
        {
          key: 'lastDateSubmission',
          label: 'Last Date Of Submission'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
      ]
    },
    {
      value: 'Highlights',
      label: 'Highlights',
      moduleId: '28',
      dynamicForm: {
        title: [''],
        autoExpiry: [''],
        link: [''],
        // ranking:[''],
        fileList:[],
        keyword: ['']
      },
      tableHeader: [
        {
          key: 'title',
          label: 'Title'
        },
        {
          key:'autoExpiry',
          label: 'Auto Expiry'
        },
        // {
        //   key:'ranking',
        //   label: 'Ranking'
        // },
        {
          key:'link',
          label: 'Link'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
    ]
  },
  {
    value: 'Success Stories',
    label: 'Success Stories',
    moduleId: '78',
    dynamicForm: {
      title: [''],
      author: [''],
      fileList: [''],
      source:[''],
      publishedDate:[''],
      keyword: ['']
    },
    tableHeader: [{
      key: 'title',
      label: 'Title'
    },
    {
      key: 'author',
      label: 'Author'
    },
    {
      key: 'publishedDate',
      label: 'Published Date'
    },
    {
      key: 'fileList',
      label: 'File'
    },
    {
      key: 'source',
      label: 'Source'
    },
    {
      key: 'keyword',
      label: 'Keyword'
    }]
}
    ]
}];
  totalCount: any = 0;
  fileAcceptType: any;
  labelKeys: any;

  dateFlag = ['StartDate','EndDate','AutoExpiry','PublishedDate','Duedate','IssuedDate','LastDateSubmission'];

isEntry= false;
buttonDisable:any;
onSelectionChange(event:any){
  this.isEntry = false;
  let selectedModule =event.target.value;
  this.subModules = this.catageoryOption.find((item:any)=>item.id==selectedModule).options;
  this.catageoryOption.forEach((item:any) => {
  let tableHead:any = item.options.find((option:any) => option.moduleId == event.target.value);
    if(tableHead && tableHead.tableHeader){
      console.log("events",tableHead, event)
    // this.moduleData = tableHead;
      this.dynamicForm = tableHead.dynamicForm;
    this.tableHeader = tableHead.tableHeader;
    this.entryTitle = tableHead.label;
    this.selectedModuleOptions =  tableHead;
    if(tableHead.moduleId=='11' && tableHead?.subModuleId=='15'){
      this.fileAcceptType = this.imgExtensions.toString();
    }else{
      this.fileAcceptType =''
    }}
  })
  if(event.target.value=='misc'){
    delete this.selectedModuleOptions['subModuleId'];
    this.subModules = this.catageoryOption[this.catageoryOption.length-1].options;
    this.dynamicForm = this.subModules[0].dynamicForm;
    this.selectedModuleOptions['moduleId'] = this.subModules[0].moduleId;
  }
console.log(this.selectedModuleOptions)
}
onSubModuleChange(event:any){
  this.isEntry = false;

this.dynamicForm = this.subModules.find((item:any)=>item.value==event.target.value)?.dynamicForm;
this.selectedModuleOptions['subModuleId'] = this.subModules.find((item:any)=> item.value == event.target.value)?.subModuleId;
this.selectedModuleOptions['moduleId'] = this.subModules.find((item:any)=> item.value == event.target.value)?.moduleId;
!!this.selectedModuleOptions['subModuleId']??delete this.selectedModuleOptions['subModuleId']

}
submitData(event:any){
  console.log("event", event, this.dynamicForm, this.item)
  let type= 'save';
  let fileInput = event.file;
  let id:number  = 0;
  const formdata:any = new FormData();
  formdata.append("kmsId", this.kmsId)
  if(fileInput)
  for(let i=0; i<fileInput.length;i++){
    formdata.append("files", fileInput[i], fileInput[i].name);
  }

  let file = this.item.FileName ? this.item.FileName : '';
  if(!fileInput && this.kmsId && file)
  formdata.append("file", file)
  console.log("filess")
  for (let key in event.form) {
    console.log("keys", key)
    if(event.form[key] && key != 'fileList')
    formdata.append(key, event.form[key])
  }

  if(event.contentId){
  // formdata.append("contentId", event.contentId)
  id = event.contentId;
  type = 'update';
  }
  formdata.append("moduleId", this.selectedModuleOptions.moduleId)
  // if(!this.selectedModuleOptions.subModuleId)
  // this.selectedModuleOptions.subModuleId = '1';
  if(this.selectedModuleOptions.subModuleId)
  formdata.append("subModuleId", this.selectedModuleOptions.subModuleId)
  this.buttonDisable=true;
  this.contentService.saveContent(formdata, type, id).subscribe((data:any) => {
    if(data.responseCode == '200')
    this.goBack()
    this.buttonDisable = false;
    swal({
      title: "Success",
      text: "Data submitted successfully",
      icon: "success",
     // dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.rowData = {};
        this.getMasterData();
      }
  }
    )
},
(error) => {
  this.buttonDisable = false;
  console.log("edit cutoff error", error);
  swal("", `${error?.error?.errorMsg?error?.error?.errorMsg : 'Internal server error' }`, "error");
});

}

getMasterData(){
  console.log(this.selectedModule);
  let params = `moduleId=${this.selectedModuleOptions.moduleId}&page=${this.page}`;
  if(this.selectedModuleOptions.subModuleId)
  params += `&subModuleId=${this.selectedModuleOptions.subModuleId}`;
  this.contentService.getAdminContent('list',params).subscribe((result:any) => {
    console.log("results", result);
    this.masterData = result.wrapperList;
    this.totalCount = result?.totalcount

    console.log('masterData', this.masterData)
  })
}

editData(rowData:any,isEdit:any=true){
  console.log("record", rowData, isEdit)
  this.labelKeys = {}
  this.labelKeys = this.tableHeader.map((item:any) => this.labelKeys[item.key] =item.label)
  this.isViewMode = !isEdit;
  this.rowData = JSON.parse(JSON.stringify(rowData));
  this.submitButton = 'Update';
  this.dynamicForm = this.selectedModuleOptions.dynamicForm;
  this.isChecked = false;
}

setApprove( event:any, rowData:any){
  rowData.checked = !rowData.checked;
  console.log("rowdata", rowData, event)
  if(this.masterData.find((item:any) => item.checked)){
  this.isChecked = true;
  }else{
    this.isChecked = false;
  }

}

approveReject(isApproved:any=1){
  let id = {};
  let approvedText = isApproved == 1 ? 'Approved':'Rejected';
  let filterId = this.masterData.filter((item:any) => item.checked);
  id = filterId ? {id: filterId.map((item:any) => item.id)} : {};
  this.contentService.changeAprrove(isApproved, id).subscribe((data:any) => {
    if(data.responseCode == '200')
    swal({
      title: "Success",
      text: `Data ${approvedText} successfully`,
      icon: "success",
     // dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.masterData = this.masterData.map((item:any) => ({...item, checked: false}));
        this.isChecked = false;
        this.getMasterData();
      }
  })

    console.log("result")
  },
  (error) => {
    console.log("edit cutoff error", error);

    swal("Error", `Some error occured`, "error");
  })
}
pageEvent(event:any){
  this.page = event;
  this.getMasterData();
}
deleteContent(record:any){
  this.contentService.deleteAdminContent('admincontent',`id=${record.id}`).subscribe((data:any) => {
    if(data.responseCode == '200')
    swal({
      title: "Success",
      text: `Data Deleted successfully`,
      icon: "success",
     // dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.getMasterData();
      }
  })
})
}
openDeleteDialog(data:any) {
  const dialogRef = this.dialog.open(DeleteDialogComponent);
  dialogRef.afterClosed().subscribe((result:any) => {
    if(result){
      this.deleteContent(data)
    }
  });
}
resetFileType(){
  this.fileAcceptType=''
  console.log(this.selectedModuleOptions)
  console.log('masterData', this.masterData)
}
openDialog(fileList:any) {
  const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
    data:{
      fileList:fileList,
    },
    width: '800px',
    height: 'auto',
  });
  dialogRef.afterClosed().subscribe((result:any) => {
    console.log(`Dialog result: ${result}`);
  });
  }
  addCore(){
    // this.isEntry = true;
    // this.isViewMode = true;
    this.labelKeys = {};
    this.rowData = {};
    console.log("rowDyanamicForm", this.dynamicForm, this.rowData, this.masterData)
    let obj:any = {title:'fileName', fileType:'knowledgeMaterialVertical',language:'language',source:'source',fileList:'file',keyword:'keyword'};
    let filteredArray = this.masterData.find((value:any)=>{
      console.log(value);
      return  value.id == this.kmsId
    })
    console.log('filteredArray', filteredArray);
    if(filteredArray && !filteredArray.fileList ){
      this.dynamicForm = {...filteredArray, fileList : [
        {
          file:filteredArray.file,
          fileName:filteredArray.fileName,
          fileType:filteredArray.fileType,
          fileUrl:filteredArray.file
        }
        ],
        title: filteredArray.title,
        author:  filteredArray.approverName,
        publishedDate:filteredArray.createdAt,
        publishedBy:filteredArray.approverName // not clear right now need to fix further
      };
      this.rowData = this.dynamicForm;
    }else {
      for(let key in obj){
        this.rowData[key] = filteredArray[key]
        if(key != 'fileList' && this.dynamicForm[key])
          this.dynamicForm[key] = filteredArray[key];
        if(key == 'fileList' && this.dynamicForm[key]){
          this.dynamicForm[key] = {fileUrl: filteredArray[key]};
          this.rowData[key] = [{fileUrl: filteredArray[key]}]
        }
      }

    }
    console.log("rowDyanamicForm", this.dynamicForm, obj, this.rowData, this.masterData)

    this.tableHeader.forEach((item:any) => this.labelKeys[item.key]=item.label)
       this. submitButton = 'Submit';
       console.log(this.rowData)

       this. isChecked = false;
  }
  getDate(data:any,key:any){
    if(this.dateFlag.includes(key)){
      return new Date(data).toDateString()
    }else{
      return data
    }
  }
  checkDataTypes(data:any){
    if(typeof(data)=='string'){
      return data[0].toUpperCase()+ data.slice(1);
    }
    return data
   }
   typeOf(data:any){
     return(typeof data);
   }
}
