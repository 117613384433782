<div class="container">
    <div class="row py-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/about" class="nav-link ">Themes</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Special Interventions</div>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Integrated Farming Cluster (IFC)</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <span style="font-weight: 900;">Integrated Farming Cluster (IFC)</span>
                    Farm Livelihoods strategy under DAY-NRLM include two components i.e., i) Strengthening the women farmers in production system through adoption of Agro Ecological Practices, better livestock management practices, scientific NTFP collection and cultivation, ii) Support these small and marginal women farmers for better market access by promoting community owned producer collectives.<br /><br />
                    Further, with deliberation with various stakeholders like ICAR, KVK, CSO, strategy was devised in 2022 to further intensify the Farm livelihood interventions through diversified livelihood interventions strategy to enhance income at household level through integration and bring in synergy between the livelihood activities by promotion of IFC approach. This includes interventions in forward linkages with strengthened backward integration in the identified cluster of intervention villages. 
                </div>
            </div>
        </div>
    </div>
    <app-common-document-upload [moduleId]="31" [subModuleId]="41"></app-common-document-upload>
</div>