<footer class="footerBg">
    <div class="container-fluid p-0" style="position:absolute; bottom: 0;">
        <div class="row bgCustom" style="width: 100%; margin-right: auto;">
            <div class="col-sm-12 mb-3 col-lg ms-5">
                <h6 style="font-size: 30px;">Contact Us</h6>
                <div style="font-size: 15px;" class="mt-3">Deendayal Antyodaya Yojana -&nbsp;</div>
                <div style="font-size: 15px;">National Rural Livelihoods Mission (DAY-NRLM)</div>
                <div style="font-size: 15px;">Ministry of Rural Development - Govt. of India</div>
                <div style="font-size: 15px;">7th Floor, NDCC Building -II, Jai Singh Road</div>
                <div style="font-size: 15px;">New Delhi - 110001</div>
                <div style="font-size: 15px;">Phone: 011 - 23461708</div>
            </div>
        </div>
    </div>
</footer>
<div class="back-to-top" (click)="scrollToTop()">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/><path d="M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20 M12,22c5.52,0,10-4.48,10-10c0-5.52-4.48-10-10-10 C6.48,2,2,6.48,2,12C2,17.52,6.48,22,12,22L12,22z M11,12l0,4h2l0-4h3l-4-4l-4,4H11z"/></g></svg>
</div>
