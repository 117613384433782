import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContentService } from 'src/app/shared/services/content.service';
var md5 = require("md5");
import { sha256 } from 'js-sha256';
import { SweetAlert } from 'sweetalert/typings/core';
const swal: SweetAlert = require("sweetalert");

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  salt: string = '';
  constructor(private formBuilder: FormBuilder, private contentService: ContentService, private router: Router,) {
    this.sessionId = this.generateUUID();
    this.loginForm = this.formBuilder.group({
      loginId: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      sessionId: this.sessionId,
      captcha: ['']
    });
    sessionStorage.removeItem('token');
  }
  sessionId: any;
  isDisabled = false;
  userLevel: any;

  ngOnInit(): void {
    this.getCaptcha();
    // this.submitLogin()
  }

  submitLogin() {
    console.log("Login Form ", this.loginForm)
    let pass = sha256(this.loginForm.get('password')?.value);
    pass = sha256(pass + this.salt);

    let obj = {
      ...this.loginForm.value,
      password: pass
    }

    if(this.loginForm.controls['loginId'].status === 'VALID' && this.loginForm.controls['password'].status === 'VALID') {
      this.isDisabled = true;
      this.contentService.loginSubmit(obj).subscribe((result: any) => {
        this.isDisabled = false;
        sessionStorage.setItem('token', result?.accessToken);
        let levelParams = 'id=' + result.levelId;
        let route = '';
        this.contentService.getUserLevel(levelParams).subscribe((levelList: any) => {
          let permissionNavigator: any = { 'admincontent': '/admin/content', 'extracontent': '/admin/website-editor', 'faq': '/admin/faq' }
          this.userLevel = levelList.wrapperList[0];
          sessionStorage.setItem('permissionModules', JSON.stringify(this.userLevel.permissionModules));
          console.log("Leveluserlevel", this.userLevel);
          
          this.userLevel.permissionModules.forEach((item :any)=>{
            console.log(item);
            for(let key in permissionNavigator){       
              if(item.endPoint.includes(key)){      
                this.userLevel.permissionModules[0].endPoint = permissionNavigator[key]
                let redirect = this.userLevel.permissionModules[0].endPoint
                this.router.navigate([redirect]);
                break;
              }
            }     
           })
          console.log("Leveluserlevel", this.userLevel);
          let pageNavigator = this.userLevel && this.userLevel.permissionModules && this.userLevel.permissionModules.length ? this.userLevel.permissionModules[0].endPoint : 'content';
          console.log("Level", pageNavigator);
  
  
          for (let permission in permissionNavigator) {
            if (pageNavigator.indexOf(permission) > -1)
            console.log(permissionNavigator)
              route = permissionNavigator[permission];
          }
          console.log("Level", route);
          // this.router.navigate([route]);
          
        }, error => {
          route = route ? route : 'admin/website-editor';
          this.router.navigate([route]);
        })
  
      }, error => {
        this.refreshLogin()
        this.isDisabled = false;
        swal("", `${error?.error?.errorMsg}`, "error");
  
      })
    } else if(this.loginForm.controls['loginId'].status === 'INVALID' && this.loginForm.controls['password'].status === 'INVALID') {
      swal("", `Please Enter Credential`, "error");
    } else if(this.loginForm.controls['loginId'].status === 'INVALID') {
      swal("", `Please Enter Username`, "error");
    } else if(this.loginForm.controls['password'].status === 'INVALID') {
      swal("", `Please Enter Password`, "error");
    }

  }
  getCaptcha() {
    this.contentService.getCaptcha(this.sessionId).subscribe((result: any) => {
      console.log("results", result);
      this.salt = result.salt ? result.salt : 'rSgQjJC1jjMXVZE0Cb4KHA==';
      sessionStorage.setItem('saltId', this.salt)
      this.contentService.getBase64ToImage(result['captchaan'], 'captchaId');
    })
  }
  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  refreshLogin() {
    this.deleteChild();
    this.sessionId = this.generateUUID();
    this.loginForm.patchValue({
      sessionId: this.sessionId,
      captcha: ''
    })
    this.getCaptcha()
  }
  deleteChild() {
    var e = <HTMLElement>document.getElementById('captchaId');
    var first = e?.firstElementChild;
    while (first) {
      first.remove();
      first = e?.firstElementChild;
    }
  }

  randomString() {
    return 'new-text-' + Math.random()
  }

}


