import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

import { LoginComponent } from './pages/login/login.component';

import { JoinUsComponent } from './pages/join-us/join-us.component';

import { FaqComponent } from './pages/faq/faq.component';
import { PhotoGalleryComponent } from './pages/multimedia/photo-gallery/photo-gallery.component';
import { VideosUploadComponent } from './pages/videos-upload/videos-upload.component';
import { VideoGalleryComponent } from './pages/multimedia/video-gallery/video-gallery.component';
import { NationalRepositoryComponent } from './pages/knowledge-products/national-repository/national-repository.component';
import { NrlmDocumentsComponent } from './pages/knowledge-products/nrlm-documents/nrlm-documents.component';
import { NewslettersComponent } from './pages/knowledge-products/newsletters/newsletters.component';
import { ForumsComponent } from './pages/multimedia/forums/forums.component';
import { ParliamentQaComponent } from './pages/multimedia/parliament-qa/parliament-qa.component';
import { ResourcesComponent } from './pages/multimedia/resources/resources.component';
import { RtiActComponent } from './pages/multimedia/rti-act/rti-act.component';
import { UpdatesComponent } from './pages/whats-new-section/updates/updates.component';
import { LettersCircularsComponent } from './pages/whats-new-section/letters-circulars/letters-circulars.component';
import { EventsComponent } from './pages/whats-new-section/events/events.component';
import { TendersComponent } from './pages/whats-new-section/tenders/tenders.component';
import { MediaNewsComponent } from './pages/whats-new-section/media-news/media-news.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CopyrightPolicyComponent } from './pages/copyright-policy/copyright-policy.component';
import { HyperlinkPolicyComponent } from './pages/hyperlink-policy/hyperlink-policy.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { AccessibilityStatementComponent } from './pages/accessibility-statement/accessibility-statement.component';
import { ContactCeoComponent } from './pages/contact-ceo/contact-ceo.component';
import { ScreenReaderAccessComponent } from './pages/screen-reader-access/screen-reader-access.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { OtherUsefulLinksComponent } from './pages/other-useful-links/other-useful-links.component';
import { AuthGuard } from './auth.guard';
import { HighlightsComponent } from './pages/admin/highlights/highlights.component';
import { ContentSearchComponent } from './pages/content-search/content-search.component';
import { ContentDetailComponent } from './pages/content-detail/content-detail.component';
import { LoginGuardGuard } from './shared/login-guard.guard';
import { RtiComplianceComponent } from './pages/multimedia/rti-compliance/rti-compliance.component';
import { ConvergencePartnersComponent } from './pages/convergence-partners/convergence-partners.component';
import { MediaGalleryComponent } from './pages/media-gallery/media-gallery.component';


const routes: Routes = [
	{
		path: "",
        component: HomeComponent,
	},
	{
		path: "login",
        component: LoginComponent,
        canActivate:[LoginGuardGuard]
	},

	{
		path: "home",
        component: HomeComponent,
	},

	{
		path: "convergence/partners",
        component: ConvergencePartnersComponent,
	},
	{
		path: "mediaGallery",
        component: MediaGalleryComponent,
	},
	{
		path: "videoUpload",
		component: VideosUploadComponent
	},

	{
		path: "about",
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)
	},
	{
		path: "project",
		loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectModule)
	},
	{
		path: "resource",
		loadChildren: () => import('./pages/resource-center/resource-center.module').then(m => m.ResourceCenterModule)
	},
	{
		path: "what-we-do",
    loadChildren: () => import('./pages/what-we-do/what-we-do.module').then(m => m.WhatWeDoModule)
	},

	{
		path: "work-structure",
    loadChildren: () => import('./pages/work-structure/work-structure.module').then(m => m.WorkStructureModule)
	},

	{
		path: "our-impact",
    loadChildren: () => import('./pages/our-impact/our-impact.module').then(m => m.OurImpactModule)
	},

  {
    path: "resource-center",
    loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule)
  },
  {
    path: "fl",
    loadChildren: () => import('./pages/flComponents/fl-components.module').then(m => m.FlComponentsModule)
  },
  {
    path: "theme",
    loadChildren: () => import('./pages/theme/theme.module').then(m => m.ThemeModule)
  },
  {
    path: "admin",
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
    canActivate:[AuthGuard]
  },

	{
		path: "join-us",
        component: JoinUsComponent ,
	},
  {
		path: "highlights",
        component: HighlightsComponent ,
	},

	{
		path: "faq",
        component: FaqComponent ,
	},
	{
		path: "multimedia/photo-gallery",
        component: PhotoGalleryComponent ,
	},
	{
		path: "multimedia/video-gallery",
		 		component: VideoGalleryComponent,
	},
	{
		path: "multimedia/forums",
		 		component: ForumsComponent,
	},
	{
		path: "multimedia/parliament-qa",
		 		component: ParliamentQaComponent,
	},
	{
		path: "multimedia/resources",
		 		component: ResourcesComponent,
	},
	{
		path: "multimedia/rti-act",
		 		component: RtiActComponent,
	},
  {
		path: "multimedia/rti-compliance",
		 		component: RtiComplianceComponent,
	},
	{
		path: "knowledge-products/national-repository",
		 		component: NationalRepositoryComponent,
	},
	{
		path: "knowledge-products/nrlm-documents",
		 		component: NrlmDocumentsComponent,
	},
	{
		path: "knowledge-products/newsletters",
		 		component: NewslettersComponent,
	},
	{
		path: "whats-new-section/updates",
		 		component: UpdatesComponent,
	},
	{
		path: "whats-new-section/letters-circulars",
		 		component: LettersCircularsComponent,
	},
	{
		path: "whats-new-section/tenders",
		 		component: TendersComponent,
	},
	{
		path: "whats-new-section/events",
		 		component: EventsComponent,
	},
	{
		path: "whats-new-section/media-news",
		 		component:MediaNewsComponent,
  },
	{
		path:"multimedia/feedback",
		component:FeedbackComponent,
	},
	{
		path:"sitemap",
		component:SitemapComponent,
	},
	{
		path:"terms-conditions",
		component:TermsConditionsComponent,
	},
	{
		path:"privacy-policy",
		component:PrivacyPolicyComponent,
	},
	{
		path:"copyright-policy",
		component:CopyrightPolicyComponent,
	},
	{
		path:"hyperlink-policy",
		component:HyperlinkPolicyComponent,
	},
	{
		path:"disclaimer",
		component:DisclaimerComponent	,
	},
	{
		path:"accessibility-statement",
		component:AccessibilityStatementComponent	,
	},
	{
		path:"contact-ceo",
		component:ContactCeoComponent	,
	},
	{
		path:"screen-reader-access",
		component:ScreenReaderAccessComponent	,
	},
	{
		path:"dashboard",
		component:DashboardComponent	,
	},
	{
		path:"other-useful-links",
		component:OtherUsefulLinksComponent	,
	},
  {
    path: "search/:id",
    component: ContentSearchComponent
  },
  {
    path: "content-details/:id",
    component: ContentDetailComponent
  },
  {
    path:'**',
    redirectTo:'/'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
