import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { sha256 } from 'js-sha256';
const swal: SweetAlert = require("sweetalert");

interface dynamicFormInterface {
  name: string[],
  email: string[],
  username: string[],
  mobile: string[],
  password: string[],
  answer: string[],
  question: string[],
  levelId: string[]
}

interface tableHeaderInterface {
  [key: string]: { key: string; label: string; }[]
}


interface userListInterface {
  name?: string,
  username?: string,
  email?: string,
  mobile?: string,
  password?: string,
level?: string
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  page = 1;
  entryTitle: string = 'Users';
  userList: userListInterface[] | any[] = []
  totalCount = 0;
  buttonDisable: boolean = false;
  isEntry: boolean = false;
  isViewMode: boolean = false;
  rowData: any = {}
  currentPage = 0
  dynamicForm: dynamicFormInterface = {
    name: [''],
    email: [''],
    username: [''],
    mobile: [''],
    password: [''],
    question: [''],
    answer: [''],
    levelId: ['']
  }
  submitButton = 'Submit'
  tableHeader: { key: string, label: string }[] = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'username',
      label: 'Username',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'mobile',
      label: 'Mobile',
    },
    // {
    //   key: 'password',
    //   label: 'Password',
    // },

  ];
  salt ='rSgQjJC1jjMXVZE0Cb4KHA==';
  flag:any=0;
  isEntryStatus: any;
  isViewModeStatus: any;
  isEditDataStatus: any;
  createUserStatus: any
  pageSize = 20

  constructor(private contentService: ContentService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.contentService.kmsRouter.subscribe((item)=>{
      console.log(item)
      this.isEntry = item
      this.entryTitle = 'Users'
    })
    this.getUsers()
    this.getkmsCount()

    this.isEntryStatus = sessionStorage.getItem("isEntry") ? sessionStorage.getItem("isEntry") : "false"
    this.isViewModeStatus = sessionStorage.getItem("isViewMode")
    this.isEditDataStatus = sessionStorage.getItem("isEdit")
    this.createUserStatus = sessionStorage.getItem('createUserStatus')

    console.log("Is View Mode Status ", this.isViewModeStatus)

    if(this.isEntryStatus === 'true') {
      //  debugger
       this.isEntry = true
      //  this.entryTitle = 'Create User'
      //  this.entryTitle = this.isViewModeStatus == 'true' ? 'Edit User' : 'Create User'
      console.log("View Mode ", this.isViewModeStatus, this.isEditDataStatus, this.createUserStatus)
      if(this.isViewModeStatus == 'true' || this.isEditDataStatus == 'true' && this.createUserStatus == 'false') {
         this.entryTitle = 'Edit User'
         this.isViewMode = this.isViewModeStatus == 'true' ? true : false 
      }else if(this.isViewModeStatus == 'false' && this.createUserStatus == 'true') {
        this.entryTitle = 'Create User'
      }
      this.rowData = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem("rowData"))))
      console.log("Row Data ", this.rowData)
    } else {
      this.isEntry = false
      this.entryTitle = 'User'
    }

    console.log("Is Entry ", this.isEntry)

    
  }
  pageEvent(event: any) {
    this.page = event;
    this.currentPage = event == 1 ? 0 : (event-1)
    this.getUsers();
  }
  
  getkmsCount(){
    this.contentService.getKmsCount('isSyncedCount',`flag=${this.flag}`)
                       .subscribe((res:any)=>{
                        console.log('kms count--->>>', res)
                        let kmsCount = res
                        this.contentService.kmsCounter.next(kmsCount)
                       })
  }

  getUsers() {
    this.contentService
      .getUser('list', `page=${this.page}`)
      .subscribe((result: any) => {

        let obj: any = {};
        this.userList = result.wrapperList;
        this.totalCount = result?.totalcount;

      });
  }
  goBack() {
    this.entryTitle = "Users"
    this.isEntry = false;
    sessionStorage.removeItem("isEntry")
    sessionStorage.removeItem("isViewMode")
    sessionStorage.removeItem("isEdit")
    sessionStorage.removeItem("rowData")
  }
  addCore() {
    this.entryTitle = "Create User"
    this.isEntry = true;
    this.isEntryStatus = "true"
    this.createUserStatus = 'true'
    this.isViewModeStatus = 'false'
    sessionStorage.setItem('createUserStatus', this.createUserStatus)
    sessionStorage.setItem('isViewMode', this.isViewModeStatus)
    sessionStorage.setItem("isEntry", this.isEntryStatus)
    this.isViewMode = false;
    this.submitButton = 'Submit';
    this.rowData = {};
  }
  submitData(event: any) {
    // let pass = sha256(event.form.get('password')?.value);
    // pass = sha256(pass + this.salt);
    console.log("event", event)
    let values = event.form
    let id: number = event.id ? event.id : 0;
    this.buttonDisable = true;
    this.contentService.saveUser(values, id).subscribe((data: any) => {
      if (data.responseCode == '200')
        this.buttonDisable = false;
        sessionStorage.removeItem("isEntry")
    sessionStorage.removeItem("isViewMode")
    sessionStorage.removeItem("isEdit")
    sessionStorage.removeItem("rowData")
      swal({
        title: "Success",
        text: "Data submitted successfully",
        icon: "success",
      })
        .then((willDelete) => {
          if (willDelete) {
            this.rowData = {};
            this.isEntry = false;
            this.getUsers();
          }
        }
        )
    }
      ,
      (error) => {
        this.buttonDisable = false;
        console.log("edit cutoff error", error);
        // swal("", `${error?.error?.errorMsg?error?.error?.errorMsg : 'Internal server error' }`, "error");
      });
  }
  editData(rowData: any, isEdit: any = true) {
    console.log("Row Data ", rowData)
    this.entryTitle = "Edit User"
    this.isViewMode = !isEdit;
    this.rowData = JSON.parse(JSON.stringify(rowData));
    this.isEntry = true;
    this.submitButton = 'Update';
    this.isEntryStatus = 'true'
    this.isViewModeStatus = !isEdit ? 'true' : 'false'
    this.isEditDataStatus = !isEdit ? 'false' : 'true'
    this.createUserStatus = 'false'
    sessionStorage.setItem('createUserStatus', this.createUserStatus)
    sessionStorage.setItem("isEdit", this.isEditDataStatus)
    sessionStorage.setItem('isEntry', this.isEntryStatus)
    sessionStorage.setItem("isViewMode", this.isViewModeStatus)
    sessionStorage.setItem("rowData", JSON.stringify(this.rowData))
    // this.dynamicForm = this.selectedModuleOptions.dynamicForm;
  }

  deleteFaq(record: any) {
    this.contentService.deleteUserContent(`id=${record.id}`).subscribe((data: any) => {
      if (data.responseCode == '200')
        swal({
          title: "Success",
          text: `Data Deleted successfully`,
          icon: "success",
          // dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
              this.getUsers();
            }
          })
    })
  }

  openDeleteDialog(data: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteFaq(data)
      }
    });
  }
}
