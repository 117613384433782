<div class="container">
    <div class="row py-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/about" class="nav-link ">Themes</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Livestock</div>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">Poultry</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p>Backyard poultry is defined as the small-scale rearing of poultry birds (chicken) by womenfolk in poor households for dietary improvement, cash income, investment and security against risk. Here, the poultry birds may range freely in the household compound and, find much of their food, get additional amounts from the household. The birds are fed with locally available feed sources, mostly household and farm by-products. Backyard poultry is rarely the sole means of livelihood for a family but is one of the number of integrated and complementary farming activities contributing to the overall well-being of the household. It generates income from the sale of poultry birds and eggs. Eggs can provide a regular, albeit small, income, while the sale of live birds provides a more flexible source of cash. Occasional consumption of chicken meat and egg offers a valuable source of protein in the household diet. Poultry also plays an essential socio-cultural role in many societies </p>                 
                </div>
            </div>
        </div>
    </div>
    <app-common-document-upload [moduleId]="31" [subModuleId]="5"></app-common-document-upload>
</div>