import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationComponent } from 'src/app/shared/component/pagination/pagination.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AlphabetOnlyDirective } from './directive/alphabet-only.directive';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ReadmoreDirective } from './readmore.directive';
import { camelCaseToSpaceDirective } from './directive/camelCaseToSpace.directive';
import { CommonGalleryDialogComponent } from './common-gallery-dialog/common-gallery-dialog.component';
import { CommonFiltersComponent } from './common-filters/common-filters.component';
import { CommonDocumentUploadComponent } from '../pages/common-document-upload/common-document-upload.component';

@NgModule({
    declarations: [
        PaginationComponent,
        PaginatorComponent,
        DeleteDialogComponent,
        AlphabetOnlyDirective,
        ReadmoreDirective,
        camelCaseToSpaceDirective,
        CommonGalleryDialogComponent,
        CommonFiltersComponent,
        CommonDocumentUploadComponent
    ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule



    ],
    exports: [
        PaginationComponent,
        PaginatorComponent,
        CommonModule,
        DeleteDialogComponent,
        AlphabetOnlyDirective,
        MatTooltipModule,
        ReadmoreDirective,
        camelCaseToSpaceDirective,
        CommonGalleryDialogComponent,
        CommonFiltersComponent,
        CommonDocumentUploadComponent
    ]
})
export class SharedModule { }
