import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = require("sweetalert");


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  currentPage = 0;
  pageSize = 20;
  tableHeader: any = [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'publishedDate',
    label: 'Published Date'
  },
  {
    key: 'publishedBy',
    label: 'Published By'
  },
  {
    key: 'author',
    label: 'Author'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'source',
    label: 'Document Source'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  }
  ];
  docExtensions = [
    'doc',
    'docm',
    'docx',
    'pdf',
    'txt',
    'csv',
    'xls',
    'xlsb',
    'xlsx',
    'pptm',
    'pptx',
    'application/pdf',
    'application/vnd.ms-excel'
  ];
  imgExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/raw', 'image/image'];
  videoExtensions = [
    'mp4',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'f4v',
    'swf',
    'mkv',
    'webm',
    // 'html5',
    'mpeg-2',
  ];
  page = 1;
  entryTitle: any = 'NRLM Document';
  selectedModule: any;
  selectedModuleOptions: any = { moduleId: '29', subModuleId: '38' };
  rowData: any = [];
  isChecked: boolean = false;
  isViewMode: boolean = false;
  submitButton: string = 'Submit';
  dynamicForm: any = {
    title: [''],
    publishedDate: [''],
    publishedBy: [''],
    author: [''],
    fileList: [''],
    source: [''],
    keyword: ['']
  };
  totalCount: any = 0;
  fileAcceptType: any;
  labelKeys: any;
  permissionObject: any;
  contentPermission: any;
  isEntryTitleStatus: boolean = false
  addData: any = "false"
  editDataStatus: any = 'false';
  constructor(
    private router: Router,
    private contentService: ContentService,
    private dialog: MatDialog

  ) { }
  isEntry: boolean = false;
  //   masterData:any = [
  //     {
  //         "id": 4,
  //         "moduleId": 1,
  //         "subModuleId": 1,
  //         "title": "testtitle",
  //         "documentSource": "NRLM",
  //         "author": "NRLM",
  //         "uploadedOn": null,
  //         "publishedBy": null,
  //         "publishedDate": "2015-01-12T18:30:00.000+00:00",
  //         "issuedNo": null,
  //         "issuedDate": null,
  //         "issuedBy": null,
  //         "corrigendum": null,
  //         "dueDateExtended": null,
  //         "autoExpiry": null,
  //         "lastDateSubmission": null,
  //         "location": null,
  //         "link": null,
  //         "fileNumber": null,
  //         "source": null,
  //         "vacancyUploadedDate": null,
  //         "rtiQuery": null,
  //         "description": null,
  //         "isUpdate": null,
  //         "isHighlight": null,
  //         "approvedRejectedStatus": null,
  //         "approvedRejectedBy": null,
  //         "fileList": []
  //     },
  //     {
  //         "id": 3,
  //         "moduleId": 1,
  //         "subModuleId": 1,
  //         "title": "testtitle",
  //         "documentSource": "NRLM",
  //         "author": "NRLM",
  //         "uploadedOn": null,
  //         "publishedBy": null,
  //         "publishedDate": "2015-01-12T18:30:00.000+00:00",
  //         "issuedNo": null,
  //         "issuedDate": null,
  //         "issuedBy": null,
  //         "corrigendum": null,
  //         "dueDateExtended": null,
  //         "autoExpiry": null,
  //         "lastDateSubmission": null,
  //         "location": null,
  //         "link": "https://www.google.com/url?sa=i&url=https%3A%2F%2Funsplash.com%2Fs%2Fphotos%2Fview&psig=AOvVaw04_4SRLFzb_xYsq8gPbxXd&ust=1650958461405000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCPjA0dbZrvcCFQAAAAAdAAAAABAD",
  //         "fileNumber": null,
  //         "source": null,
  //         "vacancyUploadedDate": null,
  //         "rtiQuery": null,
  //         "description": null,
  //         "isUpdate": null,
  //         "isHighlight": null,
  //         "approvedRejectedStatus": null,
  //         "approvedRejectedBy": null,
  //         "fileList": []
  //     },
  //     {
  //         "id": 2,
  //         "moduleId": 1,
  //         "subModuleId": 1,
  //         "title": "testtitle",
  //         "documentSource": "NRLM",
  //         "author": "NRLM",
  //         "uploadedOn": null,
  //         "publishedBy": null,
  //         "publishedDate": "2015-06-17T18:30:00.000+00:00",
  //         "issuedNo": null,
  //         "issuedDate": null,
  //         "issuedBy": null,
  //         "corrigendum": null,
  //         "dueDateExtended": null,
  //         "autoExpiry": null,
  //         "lastDateSubmission": null,
  //         "location": null,
  //         "link": null,
  //         "fileNumber": null,
  //         "source": null,
  //         "vacancyUploadedDate": null,
  //         "rtiQuery": null,
  //         "description": null,
  //         "isUpdate": null,
  //         "isHighlight": null,
  //         "approvedRejectedStatus": null,
  //         "approvedRejectedBy": null,
  //         "fileList": []
  //     },
  //     {
  //         "id": 1,
  //         "moduleId": 1,
  //         "subModuleId": 1,
  //         "title": "testtitle",
  //         "documentSource": "NRLM",
  //         "author": "NRLM",
  //         "uploadedOn": null,
  //         "publishedBy": "JS-RL",
  //         "publishedDate": "2020-08-17T18:30:00.000+00:00",
  //         "issuedNo": null,
  //         "issuedDate": null,
  //         "issuedBy": null,
  //         "corrigendum": null,
  //         "dueDateExtended": null,
  //         "autoExpiry": null,
  //         "lastDateSubmission": null,
  //         "location": null,
  //         "link": "https://www.google.com/url?sa=i&url=https%3A%2F%2Funsplash.com%2Fs%2Fphotos%2Fview&psig=AOvVaw04_4SRLFzb_xYsq8gPbxXd&ust=1650958461405000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCPjA0dbZrvcCFQAAAAAdAAAAABAD",
  //         "fileNumber": null,
  //         "source": null,
  //         "vacancyUploadedDate": null,
  //         "rtiQuery": null,
  //         "description": null,
  //         "isUpdate": null,
  //         "isHighlight": null,
  //         "approvedRejectedStatus": null,
  //         "approvedRejectedBy": null,
  //         "fileList": []
  //     }
  // ]
  masterData: any = [];
  isKms = false;
  catageoryOption: any = [
  // {
  //   value: 'Themes',
  //   label: 'Themes',
  //   options: [
  //     {
  //       value: 'Sustainable Agriculture',
  //       label: 'Sustainable Agriculture',
  //       moduleId: '28',
  //       subModuleId: '41',
  //       dynamicForm: {
  //         title: [''],
  //         fileList: [''],
  //         issuedDate: [''],
  //         keyword: ['']
  //       },
  //       tableHeader: [{
  //         key: 'title',
  //         label: 'Title'
  //       },
  //       {
  //         key: 'fileList',
  //         label: 'File'
  //       },
  //       {
  //         key: 'issuedDate',
  //         label: 'Date'
  //       },
  //       {
  //         key: 'keyword',
  //         label: 'Keyword'
  //       }]
  //     },
  //     {
  //       value: 'Livestock',
  //       label: 'Livestock',
  //       moduleId: '28',
  //       subModuleId: '42',
  //       dynamicForm: {
  //         title: [''],
  //         fileList: [''],
  //         issuedDate: [''],
  //         keyword: ['']
  //       },
  //       tableHeader: [{
  //         key: 'title',
  //         label: 'Title'
  //       },
  //       {
  //         key: 'fileList',
  //         label: 'File'
  //       },
  //       {
  //         key: 'issuedDate',
  //         label: 'Date'
  //       },
  //       {
  //         key: 'keyword',
  //         label: 'Keyword'
  //       }]
  //     },
  //     {
  //       value: 'NTFP',
  //       label: 'NTFP',
  //       moduleId: '28',
  //       subModuleId: '43',
  //       dynamicForm: {
  //         title: [''],
  //         fileList: [''],
  //         issuedDate: [''],
  //         keyword: ['']
  //       },
  //       tableHeader: [{
  //         key: 'title',
  //         label: 'Title'
  //       },
  //       {
  //         key: 'fileList',
  //         label: 'File'
  //       },
  //       {
  //         key: 'issuedDate',
  //         label: 'Date'
  //       },
  //       {
  //         key: 'keyword',
  //         label: 'Keyword'
  //       }]
  //     },
  //     {
  //       value: 'Value Chain Development',
  //       label: 'Value Chain Development',
  //       moduleId: '28',
  //       subModuleId: '44',
  //       dynamicForm: {
  //         title: [''],
  //         fileList: [''],
  //         issuedDate: [''],
  //         keyword: ['']
  //       },
  //       tableHeader: [{
  //         key: 'title',
  //         label: 'Title'
  //       },
  //       {
  //         key: 'fileList',
  //         label: 'File'
  //       },
  //       {
  //         key: 'issuedDate',
  //         label: 'Date'
  //       },
  //       {
  //         key: 'keyword',
  //         label: 'Keyword'
  //       }]
  //     },
  //     {
  //       value: 'Special Intervention',
  //       label: 'Special Intervention',
  //       moduleId: '28',
  //       subModuleId: '43',
  //       dynamicForm: {
  //         title: [''],
  //         fileList: [''],
  //         issuedDate: [''],
  //         keyword: ['']
  //       },
  //       tableHeader: [{
  //         key: 'title',
  //         label: 'Title'
  //       },
  //       {
  //         key: 'fileList',
  //         label: 'File'
  //       },
  //       {
  //         key: 'issuedDate',
  //         label: 'Date'
  //       },
  //       {
  //         key: 'keyword',
  //         label: 'Keyword'
  //       }]
  //     }
  //   ]
  // },
  {
    value: 'Theme',
    label: 'Theme',
    options: [
      {
        value: 'Sustainable Agriculture > Horticulture',
        label: 'Sustainable Agriculture > Horticulture',
        moduleId: '31',
        subModuleId: '32',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        ]
      },
      {
        value: 'Sustainable Agriculture > Natural Farming',
        label: 'Sustainable Agriculture > Natural Farming',
        moduleId: '31',
        subModuleId: '33',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        ]
      },
      {
        value: 'Sustainable Agriculture > organic Farming',
        label: 'Sustainable Agriculture > organic Farming',
        moduleId: '31',
        subModuleId: '34',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Sustainable Agriculture > Agroforestry',
        label: 'Sustainable Agriculture > Agroforestry',
        moduleId: '31',
        subModuleId: '35',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'LiveStock > Poultry',
        label: 'LiveStock > Poultry',
        moduleId: '31',
        subModuleId: '5',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'LiveStock > Goat Rearing',
        label: 'LiveStock > Goat Rearing',
        moduleId: '31',
        subModuleId: '6',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'LiveStock > Fish Rearing',
        label: 'LiveStock > Fish Rearing',
        moduleId: '31',
        subModuleId: '7',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'LiveStock > Pig Rearing',
        label: 'LiveStock > Pig Rearing',
        moduleId: '31',
        subModuleId: '8',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'LiveStock > Dairy Promotion',
        label: 'LiveStock > Dairy Promotion',
        moduleId: '31',
        subModuleId: '9',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'NTFP/Non-wood Forest Products',
        label: 'NTFP/Non-wood Forest Products',
        moduleId: '31',
        subModuleId: '36',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
       
        ]
      },
      {
        value: 'Value Chain Development > PG Promotion',
        label: 'Value Chain Development > PG Promotion',
        moduleId: '31',
        subModuleId: '37',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Value Chain Development > PE Promotion',
        label: 'Value Chain Development > PE Promotion',
        moduleId: '31',
        subModuleId: '38',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Value Chain Development > FPO Promotion',
        label: 'Value Chain Development > FPO Promotion',
        moduleId: '31',
        subModuleId: '39',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Special Interventions > Millet Promotion',
        label: 'Special Interventions > Millet Promotion',
        moduleId: '31',
        subModuleId: '40',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
               ]
      },
      {
        value: 'Special Interventions > Integrated Farming Cluster (IFC)',
        label: 'Special Interventions > Integrated Farming Cluster (IFC)',
        moduleId: '31',
        subModuleId: '41',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Special Interventions > Sub-Sector Promotion',
        label: 'Special Interventions > Sub-Sector Promotion',
        moduleId: '31',
        subModuleId: '42',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Special Interventions > SLACC',
        label: 'Special Interventions > SLACC',
        moduleId: '31',
        subModuleId: '43',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
       
        ]
      },
    ]
  },
  {
    value: 'Digital Library',
    label: 'Digital Library',
    options: [
      {
        value: 'Orders, Advisory & Guidlines',
        label: 'Orders, Advisory & Guidlines',
        moduleId: '29',
        subModuleId: '38',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Evaluation & Study Reports',
        label: 'Evaluation & Study Reports',
        moduleId: '29',
        subModuleId: '39',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Best Practices & Case Study',
        label: 'Best Practices & Case Study',
        moduleId: '29',
        subModuleId: '40',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
       
        ]
      },
      {
        value: 'Knowledge Documents / Human Resource',
        label: 'Knowledge Documents / Human Resource',
        moduleId: '29',
        subModuleId: '41',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Knowledge Documents / Sustainable Agricuture',
        label: 'Knowledge Documents / Sustainable Agricuture',
        moduleId: '29',
        subModuleId: '42',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Knowledge Documents / Training Capacity Building',
        label: 'Knowledge Documents / Training Capacity Building',
        moduleId: '29',
        subModuleId: '43',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
    ]
  },
  {
    value: 'Media Gallery',
    label: 'Media Gallery',
    options: [
      {
        value: 'Photos',
        label: 'Photos',
        moduleId: '28',
        subModuleId: '29',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
      {
        value: 'Videos',
        label: 'Videos',
        moduleId: '28',
        subModuleId: '30',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
    ]
  },
  {
    value: "Lakhpati Initiatives",
    label: "Lakhpati Initiatives",
    options: [
      {
        value: 'Lakhpati Initiatives',
        label: 'Lakhpati Initiatives',
        moduleId: '31',
        subModuleId: '44',
        dynamicForm: {
          title: [''],
          fileList: [''],
          issuedDate: [''],
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        
        ]
      },
    ]
  }
];
  moduleData: any;
  buttonDisable: boolean = false;
  flag:any=0;

  ngOnInit(): void {
    this.contentService.kmsRouter.subscribe((item)=>{
      console.log(item)
      this.isEntry = item
    })
    let selectData: any = { target: { value: 'Orders, Advisory & Guidlines' } }
    this.onSelectionChange(selectData)

    this.addData = sessionStorage.getItem("addData") ? sessionStorage.getItem("addData") : "false"
    this.editDataStatus = sessionStorage.getItem('editDataStatus') ? sessionStorage.getItem('editDataStatus') : null

    if(this.addData === "true") {
      this.entryTitle = sessionStorage.getItem("entryTitle") ? sessionStorage.getItem("entryTitle") : 'Orders, Advisory & Guidlines'
      this.isEntry = true
      if(this.isEntry) {
        let selectData: any = { target: { value: this.entryTitle } }
        this.onSelectionChange(selectData)
      }
    }

    if(this.addData === 'false' && this.editDataStatus !== null) {
      this.entryTitle = sessionStorage.getItem("entryTitle") ? sessionStorage.getItem("entryTitle") : 'Orders, Advisory & Guidlines'
      this.isEntry = true
      console.log('Row Data ', JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('rowData')))))
      this.rowData = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('rowData'))))
      console.log("Row Data ", this.rowData)
      if(this.editDataStatus == 'true') {
        this.isViewMode = false
      } else {
        this.isViewMode = true
      }
    }

    // this.getMasterData()
    this.getkmsCount();
    if (sessionStorage.getItem("permissionModules")) {
      this.permissionObject = sessionStorage.getItem("permissionModules");
      this.permissionObject = JSON.parse(this.permissionObject);
      console.log("permissionObject", this.permissionObject);
    }
    else {
      this.permissionObject = [
        {
          "title": "Content",
          "permission": {
            "/aajeevika/admincontent/list": true,
            "/aajeevika/admincontent/save": true,
            "/aajeevika/admincontent/update": true,
            "/aajeevika/admincontent/delete": true,
            "/aajeevika/content/changeApprovedStatus": true,
            "reject": true
          },
          "key": "content"
        },
        {
          "title": "FAQ",
          "permission": {
            "/aajeevika/faq/save": true,
            "/aajeevika/faq/faqlist": true,
            "/aajeevika/faq/update": true,
            "/aajeevika/faq/delete": true
          },
          "key": "faq"
        },
        {
          "title": "Website Editor",
          "permission": {
            "/aajeevika/extracontent/save": true,
            "/aajeevika/content/extralist": true,
            "/aajeevika/extracontent/update": true,
            "/aajeevika/extracontent/delete": true
          },
          "key": "editor"
        }
      ]
    }
  }

  getkmsCount(){
    this.contentService.getKmsCount('isSyncedCount',`flag=${this.flag}`)
                       .subscribe((res:any)=>{
                        console.log('kms count--->>>', res)
                        let kmsCount = res
                        this.contentService.kmsCounter.next(kmsCount)
                       })
  }
  


  onSelectionChange(event: any) {
    console.log("Selection Change ", event)
    this.page = 1;
    this.currentPage = 0;
    this.pageSize = 20

    //     moduleId: "11"
    // subModuleId: "15"


    this.catageoryOption.forEach((item: any) => {
      let tableHead: any = item.options.find((option: any) => option.value == event.target.value);

      if (tableHead && tableHead.tableHeader) {
        console.log("events", tableHead, event)
        this.moduleData = tableHead;
        this.dynamicForm = tableHead.dynamicForm;
        this.tableHeader = tableHead.tableHeader;
        this.entryTitle = tableHead.label;
        this.selectedModuleOptions = tableHead;
        if (tableHead.moduleId == '11' && tableHead?.subModuleId == '15') {
          this.fileAcceptType = this.imgExtensions.toString();
        } else {
          this.fileAcceptType = ''
        }

      }
    })
    this.getMasterData();

    console.log("gggg",event.target.value, event, this.catageoryOption, this.tableHeader);
    // this.router.navigate(['/admin/'+event.target.value]);

  }
  submitData(event: any) {
    sessionStorage.removeItem("entryTitle")
    console.log("event", event)
    let type = 'save';
    let fileInput = event.file;
    let id: number = 0;
    const formdata: any = new FormData();
    if (fileInput)
      for (let i = 0; i < fileInput.length; i++) {
        formdata.append("files", fileInput[i], fileInput[i].name);
      }

    for (let key in event.form) {
      console.log("keys", key)
      if (event.form[key] && key != 'fileList')
        formdata.append(key, event.form[key])
    }

    if (event.contentId) {
      // formdata.append("contentId", event.contentId)
      id = event.contentId;
      type = 'update';
    }
    formdata.append("moduleId", this.selectedModuleOptions.moduleId)
    // if(!this.selectedModuleOptions.subModuleId)
    // this.selectedModuleOptions.subModuleId = '1';
    if (this.selectedModuleOptions.subModuleId)
      formdata.append("subModuleId", this.selectedModuleOptions.subModuleId)
    console.log("keys", event, formdata)
    this.buttonDisable = true;
    this.contentService.saveContent(formdata, type, id).subscribe((data: any) => {
      if (data.responseCode == '200')
        this.buttonDisable = false;
      swal({
        title: "Success",
        text: "Data submitted successfully",
        icon: "success",
        // dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            this.rowData = {};
            this.isEntry = false;
            this.getMasterData();
          }

          sessionStorage.removeItem('entryTitle')
    sessionStorage.removeItem('addData')
    sessionStorage.removeItem('editDataStatus')
          
        }
        )
    },
      (error) => {
        this.buttonDisable = false;
        console.log("edit cutoff error", error);
        swal("", `${error?.error?.errorMsg ? error?.error?.errorMsg : 'Internal server error'}`, "error");
      });

  }

  getMasterData() {
    console.log(this.selectedModule);
    let params = `moduleId=${this.selectedModuleOptions.moduleId}&page=${this.page}`;
    if (this.selectedModuleOptions.subModuleId)
      params += `&subModuleId=${this.selectedModuleOptions.subModuleId}`;
    this.contentService.getAdminContent('list', params).subscribe((result: any) => {
      console.log("results", result);
      this.masterData = result.wrapperList;
      this.totalCount = result?.totalcount

      console.log('masterData', this.masterData)
    })
  }

  editData(rowData: any, isEdit: any = true) {
    console.log("record", rowData, isEdit)
    this.labelKeys = {}
    this.tableHeader.forEach((item: any) => this.labelKeys[item.key] = item.label)
    this.isViewMode = !isEdit;
    this.rowData = JSON.parse(JSON.stringify(rowData));
    this.isEntry = true;
    this.submitButton = 'Update';
    this.dynamicForm = this.selectedModuleOptions.dynamicForm;
    this.isChecked = false;
    this.addData = "false"
    sessionStorage.setItem("addData", this.addData)
    this.editDataStatus = !isEdit ? 'false' : 'true'
    sessionStorage.setItem('editDataStatus', this.editDataStatus)
    sessionStorage.setItem("entryTitle", this.entryTitle)
    sessionStorage.setItem("rowData", JSON.stringify(this.rowData))
  }

  setApprove(event: any, rowData: any) {
    rowData.checked = !rowData.checked;
    console.log("rowdata", rowData, event)
    if (this.masterData.find((item: any) => item.checked)) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }

  }

  approveReject(isApproved: any = 1) {
    let id = {};
    let approvedText = isApproved == 1 ? 'Approved' : 'Rejected';
    let filterId = this.masterData.filter((item: any) => item.checked);
    id = filterId ? { id: filterId.map((item: any) => item.id) } : {};
    this.contentService.changeAprrove(isApproved, id).subscribe((data: any) => {
      if (data.responseCode == '200')
        swal({
          title: "Success",
          text: `Data ${approvedText} successfully`,
          icon: "success",
          // dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
              this.masterData = this.masterData.map((item: any) => ({ ...item, checked: false }));
              this.isChecked = false;
              this.getMasterData();
            }
          })

      console.log("result")
    },
      (error) => {
        console.log("edit cutoff error", error);

        swal("Error", `Some error occured`, "error");
      })
  }
  
  pageEvent(event: any) {
    this.page = event;
    this.currentPage = event == 1 ? 0 : (event-1)
    this.getMasterData();
  }

  deleteContent(record: any) {
    this.contentService.deleteAdminContent('admincontent', `id=${record.id}`).subscribe((data: any) => {
      if (data.responseCode == '200')
        swal({
          title: "Success",
          text: `Data Deleted successfully`,
          icon: "success",
          // dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
              this.getMasterData();
            }
          })
    })
  }
  openDeleteDialog(data: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteContent(data)
      }
    });
  }
  resetFileType() {
    this.fileAcceptType = ''
    this.isEntry = false;
    let selectData: any = { target: { value: 'Orders, Advisory & Guidlines' } }
    this.entryTitle = 'Orders, Advisory & Guidlines'
    this.onSelectionChange(selectData)
    console.log(this.selectedModuleOptions)
    console.log('masterData', this.masterData)
    sessionStorage.removeItem('entryTitle')
    sessionStorage.removeItem('addData')
    sessionStorage.removeItem('editDataStatus')
  }
  openDialog(fileList: any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent, {
      data: {
        fileList: fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  addCore() {
    sessionStorage.setItem("entryTitle", this.entryTitle)
    this.addData = "true"
    sessionStorage.setItem("addData", this.addData)
    this.labelKeys = {}
    this.tableHeader.forEach((item: any) => this.labelKeys[item.key] = item.label)
    this.isEntry = true;
    this.isViewMode = false;
    this.submitButton = 'Submit';
    this.rowData = {};
    this.isChecked = false;
  }

  checkAvailablitiy(endPoint: string) {
    let isAvail = false;
    this.permissionObject.find((item: any) => {
      if (item.endPoint == endPoint) {
        isAvail = true;
      }
    })
    return isAvail;
  }
}
