import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-convergence-partners',
  templateUrl: './convergence-partners.component.html',
  styleUrls: ['./convergence-partners.component.scss']
})
export class ConvergencePartnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
