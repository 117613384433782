import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResourcesRoutingModule } from './resources.routing.module';
import { CaseStudiesComponent } from './case-studies/case-studies.component';
import { ResourceMenuComponent } from './resource-menu/resource-menu.component';
import { ManualsComponent } from './manuals/manuals.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { PressReleasesComponent } from './press-releases/press-releases.component';
import { PublicationsComponent } from './publications/publications.component';
import { AnnualReportsComponent } from './annual-reports/annual-reports.component';
import { CommonFiltersComponent } from 'src/app/shared/common-filters/common-filters.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { MatButtonModule } from '@angular/material/button'
import { NgImageSliderModule } from 'ng-image-slider';
import { ResourcesComponent } from './resources/resources.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
@NgModule({
    declarations: [
        CaseStudiesComponent,
        ResourceMenuComponent,
        ManualsComponent,
        GuidelinesComponent,
        PressReleasesComponent,
        PublicationsComponent,
        AnnualReportsComponent,
        // CommonFiltersComponent,
        // CommonGalleryDialogComponent,
        ResourcesComponent,


    ],
    imports: [
        CommonModule,
        ResourcesRoutingModule,
        MatDialogModule,
        MatButtonModule,NgImageSliderModule,
        ReactiveFormsModule,
        MatTooltipModule,
        SharedModule
    ],
    // exports:[CommonFiltersComponent,CommonGalleryDialogComponent]
})
export class ResourcesModule { }
