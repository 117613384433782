<div class="header-image">
    <img src="/assets/images/ajjevika-banner-introduction.jpg" alt="Introduction" title="Introduction" class="img-fluid" />
</div>
<div class="container">
    <div class="row py-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/about" class="nav-link ">F L Components</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <div class="px-3">NRLM-AAP</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <span style="font-weight: 900;">Universalization of Farm Livelihoods Interventions in Intensive Blocks through Annual Action Plan (AAP)</span><br />
                    DAY-NRLM is promoting sustainable agriculture, NTFP and livestock as part of Annual Action Plans. Under sustainable agriculture, the focus is on reducing the cost of cultivation, increasing productivity of existing crops, food and nutrition security, apart from enhancing net income through various agro-ecological practices for a diversified poly crop model. Under NTFP, the State Rural Livelihood Mission (SRLM) are focusing on building the capacity of the NTFP dependent communities on imbibing scientific harvesting, post-harvesting practices, value addition and market linkages. In respect of livestock, DAY-NRLM is focusing on reducing morbidity and mortality of livestock by delivering livestock and animal husbandry extension services at the doorstep of the Self Help Groups (SHG) member households. It may be noted that farm livelihood interventions are household–centric, having strong focus on building capacity of the target community through the engagement of Community Resource Persons (CRPs), known as Krishi Sakhis, PashuSakhis, Van Sakhis Krishi Udyog Sakhi etc.                </div>
            </div>
        </div>
    </div>
</div>