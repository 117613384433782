import { Component, OnInit } from '@angular/core';
import { ContentService } from './shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = require("sweetalert");
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  isLoading = false;
  constructor(public contentService:ContentService){

  }
  ngOnInit(): void {
    this.contentService.loaderSubscriber.subscribe(res=>{
      this.isLoading = res;
    })
  }
  title = 'nrlm-aajeevika';
}
