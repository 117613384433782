import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
import { sha256 } from 'js-sha256';
const swal: SweetAlert = require("sweetalert");

interface dynamicFormInterface {
  question: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  answer: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  name: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  mobile: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  username: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  email: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  password: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  levelId: (string | ((control: AbstractControl) => ValidationErrors | null))[];
}

interface userListInterface {
  name?: string,
  username?: string,
  email?: string,
  mobile?: string,
  password?: string,
  levelId?: string
}

interface submitEntryInterface {

  name?: string,
  username?: string,
  email?: string,
  mobile?: string,
  password?: string,
  id?: string,
  levelId?: string
}

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  userForm: FormGroup;
  @Input() rowData:userListInterface | any = [];
  @Input() submitButton:string = 'Submit';
  @Output() submitEntry: submitEntryInterface | any = new EventEmitter<submitEntryInterface>();
  @Output() previousPath: any = new EventEmitter();
  @Input() isViewMode: boolean = false;
  @Input() buttonDisable: boolean = false;
  @Input() title: string = 'Create User';
  @Input() dynamicForm: dynamicFormInterface = {question: ['',Validators.required],answer: ['',Validators.required], name: ['',Validators.required], mobile: ['',Validators.required], username: ['',Validators.required], email: ['',Validators.required], password: ['',Validators.required], levelId: ['',Validators.required]};
  // fileInput: any;
  // userPolicy:any= [];
  userLevel:any;
  salt:any;

  constructor(private formBuilder: FormBuilder,private contentService:ContentService,private dialog :MatDialog) {
    this.userForm = this.formBuilder.group({
      name: ['',Validators.required],
      email: ['',[Validators.required,Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      mobile: ['',[Validators.required,Validators.pattern('[6-9]\\d{9}')]],
      password: ['',[Validators.required,Validators.minLength(3)]],
      username: ['',[Validators.required,Validators.minLength(3)]],
      levelId: ['',Validators.required],  
    });

    this.salt= sessionStorage.getItem('saltId')
    console.log("SaltId",this.salt);
    
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // get f() { return this.userForm.controls; }


  ngOnInit(): void {
    this.contentService.getUserLevel().subscribe((result: any) => {
      console.log("Level", result.wrapperList);
       this.userLevel = result.wrapperList
    })
  }
  
  ngOnChanges(changes: SimpleChanges) {
    console.log("chanes", changes);
    if(changes.rowData && changes.rowData.currentValue){
      this.rowData = changes.rowData.currentValue;
      for(let key in this.userForm.value){
        this.userForm.patchValue({
          [key] : this.rowData[key]
        })
      }
      this.prefilledForm();

    }
    if(changes.isViewMode && changes.isViewMode.hasOwnProperty('currentValue')){
      this.isViewMode = changes.isViewMode.currentValue;
    }

    if(changes.dynamicForm && changes.dynamicForm.currentValue){
      this.dynamicForm = changes.dynamicForm.currentValue
      this.userForm = this.formBuilder.group(changes.dynamicForm.currentValue);
      if(this.rowData.hasOwnProperty('id'))
      this.prefilledForm();
    }

    console.log('View Mode ', this.isViewMode)
  }

  prefilledForm(){
      for(let key in this.userForm.value){
        this.userForm.patchValue({
          [key] : this.rowData[key]
        })
      }
  }

  onSubmit(){
    // let pass;
    // if(this.salt){
    //   pass = sha256(this.userForm.get('password')?.value);
    //   pass = sha256(pass + this.salt);
    // }
    // console.log("Submit", this.userForm.value, this.rowData, pass);
    this.submitEntry.emit({form: {
      ...this.userForm.value,
      // password: pass
    }, id: this.rowData?.id});    
  }

  goBack() {
    this.previousPath.emit();
    sessionStorage.removeItem("isEntry")
  }

  onSelectionChange(event:any){

  }
  onPolicyChange(event:any){

  }
}
