<form [formGroup]="filters" class="mx-0 mb-2 bg-white p-2" >
  <!-- <div  class=" row bg-light px-3 py-2 mb-2" *ngIf="filterConfig.name || filterConfig.search">
    <div class="col-12">
      <div class="input-group">
        <input
          class="form-control border-end-0 border"
          type="search"
          placeholder="Looking for"
          id="example-search-input"
          formControlName="name"
        />
      </div>
    </div>
  </div> -->
  <div class="row">
    <div class="col-3" *ngIf="filterConfig.globalSearch || filterConfig.search">
      <div class="input-group">
        <!-- <label for="exampleFormControlTextarea1">Example textarea</label> -->
        <div class="form-group" style="z-index: 1;">
          <label class="m-0">Search</label>
          <input
            class="form-control rounded"
            type="search"
            placeholder="Search"
            id="example-search-input"
            formControlName="globalSearch" style="width:180px;"
          />
        </div>
  
      </div>
    </div>
      <div class="col-3" *ngIf="filterConfig.author">
        <select name="" id="" class="form-select" formControlName="author" placeholder="Author">
          <option value="" selected>Author</option>
          <ng-container *ngFor="let item of authorList">
          <option [value]="item.optionValue">{{item.optionValue}}</option>
          </ng-container>
        </select>
      </div>
      <div class="col-3"  *ngIf="filterConfig.source" >
        <select name="" id="" class="form-select" placeholder="Source" formControlName="source">
          <option value=""  selected>Source</option>
          <ng-container *ngFor="let item of sourceList">
            <option [value]="item.optionValue">{{item.optionValue}}</option>
            </ng-container>
        </select>
      </div>
      <div class="col-3" *ngIf="filterConfig.from">
        <span class="me-2" >From</span>
        <input name="from" type="date" placeholder="from" id="" max="2023-06-01" class="form-control" formControlName="issuedDateFrom">
      </div>
      <div class="col-3" *ngIf="filterConfig.to">
        <span class="me-2" >To</span>
        <input name="to" type="date" placeholder="to" id="" max="2023-06-01" class="form-control"  [ngClass]="{'disabled-toDate': !filters?.controls?.issuedDateFrom?.value}" formControlName="issuedDateTo" >
      </div>
      <div class="col-3" *ngIf="filterConfig.fileType">
        <select name="" id="" class="form-select" formControlName="fileType" placeholder="File Type">
          <option value="" selected>File Type</option>
          <ng-container *ngFor="let item of fileTypes">
            <option [value]="item.optionValue">{{item.optionValue}}</option>
            </ng-container>
        </select>
      </div>
    <div class="col-3 row align-items-center" >
       <div class="col-6" style="margin-top: 20px;"> <button type="button" class="btn btn-sm btn-bg" aria-hidden="true" (click)="emitSearchParams()">Search</button></div>
       <div class="col-6" style="margin-top: 20px;"><button type="button" class="btn btn-sm btn-outline-primary" (click)="clearSearchFilters()">Reset</button></div>
      </div>
  </div>
  
  </form>
